import React from "react";

import { useNavigate } from "react-router-dom";
import styles from "../Stylesheets/Modules/cohortBackNav.module.scss";
import { StyledArrowBackIcon } from "./StyledComponents/Icons.style";
import { connect } from "react-redux";
import banner from "../Assets/Images/banner.png";
import { useConfirmDialog } from "../Context/ConfirmDialogContext";
const CohortBackNav = (props) => {
  const navigate = useNavigate();
  const { checkConditionAndShowDialog } = useConfirmDialog();
  return (
    <div
      className={styles["backto-container"]}
      onClick={() => checkConditionAndShowDialog(() => navigate(props.link))}
      style={{
        background:
          props.themeInfo[0]?.primarycolor !== "#0e1555"
            ? props.themeInfo[0]?.primarycolor
            : banner,
      }}
    >
      <StyledArrowBackIcon />
      <span className={styles["backto-text"]}>{props.text}</span>
    </div>
  );
};
const mapStateToProps = ({ app, modules, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    sections: modules.sections,
    themeInfo: user.themeInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CohortBackNav);
