// app
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_AUTH_MODAL = "SET_AUTH_MODAL";
export const SET_ORG_LOGIN_MODAL = "SET_ORG_LOGIN_MODAL";
export const SET_FORGOT_PASS_MODAL = "SET_FORGOT_PASS_MODAL";
export const SET_APP_LOADING = "SET_APP_LOADING";
export const SET_INIT_LOADING = "SET_INIT_LOADING";
export const COGNITO_TOKEN = "COGNITO_TOKEN";
export const SET_USER_NAME_TOKEN = "SET_USER_NAME_TOKEN";
export const SET_NOTIFICATION_TOGGLE = "SET_NOTIFICATION_TOGGLE";
export const SET_VERIFICATION_CODE_INPUT = "SET_VERIFICATION_CODE_INPUT";
// auth
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_TOKEN_EXPIRATION = "SET_TOKEN_EXPIRATION";
export const SET_CONFIG = "SET_CONFIG";

// user
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_USER_UID = "SET_USER_UID";
export const SET_ORG_LIST_DETAILS = "SET_ORG_LIST_DETAILS";
export const SET_THEME_INFO = "SET_THEME_INFO";
export const SET_CUSTOMIZATION_INFO = "SET_CUSTOMIZATION_INFO";
export const SET_IMAGE_URL = "SET_IMAGE_URL";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_USER_PERSONAL_DATA = "SET_USER_PERSONAL_DATA";
export const SET_USER_NAME_MOBILE = "SET_USER_NAME_MOBILE";
export const SET_USER_EDUCATION_DATA = "SET_USER_EDUCATION_DATA";
export const SET_USER_PROFESSIONAL_DATA = "SET_USER_PROFESSIONAL_DATA";
export const EDIT_USER_PROFILE_DATA = "EDIT_USER_PROFILE_DATA";
export const DEL_USER_PROFILE_DATA = "DEL_USER_PROFILE_DATA";
export const SET_SKILLS_PROFILE_DATA = "SET_SKILLS_PROFILE_DATA";
export const SET_LEARNER_PROFILE_DATA = "SET_LEARNER_PROFILE_DATA";
export const SET_ALL_PROMPTS = "SET_ALL_PROMPTS";

// cohort
export const SET_COHORT_INFO = "SET_COHORT_INFO";
export const SET_APPLICATION_INFO = "SET_APPLICATION_INFO";
export const SET_COHORT_APPLICATIONS = "SET_COHORT_APPLICATIONS";
export const SET_COHORT_STUDENTS = "SET_COHORT_STUDENTS";
export const SET_VIDEO_METADATA = "SET_VIDEO_METADATA";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SET_ONBOARDING_APPLICATION_DATA =
  "SET_ONBOARDING_APPLICATION_DATA";
export const SET_ONBOARDING_SUBMISSIONS = "SET_ONBOARDING_SUBMISSIONS";
export const SET_COHORT_LIVE_SESSIONS = "SET_COHORT_LIVE_SESSIONS";
export const SET_SINGLE_LIVE_SESSION = "SET_SINGLE_LIVE_SESSION";
export const SET_COHORT_ADDITIONAL_DETAILS = "SET_COHORT_ADDITIONAL_DETAILS";
export const SET_STUDENT_COHORT_MODULE_PROGRESS =
  "SET_STUDENT_COHORT_MODULE_PROGRESS";
// program
export const SET_ALL_PROGRAMS = "SET_ALL_PROGRAMS";
export const SET_COHORT_STATS = "SET_COHORT_STATS";
export const SET_COHORT_CERTIFICATES_AND_BADGES = "SET_COHORT_CERTIFICATES_AND_BADGES"

// modules
export const SET_ALL_MODULES = "SET_ALL_MODULES";
export const SET_MODULES_INFO = "SET_MODULES_INFO";
export const SET_MODULE_CONTENT = "SET_MODULE_CONTENT";
export const ADD_MODULE_CONTENT_SECTION = "ADD_MODULE_CONTENT_SECTION";
export const SET_MODULE_QUESTION_CONTENT = "SET_MODULE_QUESTION_CONTENT";
export const SET_MODULE_OPTIONS = "SET_MODULE_OPTIONS";
export const SET_TIMESPENT_ON_CONTENT = "SET_TIMESPENT_ON_CONTENT";
export const SET_MODULE_LIVE_SESSIONS = "SET_MODULE_LIVE_SESSIONS";
export const SET_MODULE_SKILLS_LIST = "SET_MODULE_SKILLS_LIST";
// admin
export const SET_ALL_COHORTS = "SET_ALL_COHORTS";
export const SET_NEW_COHORT = "SET_NEW_COHORT";
export const SET_ADMIN_BOOTCAMP_DATA = "SET_ADMIN_BOOTCAMP_DATA";
export const SET_ADMIN_USER_DATA = "SET_ADMIN_USER_DATA";
