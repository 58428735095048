import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import Container from "@mui/material/Container";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import ConfirmDialog from "../../../Reusable/Modals/ConfirmDialog";
import LiveSessionCreation from "./LiveSessionCreation";
import EditorWrapper from "../../../Components/EditorWrapper";

import {
  StyledAddIcon,
  StyledArrowBackIcon,
  StyledDeleteIcon,
  StyledEditIcon,
} from "../../../Components/StyledComponents/Icons.style";
import { OutlinedButton } from "../../../Components/StyledComponents/Button.style";
import {
  getLiveSessions,
  getAllCohorts,
  deleteLiveSession,
} from "../../../Store/Actions/actions.cohort";
import { getAllModules } from "../../../Store/Actions/actions.modules";

import { format } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../Stylesheets/Modules/Admin/LiveSession/LiveSessionWrapper.module.scss";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { logPageView } from "../../../Utils/analytics";
const LiveSessionWrapper = (props) => {
  const history = useNavigate();
  const { themeInfo, customization, liveSessions } = props;
  const [liveSessionsList, setLiveSessionsList] = useState([]);
  const { cohortId, moduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [toggle, updateToggle] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [liveSession, setLiveSession] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [isEditorLoading, setEditorLoading] = useState(true);
  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setEditorLoading(false);
  }, []);

  useEffect(() => {
    fetchLiveSessionData(cohortId);
    props.getAllCohorts();
    props.getAllModules(cohortId)
  }, []);

  const fetchLiveSessionData = async (cohortId) => {
    await props.getLiveSessions(cohortId);
  };

  useEffect(() => {
    setLiveSessionsList(liveSessions);
  }, [liveSessions]);

  const isLiveSessionEnabled = props.config.liveSession;

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Live Session Wrapper Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  const deleteHandler = (livesessionId, cohortId) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    props.deleteLiveSession(livesessionId, cohortId);
  };
  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div
          className={styles["create-session-header"]}
          style={{ color: themeInfo[0]?.secondarycolor }}
        >
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() =>
              history(
                `/admin/cohortMenu/${cohortId}?cohort=${searchParams.get(
                  "cohort"
                )}`
              )
            }
          />
          Live Session
        </div>
        <div className={styles["create-live-session-action"]}>
          <OutlinedButton
            disabled={!isLiveSessionEnabled}
            variant="contained"
            startIcon={<StyledAddIcon />}
            bgcolor={
              themeInfo[0]?.primarycolor
                ? themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              themeInfo[0]?.fontfamily ? themeInfo[0]?.fontfamily : "Nunito"
            }
            onClick={() => {
              updateToggle(!toggle);
              setEditMode(false);
            }}
          >
            Add Live Session
          </OutlinedButton>
        </div>

        <div className={styles["live-sessions-list-wrapper"]}>
          {isLiveSessionEnabled === false && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="info">
                This is a premium feature, contact support@Gritly.us to Enable!
              </Alert>
            </Stack>
          )}
          {liveSessionsList?.map((item, key) => {
            return (
              <div
                className={styles["wrapper"]}
                key={key}
                onClick={() =>
                  history(
                    `/admin/liveSession/${item._id
                    }/cohort/${cohortId}?cohort=${searchParams.get("cohort")}`
                  )
                }
              >
                <div className={styles["header-wrapper"]}>
                  <div
                    className={styles["card-number-indicator"]}
                    style={{ background: props.themeInfo[0]?.primarycolor }}
                  >
                    {item.topic}
                  </div>
                  <div
                    className={styles["edit-delete-section"]}
                    style={{ color: props.themeInfo[0]?.primarycolor }}
                  >
                    <StyledEditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        updateToggle(!toggle);
                        setEditMode(true);
                        setLiveSession(item);
                      }}
                    />
                    <StyledDeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure you want to delete ?",
                          onConfirm: () => {
                            deleteHandler(item._id, item.cohortId);
                          },
                        });
                      }}
                    />
                  </div>
                </div>

                <div className={styles["cards-wrapper"]} key={key}>
                  <div className={styles["image-spkname-wrapper"]}>
                    <img src={item.photoUrl} alt="usericon" />
                    <div className={styles["spkname-wrapper"]}>
                      <div className={styles["spkname"]}>
                        {item.speakerName}
                      </div>
                    </div>
                  </div>
                  <div className={styles["event-overview"]}>
                    <EditorWrapper
                      root="event-overview-wrapper"
                      childRoot="event-details-content-wrapper"
                      readOnly={true}
                      content={item.eventOverview}
                    />
                  </div>
                  <div className={styles["separator"]} />
                  <div className={styles["event-link-wrapper"]}>
                    <InsertLinkIcon />
                    <div className={styles["label"]}>Event Link : </div>

                    <div
                      className={styles["value"]}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.googleMeetLink, "_blank");
                      }}
                    >
                      {item.googleMeetLink}
                    </div>
                  </div>
                  <div className={styles["recurring-wrapper"]}></div>
                  <div className={styles["slots-wrapper"]}></div>
                </div>
              </div>
            );
          })}
        </div>

        <GenericModal open={toggle} togglePopUp={updateToggle} maxWidth={"lg"}>
          <div className={styles["modal-container-wrapper"]}>
            {editMode ? (
              <div
                className={styles["modal-header"]}
                style={{
                  background: props.themeInfo[0].primarycolor,
                }}
              >
                Update Live Session
                <div><CloseIcon onClick={() => updateToggle(false)} sx={{ cursor: "pointer" }} /></div>
              </div>
            ) : (
              <div
                className={styles["modal-header"]}
                style={{
                  background: props.themeInfo[0].primarycolor,
                }}
              >
                Create Live Session
                <div><CloseIcon onClick={() => updateToggle(false)} sx={{ cursor: "pointer" }} /></div>
              </div>
            )}
            <LiveSessionCreation
              togglePopUp={updateToggle}
              editMode={editMode}
              liveSession={liveSession}
            />
          </div>
        </GenericModal>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

const mapStateToProps = ({ app, user, cohort, config }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    customization: user.customization,
    liveSessions: cohort.liveSessionDetails,
    config: config.configData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLiveSessions: (cohortId) => dispatch(getLiveSessions(cohortId)),
    getAllCohorts: () => dispatch(getAllCohorts()),
    deleteLiveSession: (livesessionId, cohortId) =>
      dispatch(deleteLiveSession(livesessionId, cohortId)),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveSessionWrapper);
