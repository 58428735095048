import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import styles from "../Stylesheets/Modules/moduleQuestions.module.scss";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import OutlinedInput from "@mui/material/OutlinedInput";
import CheckIcon from "@mui/icons-material/Check";
import CohortBackNav from "../Components/CohortBackNav";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
import EditorWrapper from "../Components/EditorWrapper";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import greenCircle from "../Assets/Images/green-circle.png";
import whiteCircle from "../Assets/Images/white-circle.png";
import {
  getStudentModuleQuestionContent,
  addModuleQuestionSection,
  updateModuleQuestionSection,
  submitQuestionnaire,
  getModuleCourseContent,
} from "../Store/Actions/actions.modules";
import { getCohortInfo } from "../Store/Actions/actions.cohort";
import {
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
  StyledCloseIcon,
  StyledKeyboardArrowDownIcon,
  StyledKeyboardArrowUpIcon,
  StyledMenuIcon,
  StyledPendingActionsIcon,
} from "../Components/StyledComponents/Icons.style";
import { logPageView } from "../Utils/analytics";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

import { getQueryParams } from "../Helpers/utils";
import { triggerASAPoints } from "../Store/Actions/actions.app";
import { handleActionCompletionASA } from "../Helpers/app";
import { useConfirmDialog } from "../Context/ConfirmDialogContext";
import StatusIcon from "../Reusable/StatusIcon";
import { Tooltip } from "@mui/material";

const ModuleQuestions = (props) => {
  const history = useNavigate();
  const { cohortId, moduleId } = useParams();
  const [studentId] = useState(props.userInfo.id);
  const [currentNav, setCurrentNav] = useState(0);
  const [searchParams] = useSearchParams();
  const [qAA, setQAA] = useState();
  const [section, setSection] = useState();
  const [hide, setHide] = useState(true);
  const [currAns, setCurrAns] = useState({});
  const [content, setContent] = useState("");
  const [toggleEditor, setToggleEditor] = useState({});
  const [isEditorLoading, setEditorLoading] = useState(true);
  const [status, setStatus] = useState();
  const [moduleList, setModuleList] = useState();
  const [lastModule, setLastModule] = useState();
  const [, forceUpdate] = useState(0);
  const { user_id, unique_task_key, step_number } = getQueryParams();
  const { checkConditionAndShowDialog } = useConfirmDialog();
  const { sections } = props;

  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setEditorLoading(false);
  }, []);

  useEffect(() => {
    getAllQuesionnaireData(moduleId);
  }, []);
  useEffect(() => {
    props.getCohortInfo(cohortId);
    props.getModuleCourseContent(cohortId, studentId, moduleId);
  }, []);
  useEffect(() => {
    if (props.cohortInfo) {
      setModuleList(props.cohortInfo.modules);
    }
  }, [props.cohortInfo]);

  const nextModuleExists = () => {
    if (moduleList?.length) {
      const index = moduleList?.findIndex((x) => x.module === moduleId);
      return !(index + 1 === moduleList?.length);
    }
  };
  useEffect(() => {
    setLastModule(!nextModuleExists());
  }, [props]);
  const sectionInformation = [
    {
      key: "startDate",
      label: "Date",
      active: true,
    },
    {
      key: "endDate",
      label: "Due Date",
      active: section?.dueDate ? true : false,
    },
    {
      key: "totalQuestions",
      label: "Total Questions",
      active: true,
    },
    {
      key: "status",
      label: "Status",
      active: false,
    },
  ];
  const getAllQuesionnaireData = async (moduleId) => {
    await props.getStudentModuleQuestionContent(studentId, cohortId, moduleId);
  };

  const handleChange = (id, val) => {
    setCurrAns({
      ...currAns,
      [id]: {
        questionId: id,
        answer: val,
      },
    });
  };
  const handleChangeView = (questionId) => {
    setToggleEditor({
      ...toggleEditor,
      [questionId]: !toggleEditor[questionId],
    });
  };

  const showSubmit = (questions) => {
    if (questions.length === 0) return false;

    // Check if all questions are "Complete"
    const allComplete = questions.every(
      (question) => question.status === "Completed"
    );

    if (allComplete) return false;

    // Check if all questions have answers
    const allAnswered = questions.every((question) => {
      return (
        currAns[question._id]?.answer &&
        currAns[question._id]?.answer.length > 0
      );
    });

    if (allAnswered) return true;

    // Check if any question is in the "ReSubmit" state and has a new answer
    const anyReSubmitWithNewAnswer = questions.some((question) => {
      return (
        question.status === "ReSubmit" &&
        currAns[question._id]?.answer &&
        currAns[question._id]?.answer.length > 0
      );
    });

    return anyReSubmitWithNewAnswer;
  };

  const submitQuestionnaireData = async (questionnaireId, status) => {
    if (Object.values(currAns)?.length > 0) {
      const payload = Object.values(currAns);
      if (status === "InReview") {
        await props.submitQuestionnaire(
          cohortId,
          studentId,
          moduleId,
          questionnaireId,
          payload,
          true
        );
      } else {
        await props.submitQuestionnaire(
          cohortId,
          studentId,
          moduleId,
          questionnaireId,
          payload,
          false
        );
      }
      getAllQuesionnaireData(moduleId);
      forceUpdate((n) => n + 1); // Force a re-render
    } else {
      toast.error("Please fill all the answers to submit");
    }
  };
  useEffect(() => {
    if (props.questionnaires?.length > 0) {
      const selectedSection = props.questionnaires?.[0];
      setQAA(props.questionnaires);
      if (searchParams.get("sectionId")) {
        const sectionId = searchParams.get("sectionId");
        const selection = props.questionnaires.find(
          (sec) => sec._id === sectionId
        );
        const idx = props.questionnaires.findIndex(
          (sec) => sec._id === sectionId
        );
        setSection(selection);
        setCurrentNav(idx);
      } else {
        let url = `/cohort/${cohortId}/curriculum/${moduleId}/questions?cohort=${searchParams.get(
          "cohort"
        )}&module=${searchParams.get("module")}`;
        url = `${url}&sectionId=${selectedSection._id}`;
        history(url);
        setSection(selectedSection);
        setCurrentNav(0);
      }
    }
  }, [props.questionnaires]);

  useEffect(() => {
    if (
      qAA &&
      qAA[0] &&
      qAA[0].status === "Completed" &&
      user_id &&
      unique_task_key &&
      step_number
    ) {
      props.triggerASAPoints();
      handleActionCompletionASA();
    }
  }, [qAA]);

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Module Questionnaire Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  const getSectionTitle = (sectionTitle) => {
    const titletoDisplay =
      sectionTitle.length > 20
        ? sectionTitle.substring(0, 20) + "..."
        : sectionTitle;
    return titletoDisplay;
  };

  const questionsNav = () => {
    return (
      <div
        className={styles["questions-nav"]}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={styles["menu-close-icon"]}
          onClick={() => {
            setHide(true);
          }}
        >
          <StyledCloseIcon />
        </div>
        <div className={styles["nav-module-name"]}>
          {searchParams.get("module")}
        </div>
        {qAA?.map((sec, idx) => {
          return (
            <div
              data-event-name="[Student] Module Questions Section Navigation"
              key={`${sec?._id}-item-x`}
              className={`${styles["nav-item"]} ${
                sec?._id === section?._id ? styles["selected"] : ""
              }`}
              onClick={() => {
                const selectedSection = qAA?.find((s) => s._id === sec._id);
                let url = `/cohort/${cohortId}/curriculum/${moduleId}/questions?cohort=${searchParams.get(
                  "cohort"
                )}&module=${searchParams.get("module")}`;
                url = `${url}&sectionId=${selectedSection._id}`;
                checkConditionAndShowDialog(() => {
                  history(url);
                  setCurrentNav(idx);
                  setCurrAns({});
                  setSection(selectedSection);
                  setHide(true);
                });
              }}
            >
              {sec.status === "InReview" ? (
                <StyledPendingActionsIcon
                  classes={{ root: styles["inreview-icon"] }}
                />
              ) : (
                <CheckIcon
                  classes={{
                    root: `${styles["tick-icon"]} ${
                      sec.status === "Completed" ? styles["completed"] : ""
                    }`,
                  }}
                />
              )}
              <Tooltip title={sec.title}>
                <div className={styles["nav-item-title"]}>
                  {getSectionTitle(sec.title)}
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  };

  const isAnswerDisabled = (status) => {
    const disabledStatus = ["Submitted", "Completed", "Failed"];
    return disabledStatus.indexOf(status) !== -1;
  };
  const length = section?.questions?.length;

  const goToNextModule = () => {
    let inc = 0;
    let nextModule = {};
    while (inc < moduleList.length) {
      const currentModule = moduleList[inc];

      if (currentModule.module === moduleId) {
        inc++;
        while (inc < moduleList.length) {
          const potentialNextModule = moduleList[inc];
          inc++;

          if (potentialNextModule.published) {
            nextModule = potentialNextModule;
            return nextModule;
          }
        }
      } else {
        inc++;
      }
    }
    return nextModule;
  };
  const getNavigationalCourseContentSection = () => {
    return sections[0]?._id;
  };
  return (
    <div className={styles["module-questions-main"]}>
      <CohortBackNav
        data-event-name={`[Student] Clicked On Back to ${searchParams.get(
          "module"
        )} ${props.customization.module_label || "Module"} Overview`}
        link={`/cohort/${cohortId}/curriculum/${moduleId}?cohort=${searchParams.get(
          "cohort"
        )}&module=${searchParams.get("module")}`}
        text={`Back to ${searchParams.get("module")} ${
          props.customization.module_label || "Module"
        } Overview`}
      />
      <div
        className={`${styles["module-questions-container"]} ${
          !hide ? styles["with-hidden-nav"] : ""
        }`}
      >
        {!hide && (
          <div className={styles["floating-nav"]}>{questionsNav()}</div>
        )}
        {hide && (
          <div
            className={styles["questions-menu-icon"]}
            onClick={() => {
              setHide(false);
            }}
          >
            <StyledMenuIcon />
          </div>
        )}
        {questionsNav()}
        <div className={styles["questions-body"]}>
          {section && (
            <div key={`${section._id}-body-key`}>
              <div className={styles["title"]}>{section.title}</div>
              <div className={styles["section-details"]}>
                {sectionInformation.map((item, key) => {
                  const dateFormatOptions = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  };
                  if (item.active)
                    return (
                      <div className={styles["box"]} key={key}>
                        <div className={styles["heading"]}>{item.label}</div>
                        <div className={styles["value"]}>
                          {item.key === "startDate"
                            ? new Date(section?.createdAt).toLocaleString(
                                "en-US",
                                dateFormatOptions
                              )
                            : item.key === "totalQuestions"
                            ? length
                            : item.key === "endDate"
                            ? section?.dueDate === undefined
                              ? "-"
                              : new Date(section?.dueDate).toLocaleString(
                                  "en-US",
                                  dateFormatOptions
                                )
                            : "-"}
                        </div>
                      </div>
                    );
                })}
              </div>
              <div className={styles["questions-container"]}>
                {section?.questions?.map((question, qIdx) => {
                  if (question.questionType === "CHECKBOX") {
                    return (
                      <div className={styles["wrapper"]} key={qIdx}>
                        <div className={styles["upper-label"]}>
                          <div className={styles["attempts"]}>
                            <div className={styles[""]}>Attempts:</div>
                            {question.unlimitedTries ? (
                              <AllInclusiveIcon />
                            ) : (
                              <>
                                <div className={styles["attempts-indicator"]}>
                                  {question?.numberOfResubmissionAllowed > 5 ? (
                                    <div
                                      className={
                                        styles["attempts-indicator-short-web"]
                                      }
                                    >
                                      <div>
                                        {question.numberOfTimesResubmitted}/
                                        {question.numberOfResubmissionAllowed}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {question?.numberOfResubmissionAllowed >
                                        0 &&
                                        [
                                          ...Array(
                                            question.numberOfTimesResubmitted
                                          ),
                                        ].map((_, index) => (
                                          <img
                                            src={greenCircle}
                                            alt="green"
                                            key={index}
                                            className={styles["circle"]}
                                          />
                                        ))}
                                      {question?.numberOfResubmissionAllowed >
                                        0 &&
                                        [
                                          ...Array(
                                            question.numberOfResubmissionAllowed -
                                              question.numberOfTimesResubmitted
                                          ),
                                        ].map((_, index) => (
                                          <img
                                            src={whiteCircle}
                                            alt="white"
                                            key={index}
                                            className={styles["circle"]}
                                          />
                                        ))}
                                    </>
                                  )}
                                </div>
                                <div
                                  className={styles["attempts-indicator-short"]}
                                >
                                  {question?.numberOfResubmissionAllowed >
                                    0 && (
                                    <div>
                                      {question.numberOfTimesResubmitted}/
                                      {question.numberOfResubmissionAllowed}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className={styles["credits"]}>
                            Credits: {question?.weightage}
                          </div>
                        </div>
                        <div
                          className={styles["question"]}
                          key={`question-${qIdx}`}
                        >
                          <div className={styles["status-title-wrapper"]}>
                            <div className={styles["title"]}>
                              {qIdx + 1}
                              {")"}&nbsp;
                              <EditorWrapper
                                root="question-editor-wrapper"
                                childRoot="question-wrapper"
                                readOnly={true}
                                content={question.question}
                                isEditorLoading={isEditorLoading}
                                setEditorLoading={setEditorLoading}
                              />
                            </div>
                            {question.status && (
                              <StatusIcon status={question.status} />
                            )}
                          </div>
                          <div className={styles["select-options"]}>
                            {question.values.map((option, idx) => {
                              return (
                                <FormControlLabel
                                  classes={{
                                    root: styles["label-field-root"],
                                  }}
                                  key={`option-${idx}`}
                                  control={
                                    <Checkbox
                                      classes={{
                                        root: styles["option-root"],
                                        disabled: styles["checkbox-disabled"],
                                      }}
                                      checked={(() => {
                                        if (currAns[question._id]?.answer) {
                                          return currAns[question._id]?.answer
                                            ? currAns[
                                                question._id
                                              ].answer.indexOf(option) !== -1
                                            : false;
                                        }
                                        if (
                                          question.answer &&
                                          (question.status === "Submitted" ||
                                            question.status === "ReSubmit" ||
                                            question.status === "Completed")
                                        ) {
                                          return (
                                            question.answer?.indexOf(option) !==
                                            -1
                                          );
                                        }
                                        return false;
                                      })()}
                                      disabled={
                                        isAnswerDisabled(question.status) ||
                                        false
                                      }
                                      name={option}
                                      onChange={(e) => {
                                        let exist =
                                          currAns[question._id]?.answer || [];
                                        let newVal = [...exist];
                                        let newOptionIdx =
                                          newVal.indexOf(option);
                                        if (newOptionIdx !== -1) {
                                          newVal.splice(newOptionIdx, 1);
                                        } else {
                                          newVal.push(option);
                                        }
                                        handleChange(question._id, newVal);
                                      }}
                                    />
                                  }
                                  label={option}
                                />
                              );
                            })}
                          </div>
                          {question?.feedback && (
                            <div className={styles["feedback-wrapper"]}>
                              <div
                                className={styles["view-feedback-accordion"]}
                              >
                                <div
                                  className={styles["view-feedback-label"]}
                                  onClick={() => {
                                    handleChangeView(question?._id);
                                  }}
                                >
                                  <span>{"View Feedback"}</span>
                                  {toggleEditor[question._id] ? (
                                    <StyledKeyboardArrowUpIcon
                                      classes={{
                                        root: styles["icon-class-up"],
                                      }}
                                    />
                                  ) : (
                                    <StyledKeyboardArrowDownIcon
                                      classes={{
                                        root: styles["icon-class-down"],
                                      }}
                                    />
                                  )}
                                </div>
                                {toggleEditor[question._id] && (
                                  <EditorWrapper
                                    readOnly={true}
                                    content={question.feedback}
                                    setContent={setContent}
                                    isEditorLoading={isEditorLoading}
                                    setEditorLoading={setEditorLoading}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  } else if (question.questionType === "RADIO") {
                    return (
                      <div className={styles["wrapper"]} key={qIdx}>
                        <div className={styles["upper-label"]}>
                          <div className={styles["attempts"]}>
                            <div className={styles[""]}>Attempts:</div>
                            {question.unlimitedTries ? (
                              <AllInclusiveIcon />
                            ) : (
                              <>
                                <div className={styles["attempts-indicator"]}>
                                  {question?.numberOfResubmissionAllowed > 5 ? (
                                    <div
                                      className={
                                        styles["attempts-indicator-short-web"]
                                      }
                                    >
                                      <div>
                                        {question.numberOfTimesResubmitted}/
                                        {question.numberOfResubmissionAllowed}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {question?.numberOfResubmissionAllowed >
                                        0 &&
                                        [
                                          ...Array(
                                            question.numberOfTimesResubmitted
                                          ),
                                        ].map((_, index) => (
                                          <img
                                            src={greenCircle}
                                            alt="green"
                                            key={index}
                                            className={styles["circle"]}
                                          />
                                        ))}
                                      {question?.numberOfResubmissionAllowed >
                                        0 &&
                                        [
                                          ...Array(
                                            question.numberOfResubmissionAllowed -
                                              question.numberOfTimesResubmitted
                                          ),
                                        ].map((_, index) => (
                                          <img
                                            src={whiteCircle}
                                            alt="white"
                                            key={index}
                                            className={styles["circle"]}
                                          />
                                        ))}
                                    </>
                                  )}
                                </div>
                                <div
                                  className={styles["attempts-indicator-short"]}
                                >
                                  {question?.numberOfResubmissionAllowed >
                                    0 && (
                                    <div>
                                      {question.numberOfTimesResubmitted}/
                                      {question.numberOfResubmissionAllowed}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className={styles["credits"]}>
                            Credits: {question?.weightage}
                          </div>
                        </div>
                        <div
                          className={styles["question"]}
                          key={`question-${qIdx}`}
                        >
                          <div className={styles["status-title-wrapper"]}>
                            <div className={styles["title"]}>
                              {qIdx + 1}
                              {")"}&nbsp;
                              <EditorWrapper
                                root="question-editor-wrapper"
                                childRoot="question-wrapper"
                                readOnly={true}
                                content={question.question}
                                isEditorLoading={isEditorLoading}
                                setEditorLoading={setEditorLoading}
                              />
                            </div>
                            {question.status && (
                              <StatusIcon status={question.status} />
                            )}
                          </div>
                          <div className={styles["radio-options"]}>
                            {question.values.map((option, idx) => {
                              return (
                                <FormControlLabel
                                  classes={{
                                    root: styles["label-field-root"],
                                  }}
                                  key={`option-x-${idx}`}
                                  control={
                                    <Radio
                                      classes={{
                                        root: styles["radio-root"],
                                        disabled: styles["radio-disabled"],
                                      }}
                                      disabled={
                                        isAnswerDisabled(question.status) ||
                                        false
                                      }
                                      checked={(() => {
                                        if (currAns[question._id]?.answer) {
                                          return currAns[question._id]?.answer
                                            ? currAns[question._id]?.answer ===
                                                option
                                            : false;
                                        }
                                        if (
                                          question.answer &&
                                          (question.status === "Submitted" ||
                                            question.status === "ReSubmit" ||
                                            question.status === "Completed")
                                        ) {
                                          return (
                                            question.answer?.indexOf(option) !==
                                            -1
                                          );
                                        }
                                        return false;
                                      })()}
                                      name={option}
                                      onChange={(event) => {
                                        handleChange(question._id, option);
                                      }}
                                    />
                                  }
                                  label={option}
                                />
                              );
                            })}
                          </div>
                          {question?.feedback && (
                            <div className={styles["feedback-wrapper"]}>
                              <div
                                className={styles["view-feedback-accordion"]}
                              >
                                <div
                                  className={styles["view-feedback-label"]}
                                  onClick={() => {
                                    handleChangeView(question?._id);
                                  }}
                                >
                                  <span>{"View Feedback"}</span>
                                  {toggleEditor[question._id] ? (
                                    <StyledKeyboardArrowUpIcon
                                      classes={{
                                        root: styles["icon-class-up"],
                                      }}
                                    />
                                  ) : (
                                    <StyledKeyboardArrowDownIcon
                                      classes={{
                                        root: styles["icon-class-down"],
                                      }}
                                    />
                                  )}
                                </div>
                                {toggleEditor[question._id] && (
                                  <EditorWrapper
                                    readOnly={true}
                                    content={question.feedback}
                                    setContent={setContent}
                                    isEditorLoading={isEditorLoading}
                                    setEditorLoading={setEditorLoading}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className={styles["wrapper"]} key={qIdx}>
                      <div className={styles["upper-label"]}>
                        <div className={styles["attempts"]}>
                          <div className={styles[""]}>Attempts:</div>
                          {question.unlimitedTries ? (
                            <AllInclusiveIcon />
                          ) : (
                            <>
                              <div className={styles["attempts-indicator"]}>
                                {question?.numberOfResubmissionAllowed > 5 ? (
                                  <div
                                    className={
                                      styles["attempts-indicator-short-web"]
                                    }
                                  >
                                    <div>
                                      {question.numberOfTimesResubmitted}/
                                      {question.numberOfResubmissionAllowed}
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {question?.numberOfResubmissionAllowed >
                                      0 &&
                                      [
                                        ...Array(
                                          question.numberOfTimesResubmitted
                                        ),
                                      ].map((_, index) => (
                                        <img
                                          src={greenCircle}
                                          alt="green"
                                          key={index}
                                          className={styles["circle"]}
                                        />
                                      ))}
                                    {question?.numberOfResubmissionAllowed >
                                      0 &&
                                      [
                                        ...Array(
                                          question.numberOfResubmissionAllowed -
                                            question.numberOfTimesResubmitted
                                        ),
                                      ].map((_, index) => (
                                        <img
                                          src={whiteCircle}
                                          alt="white"
                                          key={index}
                                          className={styles["circle"]}
                                        />
                                      ))}
                                  </>
                                )}
                              </div>
                              <div
                                className={styles["attempts-indicator-short"]}
                              >
                                {question?.numberOfResubmissionAllowed > 0 && (
                                  <div>
                                    {question.numberOfTimesResubmitted}/
                                    {question.numberOfResubmissionAllowed}
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className={styles["credits"]}>
                          Credits: {question?.weightage}
                        </div>
                      </div>
                      <div
                        className={styles["question"]}
                        key={`question-x-${qIdx}`}
                      >
                        <div className={styles["status-title-wrapper"]}>
                          <div className={styles["title"]}>
                            {qIdx + 1}
                            {")"}&nbsp;
                            <EditorWrapper
                              root="question-editor-wrapper"
                              childRoot="question-wrapper"
                              readOnly={true}
                              content={question.question}
                              isEditorLoading={isEditorLoading}
                              setEditorLoading={setEditorLoading}
                            />
                          </div>
                          {question.status && (
                            <StatusIcon status={question.status} />
                          )}
                        </div>
                        <div className={styles["input"]}>
                          <OutlinedInput
                            type="text"
                            id="outlined-adornment-email"
                            multiline
                            maxRows={3}
                            classes={{
                              root: styles["outline-root"],
                              notchedOutline: styles["notched-outline-root"],
                              input: styles["input-root"],
                              focused: styles["input-focused"],
                            }}
                            disabled={
                              isAnswerDisabled(question.status) || false
                            }
                            value={(() => {
                              if (currAns[question._id]?.answer?.length >= 0) {
                                return currAns[question._id]?.answer;
                              }
                              if (question.answer) {
                                return question.answer;
                              }
                              return "";
                            })()}
                            onChange={(event) => {
                              handleChange(question._id, event.target.value);
                            }}
                          />
                        </div>
                        {question?.feedback && (
                          <div className={styles["feedback-wrapper"]}>
                            <div className={styles["view-feedback-accordion"]}>
                              <div
                                className={styles["view-feedback-label"]}
                                onClick={() => {
                                  handleChangeView(question?._id);
                                }}
                              >
                                <span>{"View Feedback"}</span>
                                {toggleEditor[question._id] ? (
                                  <KeyboardArrowUpIcon
                                    classes={{ root: styles["icon-class-up"] }}
                                  />
                                ) : (
                                  <StyledKeyboardArrowDownIcon
                                    classes={{
                                      root: styles["icon-class-down"],
                                    }}
                                  />
                                )}
                              </div>
                              {toggleEditor[question._id] && (
                                <EditorWrapper
                                  readOnly={true}
                                  content={question.feedback}
                                  setContent={setContent}
                                  isEditorLoading={isEditorLoading}
                                  setEditorLoading={setEditorLoading}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div
                  className={`${styles["module-section-nav-wrapper"]} ${
                    !showSubmit(section?.questions)
                      ? styles["no-mark-complete"]
                      : ""
                  }`}
                >
                  <div className={styles["nav-prev"]}>
                    {currentNav !== 0 ? (
                      <div
                        data-event-name="[Student] Module Questions Navigation - Prev"
                        className={styles["nav-prev"]}
                        onClick={() => {
                          const selectedSection = qAA[currentNav - 1];
                          let url = `/cohort/${cohortId}/curriculum/${moduleId}/questions?cohort=${searchParams.get(
                            "cohort"
                          )}&module=${searchParams.get("module")}`;
                          url = `${url}&sectionId=${selectedSection._id}`;
                          checkConditionAndShowDialog(() => {
                            history(url);
                            setCurrentNav(currentNav - 1);
                            setCurrAns({});
                            setSection(selectedSection);
                          });
                        }}
                      >
                        <StyledChevronLeftIcon /> Prev
                      </div>
                    ) : (
                      <div
                        data-event-name="[Student] Module Questions Navigation - Prev"
                        className={styles["nav-prev"]}
                        onClick={() => {
                          let url = `/cohort/${cohortId}/curriculum/${moduleId}/content?cohort=${searchParams.get(
                            "cohort"
                          )}&module=${searchParams.get(
                            "module"
                          )}&sectionId=${getNavigationalCourseContentSection()}`;
                          checkConditionAndShowDialog(() => {
                            history(url);
                            setCurrAns({});
                          });
                        }}
                      >
                        <StyledChevronLeftIcon />
                        {props.customization.module_label === ""
                          ? "Module"
                          : props.customization.module_label}
                        {" Course Content "}
                      </div>
                    )}
                  </div>

                  <div className={styles["nav-next"]}>
                    {currentNav < props.questionnaires?.length - 1 ? (
                      <div
                        data-event-name="[Student] Module Questions Navigation - Next"
                        className={styles["nav-next"]}
                        onClick={() => {
                          const selectedSection = qAA[currentNav + 1];
                          let url = `/cohort/${cohortId}/curriculum/${moduleId}/questions?cohort=${searchParams.get(
                            "cohort"
                          )}&module=${searchParams.get("module")}`;
                          url = `${url}&sectionId=${selectedSection._id}`;
                          checkConditionAndShowDialog(() => {
                            history(url);
                            setCurrentNav(currentNav + 1);
                            setCurrAns({});
                            setSection(selectedSection);
                          });
                        }}
                      >
                        Next <StyledChevronRightIcon />
                      </div>
                    ) : (
                      <>
                        {!lastModule &&
                          !(Object.keys(goToNextModule()).length === 0) && (
                            <div
                              className={styles["nav-next"]}
                              onClick={() => {
                                let nextModule = goToNextModule();
                                let url = `/cohort/${cohortId}/curriculum/${
                                  nextModule.module
                                }?cohort=${searchParams.get("cohort")}&module=${
                                  nextModule.name
                                }`;
                                checkConditionAndShowDialog(() => history(url));
                              }}
                            >
                              Next{" "}
                              {props.customization.module_label === ""
                                ? "Module"
                                : props.customization.module_label}
                              <StyledChevronRightIcon />
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>

                {showSubmit(section?.questions) && (
                  <div className={styles["mark-complete-btn"]}>
                    <Button
                      data-event-name="[Student] Module Questions Submit"
                      classes={{
                        root: styles["button-root"],
                      }}
                      variant="contained"
                      onClick={() => {
                        submitQuestionnaireData(section._id, section.status);
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, modules, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    questionnaires: modules.questionnaires,
    userInfo: user.userDetails,
    cohortInfo: cohort.cohortInfo,
    customization: user.customization,
    sections: modules.sections.sections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCohortInfo: (cohortId) => dispatch(getCohortInfo(cohortId)),
    getStudentModuleQuestionContent: (studentId, cohortId, moduleId) =>
      dispatch(getStudentModuleQuestionContent(studentId, cohortId, moduleId)),
    addModuleQuestionSection: (moduleId, title) =>
      dispatch(addModuleQuestionSection(moduleId, title)),
    updateModuleQuestionSection: (_id, title, questions) =>
      dispatch(updateModuleQuestionSection(_id, title, questions)),
    getModuleCourseContent: (cohortId, studentId, moduleId) =>
      dispatch(getModuleCourseContent(cohortId, studentId, moduleId)),
    submitQuestionnaire: (
      cohortId,
      studentId,
      moduleId,
      questionnaireId,
      payload,
      update
    ) =>
      dispatch(
        submitQuestionnaire(
          cohortId,
          studentId,
          moduleId,
          questionnaireId,
          payload,
          update
        )
      ),
    triggerASAPoints: () => dispatch(triggerASAPoints()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleQuestions);
