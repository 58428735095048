import { toast } from "react-hot-toast";
import API from "../../Helpers/axiosInit";
import { setAppLoading } from "./actions.app";
import * as tus from "tus-js-client";
import axios from "axios";
import { getModuleLiveSessions } from "./actions.modules";

export const setCohortInfo = (cohortInfo) => {
  return {
    type: "SET_COHORT_INFO",
    payload: {
      cohortInfo,
    },
  };
};

export const setApplicationInfo = (application) => {
  return {
    type: "SET_APPLICATION_INFO",
    payload: {
      applicationInfo: application,
    },
  };
};
export const setCohortAdditionalDetails = (content) => {
  return {
    type: "SET_COHORT_ADDITIONAL_DETAILS",
    payload: {
      additionalDetails: content.additionalDetails,
      autoEnroll: content.autoEnroll,
    },
  };
};
export const setSingleLiveSessionDetail = (content) => {
  return {
    type: "SET_SINGLE_LIVE_SESSION",
    payload: {
      singleLiveSessionDetail: content.liveSessionDetails[0],
    },
  };
};

export const setStudentCohortModuleProgress = (content) => {
  return {
    type: "SET_STUDENT_COHORT_MODULE_PROGRESS",
    payload: {
      studentCohortModuleProgress: content.moduleList,
    },
  };
};

export const setCohortCerticatesAndBadges = (content) => {
  return {
    type: "SET_COHORT_CERTIFICATES_AND_BADGES",
    payload: {
      certificatesandBadgesList: content.certificates
    }
  }
}

export const getCohortInfo = (cohortId) => async (dispatch, getState) => {
  dispatch(setAppLoading(true, "getCohortInfo - true"));
  const { user } = getState();
  let url = `/cohorts/${cohortId}`;
  if (user?.userDetails?.id) {
    const { role, id } = user.userDetails;
    if (role === "ORG_USER") {
      url = `students/${id}/cohorts/${cohortId}`;
    }
  }

  try {
    const res = await API.get(url);
    const { data: resData } = res;
    dispatch(setCohortInfo(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getCohortInfo - false"));
  }
};

export const setAllCohorts = (cohorts) => {
  return {
    type: "SET_ALL_COHORTS",
    payload: {
      cohorts,
    },
  };
};

/**
 *
 * @returns All the available cohorts created in Admin Panel
 */
export const getAllCohorts = () => async (dispatch, getState) => {
  dispatch(setAppLoading(true, "getAllCohorts - true"));
  const { user } = getState();
  let url = "/cohorts";
  if (user?.userDetails?.id) {
    const { role, id } = user.userDetails;
    if (role === "ORG_USER") {
      url = `students/${id}/cohorts`;
    } else if (role === "ORG_ADMIN") {
      url = `/cohorts/admin/all`;
    }
  }

  try {
    const res = await API.get(url);
    const { data: resData } = res;
    dispatch(setAllCohorts(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getAllCohorts - false"));
  }
};

export const setCohortCreated = (cohortData) => {
  return {
    type: "SET_NEW_COHORT",
    payload: {
      cohortData,
    },
  };
};

export const setNewCohort = (formContent) => async (dispatch) => {
  dispatch(setAppLoading(true, "setNewCohort - true"));
  try {
    const res = await API.post("/cohorts", formContent);
    if (res.status === 200) {
      toast.success(`Successfully created cohort - ${formContent.title} 🎉`);
    }
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
    dispatch(setAppLoading(false, "setNewCohort - false"));
  }
};

export const updateCohort = (formContent, cohortId) => async (dispatch) => {
  dispatch(setAppLoading(true, "updateCohort - true"));
  try {
    const res = await API.put(`/cohorts/${cohortId}`, formContent);
    if (res.status === 200) {
      toast.success(`Successfully updated cohort - ${formContent.title} 🎉`);
    }
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
    dispatch(setAppLoading(false, "updateCohort - false"));
  }
};

export const getApplicationInfo = (cohortId) => async (dispatch, getState) => {
  const { user } = getState();
  const studentId = user.userDetails.id;
  dispatch(setAppLoading(true, "getApplicationInfo - true"));
  try {
    const res = await API.get(
      `students/${studentId}/cohorts/${cohortId}/application`
    );
    const { data: resData } = res;
    dispatch(setApplicationInfo(resData));
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
    dispatch(setAppLoading(false, "getApplicationInfo - false"));
  }
};

export const submitApplication = (application) => async (dispatch) => {
  dispatch(setAppLoading(true, "submitApplication - true"));
  let success = false;
  try {
    const res = await API.post(`student/progress/application`, application);
    if (res.status === 200) {
      toast.success(`Successfully submitted the application`);
      success = true;
    }
    return {
      success,
    };
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
    return {
      success,
    };
  } finally {
    dispatch(setAppLoading(false, "submitApplication - false"));
  }
};

export const getStudentApplications =
  (cohortId) => async (dispatch, getState) => {
    dispatch(setAppLoading(true, "getStudentApplications - true"));
    try {
      const res = await API.get(`cohorts/applications/${cohortId}`);
      const { data: resData } = res;
      dispatch({
        type: "SET_COHORT_APPLICATIONS",
        payload: {
          applications: resData.applications,
          metadata: resData.metadata,
        },
      });
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "getStudentApplications - false"));
    }
  };

export const updateApplicationStatus =
  (cohortId, status, studentId) => async (dispatch, getState) => {
    dispatch(setAppLoading(true, "updateApplicationStatus - true"));
    try {
      const res = await API.put(`/application/${status}`, {
        studentId,
        cohortId,
      });
      if (res.status === 200) {
        toast.success(`Successfully updated application status`);
      }
      dispatch(getStudentApplications(cohortId));
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "updateApplicationStatus - false"));
    }
  };

export const cohortApplicationProcess =
  (questionsPayload, autoEnroll) => async (dispatch, getState) => {
    dispatch(setAppLoading(true, "cohortApplicationProcess - true"));
    try {
      const res = await API.post(`/questionnaires/cohort`, {
        ...questionsPayload,
        autoEnroll: autoEnroll,
      });
      if (res.status === 200) {
        toast.success(`Successfully Added Cohort Onboarding Details`);
      }

      dispatch(getOnboardingApplication(questionsPayload.cohortId));
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "cohortApplicationProcess-false"));
    }
  };

export const setOnboardingApplication = (data) => {
  return {
    type: "SET_ONBOARDING_APPLICATION_DATA",
    payload: {
      data,
    },
  };
};

/**
 *
 * @param {*} cohortId
 * @returns Existing application form data
 */
export const getOnboardingApplication = (cohortId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getOnboardingApplication - true"));
  try {
    const res = await API.get(`/questionnaires/cohort/${cohortId}`);
    const { data: resData } = res;
    dispatch(setOnboardingApplication(resData));
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
    dispatch(setOnboardingApplication({}));
  } finally {
    dispatch(setAppLoading(false, "getOnboardingApplication - false"));
  }
};

/**
 * updateApplicationProcessData
 * @param {*} questions
 * @returns Existing application form data
 */
export const updateApplicationProcessData =
  (cohortId, questionId, questions, autoEnroll) => async (dispatch) => {
    dispatch(setAppLoading(true, "getOnboardingApplication - true"));
    try {
      const res = await API.put(`/questionnaires/cohort/${questionId}`, {
        questions: questions,
        autoEnroll: autoEnroll,
        cohortId: cohortId,
      });
      if (res.status === 200) {
        toast.success(`Successfully Updated Cohort Onboarding Details`);
      }
      dispatch(getOnboardingApplication(cohortId));
    } catch (err) {
      if (err?.data?.message) {
        toast.error(err?.data?.message);
      }
    } finally {
      dispatch(setAppLoading(false, "getOnboardingApplication - false"));
    }
  };

export const updateCohortStatus =
  (publish, cohortId) => async (dispatch, getState) => {
    dispatch(setAppLoading(true, "updateCohortStatus - true"));
    try {
      const res = await API.put(`cohorts/publish/${cohortId}`, {
        publish,
      });
      if (res.status === 200) {
        toast.success(`Successfully updated cohort status`);
      }
      dispatch(getAllCohorts());
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "updateCohortStatus - false"));
    }
  };

export const getCohortStudents = (cohortId) => async (dispatch, getState) => {
  dispatch(setAppLoading(true, "getCohortStudents - true"));
  try {
    const res = await API.get(`cohorts/students/${cohortId}`);
    const { data: resData } = res;
    dispatch({
      type: "SET_COHORT_STUDENTS",
      payload: {
        students: resData.students,
      },
    });
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
    dispatch(setAppLoading(false, "getCohortStudents - false"));
  }
};

export const getViewOnboardingSubmissions =
  (studentId, cohortId, questionnaireId) => async (dispatch) => {
    dispatch(setAppLoading(true, "getViewOnboardingSubmissions - true"));
    try {
      const res = await API.get(
        `/students/${studentId}/cohorts/${cohortId}/questionaires/${questionnaireId}`
      );
      const { data: resData } = res;
      dispatch({
        type: "SET_ONBOARDING_SUBMISSIONS",
        payload: {
          onBoardingSubmissions: resData,
        },
      });
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "getViewOnboardingSubmissions - false"));
    }
  };

export const setVideoData = (metaData) => {
  return {
    type: "SET_VIDEO_METADATA",
    payload: {
      metaData,
    },
  };
};

export const setUploadProgress = (progress) => {
  return {
    type: "SET_UPLOAD_PROGRESS",
    payload: {
      progress,
    },
  };
};

const MAX_PARALLEL_UPLOADS = 5;
const RETRY_LIMIT = 3;
const MIN_PART_SIZE = 5 * 1024 * 1024; // Minimum part size: 5MB

export const fetchVideoURL = (file) => async (dispatch) => {
  dispatch(setUploadProgress(0)); // Reset progress at the start

  try {
    const initiateResponse = await API.post('/cohorts/initMultiPartUpload', { fileName: file.name });
    const uploadId = initiateResponse.data.uploadId;
    const path = initiateResponse.data.path;
    const totalSize = file.size;
    const partSize = Math.max(MIN_PART_SIZE, totalSize / 1000);
    const totalParts = Math.ceil(totalSize / partSize);
    let uploadProgress = new Array(totalParts).fill(0);
    let etags = new Array(totalParts).fill("");

    const updateOverallProgress = () => {
      const totalUploaded = uploadProgress.reduce((acc, curr) => acc + curr, 0);
      dispatch(setUploadProgress(Math.round((totalUploaded / totalSize) * 100)));
    };

    const uploadQueue = [];
    let start = 0;
    for (let partIndex = 0; partIndex < totalParts; partIndex++) {
      const partBlob = file.slice(start, start + partSize);
      const uploadTask = () => uploadPartWithRetry(partBlob, partIndex + 1, uploadId, path, etags, partIndex, uploadProgress, updateOverallProgress);
      uploadQueue.push(uploadTask);
      start += partSize;
    }

    await processUploadQueue(uploadQueue);

    const completedParts = etags.map((etag, index) => ({
      PartNumber: index + 1,
      ETag: etag
    }));

    const response = await API.post('/cohorts/completeMultiPartUpload', {
      uploadId,
      path,
      completedParts
    });

    dispatch(setVideoData({ title: file.name, url: response.data.videoUrl }));
    toast.success(`Video uploaded successfully! Preview will be available soon.`, { duration: 10000 });
    return response;
  } catch (error) {
    console.error("Error during multipart upload:", error);
    dispatch(setUploadProgress(0));
  }
};

async function processUploadQueue(uploadQueue) {
  const activeUploads = new Set();
  while (uploadQueue.length > 0 || activeUploads.size > 0) {
    if (activeUploads.size < MAX_PARALLEL_UPLOADS && uploadQueue.length > 0) {
      const uploadTask = uploadQueue.shift();
      const uploadPromise = uploadTask().finally(() => activeUploads.delete(uploadPromise));
      activeUploads.add(uploadPromise);
    } else {
      await Promise.race(activeUploads);
    }
  }
}

const uploadPartWithRetry = async (partBlob, partNumber, uploadId, path, etags, partIndex, uploadProgress, updateProgress, retries = 0) => {
  try {
    const etag = await uploadPart(partBlob, partNumber, uploadId, path, partIndex, uploadProgress, updateProgress);
    etags[partIndex] = etag;
  } catch (error) {
    if (retries < RETRY_LIMIT) {
      await uploadPartWithRetry(partBlob, partNumber, uploadId, path, etags, partIndex, updateProgress, retries + 1);
    } else {
      throw error;
    }
  }
};

const uploadPart = async (partBlob, partNumber, uploadId, path, partIndex, uploadProgress, updateOverallProgress) => {
  const formData = new FormData();
  formData.append('testvideo', partBlob);
  formData.append('uploadId', uploadId);
  formData.append('path', path);
  formData.append('partNumber', partNumber.toString());

  const uploadResponse = await API.post('/cohorts/video-uploads', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const bytesUploaded = progressEvent.loaded;
      updatePartProgress(partIndex, bytesUploaded, uploadProgress, updateOverallProgress);
    }
  });

  return uploadResponse.data.part['ETag'];
};

const updatePartProgress = (partIndex, bytesUploaded, uploadProgress, updateOverallProgress) => {
  uploadProgress[partIndex] = bytesUploaded;
  updateOverallProgress();
};

export const uploadVideoCaptions = (videoId, file) => async () => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    return await API.post(`/cohorts/uploadFile/${videoId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      toast.success(`Successfully uploaded the caption file 🎉`, {
        duration: 5000,
      });
      return response;
    })
  } catch (error) {
    toast.error(`Failed to upload the caption file. Please try again`, {
      duration: 5000,
    });
    console.error("failed to upload the provided caption file to vimeo", error);
  }
};

export const deleteVideo = (videoId, type) => async () => {
  try {
    const res = await API.delete(`/cohorts/video/${videoId}`);
    toast.success(`Successfully deleted the ${type} 🎉`, { duration: 5000 });
    return res;
  } catch (error) {
    toast.error(`Failed to delete the video`, { duration: 5000 });
    console.error(error);
  }
};

export const deleteCaption = (videoId, captionId) => async () => {
  try {
    const response = await axios.delete(
      `https://api.vimeo.com/videos/${videoId}/texttracks/${captionId}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
        },
      }
    );
    toast.success(`Successfully deleted the caption file 🎉`, {
      duration: 5000,
    });
    return response;
  } catch (error) {
    toast.error(`Failed to delete the caption file`, { duration: 5000 });
    console.error(error);
  }
};

// GCP video upload implementation
// export const fetchVideoURL = (file) => new Promise((resolve, reject) => {
//   const { originalname, buffer } = file

//   const blob = bucket.file(originalname)
//   const blobStream = blob.createWriteStream({
//     contentType: 'video/mp4',
//     metadata: {
//       contentType: 'video/mp4',
//     }
//   })
//   blobStream.on('finish', () => {
//     const publicUrl = `https://storage.googleapis.com/${bucket.name}/${blob.name}`;
//     resolve(publicUrl)
//   })
//   .on('error', (error) => {
//     reject(`Unable to upload video, something went wrong`, error)
//   })
//   .end(buffer)
// });

export const cloneCohort = (cohortId) => async (dispatch) => {
  dispatch(setAppLoading(true, "cloneCohort - true"));
  try {
    const res = await API.post(`/cohorts/clone/${cohortId}`);
    if (res.status === 200) {
      toast.success("Cloned Cohort Successfully");
    }
    dispatch(getAllCohorts());
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
    dispatch(setAppLoading(false, "cloneCohort - false"));
  }
};

export const createLiveSession = (payload, moduleId) => async (dispatch) => {
  dispatch(setAppLoading(true, "Create Live Session - true"));
  try {
    const res = await API.post(`/liveSession`, {
      ...payload,
    });
    if (res.status === 200) {
      toast.success(`Successfully created Live Session`);
      dispatch(getLiveSessions(payload.cohortId));
      dispatch(getModuleLiveSessions(payload.cohortId, moduleId))
    }
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
    dispatch(setAppLoading(false, " Create Live Session - false"));
  }
};

export const getLiveSessions = (cohortId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getLiveSession - true"));
  try {
    const res = await API.get(`/liveSession/${cohortId}`);
    const { data: resData } = res;
    dispatch({
      type: "SET_COHORT_LIVE_SESSIONS",
      payload: {
        liveSessionDetails: resData,
      },
    });
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
    dispatch(setAppLoading(false, "getLiveSession - FALSE"));
  }
};

export const getSingleLiveSession =
  (cohortId, liveSessionId) => async (dispatch) => {
    dispatch(setAppLoading(true, "getSingleLiveSession - true"));
    try {
      const res = await API.get(`/liveSession/${cohortId}/${liveSessionId}`);
      const { data: resData } = res;
      dispatch(setSingleLiveSessionDetail(resData));
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "getSingleLiveSession - false"));
    }
  };
export const updateCohortAdditionalDetails =
  (cohortId, payload) => async (dispatch) => {
    dispatch(setAppLoading(true, "UpdateCohortAdditionalDetails - true"));
    try {
      const res = await API.put(`/cohorts/additionalDetails/${cohortId}`, {
        additionalDetails: payload,
      });
      dispatch(getCohortInfo(cohortId));
      dispatch(getCohortAdditionalDetails(cohortId));
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "UpdateCohortAdditionalDetails - false"));
    }
  };

export const getCohortAdditionalDetails = (cohortId) => async (dispatch) => {
  dispatch(setAppLoading(true, "GetCohortAdditionalDetails - true"));
  try {
    const res = await API.get(`/cohorts/additionalDetails/${cohortId}`);
    const { data: resData } = res;
    dispatch(setCohortAdditionalDetails(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "GetCohortAdditionalDetails - false"));
  }
};

export const getdtaDocumentLink = (cohortId, studentId) => async (dispatch) => {
  dispatch(setAppLoading(true, "GetCohortStudentDtaDocument - true"));
  try {
    const res = await API.get(
      `/students/${studentId}/cohorts/${cohortId}/dtaDocument`
    );
    const { data: resData } = res;
    dispatch(setOnboardingApplication({ dtaDocumentLink: resData }));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "GetCohortStudentDtaDocument - false"));
  }
};

export const uploadDtaDocumentLink =
  (cohortId, studentId, dtaDocumentLink) => async (dispatch) => {
    dispatch(setAppLoading(true, "uploadDtaDocumentLink - true"));
    try {
      const res = await API.put(
        `/students/${studentId}/cohorts/${cohortId}/dtaDocument`,
        {
          dtaDocumentLink: dtaDocumentLink,
        }
      );
      if (res.status === 200) {
        toast.success("Updated Additional Details");
      } else {
        toast.error("File Too Large");
      }
      dispatch(getdtaDocumentLink(cohortId, studentId));
    } catch (err) {
      toast.error("File Too Large");
    } finally {
      dispatch(setAppLoading(false, "uploadDtaDocumentLink - false"));
    }
  };

export const deleteCohort = (cohortId) => async (dispatch) => {
  try {
    const res = await API.delete(`/cohorts/${cohortId}`);
    if (res.status === 200) {
      toast.success("cohort deleted Successfully");
    }
    dispatch(getAllCohorts());
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  }
};

export const getAllCohortsStudent =
  (cohortId, page, limit, searchvalue) => async (dispatch) => {
    dispatch(setAppLoading(true, "getAllCohortStudent - true"));
    try {
      const res = await API.get(
        `/reporting?cohortId=${cohortId}&page=${page}&limit=${limit}&search=${searchvalue}`
      );
      const { data: resData } = res;
      dispatch({
        type: "SET_COHORT_STUDENTS",
        payload: {
          students: resData.response,
          totalStudents: resData.totalCount,
        },
      });
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "getAllCohortStudent-false"));
    }
  };

export const getStudentCohortModuleProgress =
  (cohortId, studentId) => async (dispatch) => {
    dispatch(setAppLoading(true, "getStudentCohortModuleProgress - true"));
    try {
      const res = await API.get(
        `/reporting/singleStudentReport?cohortId=${cohortId}&studentId=${studentId}`
      );
      const { data: resData } = res;
      dispatch(setStudentCohortModuleProgress(resData));
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "getStudentCohortModuleProgress - false"));
    }
  };

export const getLiveSessionsForStudents =
  (studentId, cohortId, moduleId) => async (dispatch) => {
    dispatch(setAppLoading(true, "getLiveSessionsForStudents - true"));
    try {
      const res = await API.get(
        `/liveSession/${cohortId}?moduleId=${moduleId}&sudentId=${studentId}`
      );
      const { data: resData } = res;
      dispatch({
        type: "SET_COHORT_LIVE_SESSIONS",
        payload: {
          liveSessionDetails: resData,
        },
      });
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "getLiveSessionsForStudents - FALSE"));
    }
  };

export const deleteSelectedStudent =
  (cohortId, studentId) => async (dispatch) => {
    try {
      dispatch(setAppLoading(true, "deleteSelectedStudent - true"));
      const res = await API.delete(`/application`, {
        data: {
          cohortId,
          studentId,
        },
      });
      toast.success(res.data.message);
      dispatch(getStudentApplications(cohortId));
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      }
    } finally {
      dispatch(setAppLoading(false, "deleteSelectedStudent - false"));
    }
  };

export const deleteLiveSession =
  (livesessionId, cohortId, moduleId) => async (dispatch) => {
    try {
      dispatch(setAppLoading(true, "deleteLiveSession - true"));
      const res = await API.delete(`/liveSession/${livesessionId}`);
      if (res.status === 200) toast.success("Delete live session successfully");
      dispatch(getLiveSessions(cohortId));
      dispatch(getModuleLiveSessions(cohortId, moduleId))
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "deleteLiveSession - false"));
    }
  };

export const updateLiveSession =
  (liveSessionId, payload, moduleId) => async (dispatch) => {
    dispatch(setAppLoading(true, "Update Live Session - true"));
    try {
      const res = await API.put(`/liveSession/${liveSessionId}`, {
        ...payload,
      });
      if (res.status === 200) {
        toast.success(`Successfully Updated Live Session`);
        dispatch(getLiveSessions(payload.cohortId));
        dispatch(getModuleLiveSessions(payload.cohortId, moduleId))
      }
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "Update Live Session - false"));
    }
  };

export const CohortStats =
  (cohortId) => async (dispatch) => {
    try {
      const res = await API.get(`/students/getReviewCount/${cohortId}`);
      if (res.status === 200) {
        dispatch({
          type: "SET_COHORT_STATS",
          payload: {
            cohortStats: res.data.featureList,
          },
        });
      }
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    }
  };

export const getCohortCertifcatesAndBadges = () => async (dispatch) => {
  dispatch(setAppLoading(true, "get badges and certificates - true"));
  try {
    const res = await API.get('/certificates');
    if (res.status === 200) {
      dispatch(setCohortCerticatesAndBadges(res.data))
    }
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }

  } finally {
    dispatch(setAppLoading(false, "get badges and certificates - false"));
  }
}