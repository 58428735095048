import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import styles from "../../../Stylesheets/Modules/moduleQuestions.module.scss";
import CohortBackNav from "../../../Components/CohortBackNav";
import { MenuItem, Select } from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Add from "@mui/icons-material/Add";
import { Switch } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import EditorWrapper from "../../../Components/EditorWrapper";
import optionsIcon from "../../../Assets/Images/optionsIcon.svg";
import addIconOptions from "../../../Assets/Images/addIconOptions.svg";
import ConfirmDialog from "../../../Reusable/Modals/ConfirmDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import {
  getModuleQuestionContent,
  addModuleQuestionSection,
  updateModuleQuestionSection,
  deletequestionaire,
  updateQuestionaireSections,
  updateModuleQuestionsOrder,
  getModuleOptions,
} from "../../../Store/Actions/actions.modules";
import {
  FilledButton,
  OutlinedButton,
  OutlinedButtonSmall,
  TransparentButton,
} from "../../../Components/StyledComponents/Button.style";
import {
  StyledCloseIcon,
  StyledDeleteIcon,
  StyledDragHandleIcon,
  StyledEditIcon,
  StyledMenuIcon,
  StyledQuestionModeEditIcon,
} from "../../../Components/StyledComponents/Icons.style";
import ModuleSectionCreationForm from "../../../Reusable/ModuleSectionCreationForm";
import { logPageView } from "../../../Utils/analytics";

const ModuleQuestionCreation = (props) => {
  const history = useNavigate();
  const { cohortId, moduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [section, setSection] = useState();
  const [isEditMode, setEditMode] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [secTitle, setSecTitle] = useState("");
  const [module, setModule] = useState();
  const [draggedQuestionaireSections, setDraggedQuestionaireSections] =
    useState(false);
  const [toggle, updateToggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState();
  const [questionContent, setQuestionContent] = useState();
  const [addOptions, setAddOptions] = useState([]);
  const [addOptionsInput, setaddOptionsInput] = useState(false);
  const [addOptionsInputTxt, setaddOptionsInputTxt] = useState("");
  const [newQuestionnaireSectionCreated, setNewQuestionnaireSectionCreated] =
    useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [gradingOptions, setGradingOptions] = useState([
    { key: "PASS_FAIL", value: "Pass/Fail" },
    { key: "FIXED_GRADING", value: "Fixed Grading" },
  ]);
  const [gradingSection, setGradingSection] = useState(false);
  const [selectedGradingOption, setSelectedGradingOption] = useState("");
  const [limitedAttempts, setLimitedAttempts] = useState(false);
  const [selectedAttemptsOption, setSelectedAttemptsOption] =
    useState("unlimited");
  const types = [
    {
      key: "TEXT",
      value: "TEXT",
      icon: NotesIcon,
    },
    {
      key: "RADIO",
      value: "RADIO",
      icon: RadioButtonCheckedIcon,
    },
    {
      key: "CHECKBOX",
      value: "CHECKBOX",
      icon: CheckBoxIcon,
    },
  ];
  const attemptsOption = [
    {
      key: "limited",
      label: "Limited",
    },
    {
      key: "unlimited",
      label: "Unlimited",
    },
  ];
  const [questionType, setQuestionType] = useState(types[0].key);
  const [qAA, setQAA] = useState([]);
  const [currQues, setCurrQues] = useState({});
  const [deletSingleQuestion, setDeletSingleQuestion] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [hide, setHide] = useState(true);
  const [isEditorLoading, setEditorLoading] = useState(true);
  const [isEditorLoadingQuestion, setEditorLoadingQuestion] = useState(true);
  const [credits, setCredit] = useState(0);
  const [creditsLeft, setCreditsLeft] = useState();
  // const [checked, setChecked] = useState(false);
  const [allowResubmission, setAllowResubmission] = useState(false);
  const [resubmission, setResubmission] = useState(1);
  const [currSelectedSection, setCurrSelectedSection] = useState("");
  const [originalCredit, setOriginalCredit] = useState(0);

  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setEditorLoading(false);
  }, []);
  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setTimeout(() => {
      setEditorLoadingQuestion(false);
    }, 1000);
  }, []);

  useEffect(() => {
    props.getModuleOptions(moduleId);
  }, []);

  useEffect(() => {
    if (deletSingleQuestion) {
      setDeletSingleQuestion(false);
      const tqAA = qAA?.reduce((res, curr) => {
        let tc = curr;
        if (tc._id === section._id) {
          tc = {
            ...curr,
            questions: [...Object.values(currQues)],
          };
        }
        res.push(tc);
        return res;
      }, []);
      setQAA(tqAA);
      updateQuestionnaireData(section, true);
    }
  }, [deletSingleQuestion]);

  useEffect(() => {
    getAllQuesionnaireData(moduleId);
  }, []);

  const getAllQuesionnaireData = async (moduleId) => {
    await props.getModuleQuestionContent(moduleId);
  };

  useEffect(() => {
    if (props.questionnaires?.length > 0) {
      const selectedSection = props.questionnaires?.[0];
      setSection(selectedSection);
      setQAA(props.questionnaires);
    } else {
      setSection(undefined);
      setQAA([]);
    }
  }, [props.questionnaires]);

  useEffect(() => {
    if (qAA?.length > 0) {
      if (qAA[0]) {
        if (searchParams.get("sectionId")) {
          const sectionId = searchParams.get("sectionId");
          const selection = props.questionnaires?.find(
            (sec) => sec._id === sectionId
          );
          setSection(selection);
          if (selection?.questions) {
            let ques = selection?.questions;
            ques = ques?.reduce((res, curr) => {
              res[curr._id] = curr;
              return res;
            }, {});
            setCurrQues(ques);
          }
        } else {
          let url = `/admin/cohort/${cohortId}/module/${moduleId}/questions?cohort=${searchParams.get(
            "cohort"
          )}&module=${searchParams.get("module")}`;
          url = `${url}&sectionId=${
            newQuestionnaireSectionCreated
              ? qAA[qAA?.length - 1]?._id
              : section?._id
          }`;
          history(url);
          setSection(qAA[0]);
          if (qAA[0]?.questions) {
            let ques = qAA[0]?.questions;
            ques = ques?.reduce((res, curr) => {
              res[curr._id] = curr;
              return res;
            }, {});
            setCurrQues(ques);
          }
        }
        if (newQuestionnaireSectionCreated) {
          let url = `/admin/cohort/${cohortId}/module/${moduleId}/questions?cohort=${searchParams.get(
            "cohort"
          )}&module=${searchParams.get("module")}`;
          url = `${url}&sectionId=${qAA[qAA?.length - 1]?._id}`;
          history(url);
          setNewQuestionnaireSectionCreated(false);
        }
      }
    }
  }, [qAA, searchParams]);

  const updateQuestionnaireData = async (section, fromDelete = false) => {
    if (fromDelete) {
      await props.updateModuleQuestionSection(
        section._id,
        section.title,
        [...Object.values(currQues)],
        section.weightage,
        section.questionnaireTotalWeightage
      );
    } else {
      let payload = {
        question: questionContent,
        questionType,
        weightage: parseInt(credits),
        typeOfQuestion: selectedGradingOption,
        numberOfResubmissionAllowed: limitedAttempts
          ? parseInt(resubmission)
          : undefined,
        allowResubmission: allowResubmission,
        feedback: content,
        unlimitedTries: !limitedAttempts,
      };
      if (questionType === "RADIO") {
        payload.values = addOptions;
        payload.answer = selectedAnswers[0];
      }
      if (questionType === "CHECKBOX") {
        payload.values = addOptions;
        payload.answer = selectedAnswers;
      }
      let updatedPayload;
      if (currSelectedSection) {
        currQues[currSelectedSection] = {
          ...currQues[currSelectedSection],
          ...payload,
        };
        updatedPayload = Object.values(currQues);
      } else {
        updatedPayload = Object.values(currQues).concat({ ...payload });
      }

      await props.updateModuleQuestionSection(
        section._id,
        section.title,
        [...updatedPayload],
        parseInt(creditsLeft),
        section.questionnaireTotalWeightage
      );
    }
    getAllQuesionnaireData(moduleId);
    clearPopUpData();
  };

  const clearPopUpData = () => {
    setCurrSelectedSection("");
    setQuestionType("TEXT");
    setQuestionContent("");
    setSelectedGradingOption("");
    setContent("");
    setAddOptions([]);
    setSelectedAnswers([]);
    setCredit(0);
    setOriginalCredit(0);
    setCreditsLeft(section.weightage);
    setAllowResubmission(false);
    setResubmission(1);
    setGradingSection(false);
    setLimitedAttempts(true);
    updateToggle(false);
    setSelectedAttemptsOption("limited");
  };

  const onEditUpdateUIState = (e, question) => {
    e.stopPropagation();
    let newQues = { ...currQues };
    const data = newQues[question._id];

    setQuestionType(data.questionType);
    setQuestionContent(data.question);
    setContent(data?.feedback);
    setOriginalCredit(data.weightage);

    if (data.questionType === "RADIO" || data.questionType === "CHECKBOX") {
      setAddOptions(data.values);
      if (data.questionType === "RADIO") {
        setSelectedAnswers([...selectedAnswers, data.answer]);
      } else {
        setSelectedAnswers(data.answer);
      }
    }
    if (data.typeOfQuestion === "FIXED_GRADING") {
      setSelectedGradingOption("FIXED_GRADING");
      setCredit(data.weightage);
      setGradingSection(true);
    } else {
      setSelectedGradingOption("PASS_FAIL");
      setCredit(data.weightage);
      setGradingSection(false);
    }
    setCreditsLeft(section.weightage);
    setAllowResubmission(data.allowResubmission);
    setResubmission(data.numberOfResubmissionAllowed);
    if (data.unlimitedTries) {
      setLimitedAttempts(false);
      setResubmission(0);
      setSelectedAttemptsOption("unlimited");
    } else {
      setLimitedAttempts(true);
      setSelectedAttemptsOption("limited");
    }
    updateToggle(true);
  };

  const deletequestionsectionhandler = (moduleId, questionaireId) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    props.deletequestionaire(moduleId, questionaireId);
    props.getModuleQuestionContent(moduleId);
  };

  const getSectionTitle = (sectionTitle) => {
    const titletoDisplay =
      sectionTitle.length > 20
        ? sectionTitle.substring(0, 20) + "..."
        : sectionTitle;
    return titletoDisplay;
  };

  const handleCreditChange = (e) => {
    let newCredit = e.target.value;
    // Check if the value is negative or not a number, and reset it to a minimum value
    if (newCredit < 0 || isNaN(newCredit)) {
      newCredit = 0;
    }

    if (newCredit > 100) {
      setCredit((prev) => prev);
      return;
    }

    const updatedCreditsLeft =
      section.weightage -
      newCredit +
      (isNaN(originalCredit) ? 0 : originalCredit);
    setCredit(newCredit);
    setCreditsLeft(updatedCreditsLeft);
  };

  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);
  let _qAA = [...qAA];
  const handleDrag = () => {
    const draggedSections = _qAA.splice(dragItem.current, 1)[0];

    _qAA.splice(dragOverItem.current, 0, draggedSections);

    dragItem.current = null;
    dragOverItem.current = null;
    setQAA(_qAA);
    if (JSON.stringify(qAA) === JSON.stringify(_qAA)) {
      setDraggedQuestionaireSections(false);
    } else {
      setDraggedQuestionaireSections(true);
    }
  };
  const questionsNav = () => {
    return (
      <div
        className={styles["questions-nav"]}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={styles["menu-close-icon"]}
          onClick={() => {
            setHide(true);
          }}
        >
          <StyledCloseIcon />
        </div>
        <div className={styles["nav-module-name"]}>{module?.name}</div>
        {qAA?.map((sec, index) => {
          const isEditable = isEditMode && section?._id === sec._id;
          return (
            <div
              key={`${sec?._id}-item`}
              className={`
                ${styles["nav-item"]} 
                ${sec?._id === section?._id ? styles["selected"] : ""}
                ${isEditable ? styles["editable"] : ""}
                ${styles["admin-nav-item"]} 
              `}
              onClick={() => {
                const selectedSection = qAA?.find((s) => s._id === sec?._id);
                // update the search params with the current selection
                let url = `/admin/cohort/${cohortId}/module/${moduleId}/questions?cohort=${searchParams.get(
                  "cohort"
                )}&module=${searchParams.get("module")}`;
                url = `${url}&sectionId=${selectedSection._id}`;
                history(url);
                setSection(selectedSection);
                setCurrQues(arrToObj(selectedSection.questions, "_id"));
                setHide(true);
              }}
              style={{ color: props.themeInfo[0]?.secondarycolor }}
              //this is used for dragging the sections
              draggable
              onDragStart={(e) => (dragItem.current = index)}
              onDragEnter={(e) => (dragOverItem.current = index)}
              onDragEnd={(e) => handleDrag()}
              onDragOver={(e) => e.preventDefault()}
            >
              <Tooltip title={sec.title}>
                <div className={styles["nav-item-title"]}>
                  {getSectionTitle(sec.title)}
                </div>
              </Tooltip>
              <div className={styles["nav-item-weightage"]}>
                {sec.questionnaireTotalWeightage}
              </div>
              <div className={styles["nav-item-actions"]}>
                <StyledEditIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    const selectedSection = qAA?.find(
                      (s) => s._id === sec?._id
                    );
                    setSection(selectedSection);
                    setUpdateMode(true);
                    setOpenModal(true);
                    setSecTitle(selectedSection.title);
                  }}
                />
                <StyledDeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    qAA?.filter((s) => s._id !== sec?._id);
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure you want to delete",
                      onConfirm: () => {
                        deletequestionsectionhandler(moduleId, sec?._id);
                      },
                    });
                  }}
                />
                <span>
                  <StyledDragHandleIcon className={styles["drag-icon"]} />
                </span>
              </div>
            </div>
          );
        })}
        <div className={styles["create-content-container"]}>
          <TransparentButton
            startIcon={
              <Add
                classes={{
                  root: styles["arrow-icon"],
                }}
              />
            }
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            onClick={() => {
              setSecTitle("");
              setOpenModal(true);
            }}
          >
            Add Questionaire Section
          </TransparentButton>
          <GenericModal
            open={openModal}
            togglePopUp={setOpenModal}
            setUpdateMode={setUpdateMode}
          >
            <ModuleSectionCreationForm
              togglePopUp={setOpenModal}
              credits={props.sectionCreditsLeft}
              updateMode={updateMode}
              setUpdateMode={setUpdateMode}
              sectionTitle={secTitle}
              sectionType={"Questionnaire"}
              section={section}
              questions={props.questionnaires}
              moduleOptions={props.moduleOptions}
              setNewQuestionnaireSectionCreated={
                setNewQuestionnaireSectionCreated
              }
            />
          </GenericModal>
          {draggedQuestionaireSections && (
            <TransparentButton
              onClick={(e) => {
                e.preventDefault();
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure you want to change the order?",
                  onConfirm: () => {
                    var orderedArray = [];
                    qAA.forEach((item) => {
                      orderedArray.push(item._id);
                    });
                    props.updateModuleQuestionsOrder(moduleId, orderedArray);
                    setDraggedQuestionaireSections(false);
                    setConfirmDialog({ ...confirmDialog, isOpen: false });
                  },
                });
              }}
              bgcolor={
                props.themeInfo[0]?.primarycolor
                  ? props.themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
            >
              Save Changes ?
            </TransparentButton>
          )}
        </div>
      </div>
    );
  };

  const arrToObj = (data, key) => {
    return data?.reduce((res, curr) => {
      res[curr[key]] = curr;
      return res;
    }, {});
  };

  const dragItem2 = React.useRef(null);
  const dragOverItem2 = React.useRef(null);
  const handleDrag2 = () => {
    let _questions = [...Object.values(currQues)];
    const draggedQuestions = _questions.splice(dragItem2.current, 1)[0];
    _questions.splice(dragOverItem2.current, 0, draggedQuestions);
    dragItem2.current = null;
    dragOverItem2.current = null;

    setCurrQues(_questions);
  };

  const isSaveButtonDisabled = () => {
    if (questionType === "TEXT") {
      return (
        questionContent === "" ||
        creditsLeft > 100 ||
        creditsLeft < 0 ||
        selectedGradingOption === ""
      );
    } else if (questionType === "RADIO" || questionType === "CHECKBOX") {
      return (
        questionContent === "" ||
        selectedAnswers.length === 0 ||
        creditsLeft > 100 ||
        creditsLeft < 0 ||
        selectedGradingOption === ""
      );
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Module Questionnaire Creation Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  return (
    <div className={styles["module-questions-main"]}>
      <CohortBackNav
        link={`/admin/createModule/${cohortId}?cohort=${searchParams.get(
          "cohort"
        )}`}
        text={`Back to ${
          props.customization.module_label || "Module"
        } ${searchParams.get("module")}`}
      />
      <div
        className={`${styles["module-questions-container"]} ${
          !hide ? styles["with-hidden-nav"] : ""
        }`}
      >
        {!hide && (
          <div className={styles["floating-nav"]}>{questionsNav()}</div>
        )}
        {hide && (
          <div
            className={styles["questions-menu-icon"]}
            onClick={() => {
              setHide(false);
            }}
          >
            <StyledMenuIcon />
          </div>
        )}
        {questionsNav()}
        <div
          className={`${styles["questions-body"]} ${styles["disable-scroll"]}`}
        >
          {section ? (
            <>
              <div
                key={`${section?._id}-body`}
                className={styles["main-body-container"]}
              >
                <div className={styles["update-actions-btn-container"]}>
                  <div className={styles["title"]}>{section.title}</div>
                  <div className={styles["wrapper"]}>
                    {section?.dueDate && (
                      <div className={styles["due-date"]}>
                        Due Date : {section?.dueDate.substring(0, 10)}
                      </div>
                    )}
                    <OutlinedButtonSmall
                      classes={{
                        root: styles["add-question-btn"],
                      }}
                      onClick={() => {
                        clearPopUpData();
                        updateToggle(true);
                      }}
                      bgcolor={
                        props.themeInfo[0]?.primarycolor
                          ? props.themeInfo[0]?.primarycolor
                          : "#0e1555"
                      }
                      fontFamily={
                        props.themeInfo[0]?.fontfamily
                          ? props.themeInfo[0]?.fontfamily
                          : "Nunito"
                      }
                    >
                      <div className={`${styles["questions-add-button"]}`}>
                        <AddCircleIcon />
                        Add
                      </div>
                    </OutlinedButtonSmall>
                  </div>
                  <GenericModal
                    show="false"
                    maxWidth="lg"
                    open={toggle}
                    togglePopUp={updateToggle}
                  >
                    <div className={styles["modal-container-wrapper"]}>
                      <div className={styles["modal-question-section"]}>
                        <div className={styles["modal-header"]}>
                          Create Question
                        </div>
                        <div className={styles["model-questions"]}>
                          <div className={styles["model-questions-type"]}>
                            Question Type
                          </div>
                          <div>
                            <Select
                              classes={{
                                select: styles["question-type-select"],
                              }}
                              value={questionType}
                              onChange={(e) => {
                                setQuestionType(e.target.value);
                              }}
                            >
                              {types?.map((type) => {
                                return (
                                  <MenuItem
                                    key={type.key}
                                    value={type.key}
                                    classes={{
                                      root: styles["menu-item-root"],
                                      selected: styles["selected-item"],
                                    }}
                                  >
                                    <type.icon />
                                    <span className={styles["menu-item-text"]}>
                                      {type.value}
                                    </span>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className={styles["content-wrapper"]}>
                          <div className={styles["model-add-questions"]}>
                            Add Questions
                          </div>

                          <EditorWrapper
                            showPreview={true}
                            readOnly={false}
                            content={questionContent}
                            setContent={setQuestionContent}
                            isEditorLoading={isEditorLoading}
                            setEditorLoading={setEditorLoading}
                          />
                        </div>
                        {questionType !== "TEXT" ? (
                          <div className={styles["optionsCard"]}>
                            <div className={styles["optionsHeader"]}>
                              <div className={styles["model-add-questions"]}>
                                Options
                              </div>
                              <div className={styles["model-add-questions"]}>
                                Set Correct Answer
                              </div>
                            </div>
                            <div>
                              {addOptions.map((option, key) => {
                                return (
                                  <div
                                    className={styles["setAnswerValue"]}
                                    key={`${key}-${option}`}
                                  >
                                    <label
                                      className={styles["model-add-questions"]}
                                    >
                                      <img
                                        className={
                                          styles["setAnswerOptionsImg"]
                                        }
                                        alt=""
                                        src={optionsIcon}
                                      ></img>
                                      {option}
                                    </label>
                                    <div
                                      className={
                                        styles["add-option-delete-container"]
                                      }
                                    >
                                      {questionType === "RADIO" ? (
                                        <input
                                          name="setAnswer"
                                          className={styles["setOptionsRadio"]}
                                          type="radio"
                                          onChange={(e) => {
                                            setSelectedAnswers([
                                              e.target.value,
                                            ]);
                                          }}
                                          checked={
                                            selectedAnswers[0] === option
                                              ? true
                                              : false
                                          }
                                          value={option}
                                        />
                                      ) : (
                                        <input
                                          className={styles["setOptionsRadio"]}
                                          type="checkbox"
                                          onChange={(event) => {
                                            const { value } = event.target;
                                            if (
                                              selectedAnswers.includes(value)
                                            ) {
                                              setSelectedAnswers(
                                                selectedAnswers.filter(
                                                  (option) => option !== value
                                                )
                                              );
                                            } else {
                                              setSelectedAnswers([
                                                ...selectedAnswers,
                                                value,
                                              ]);
                                            }
                                          }}
                                          checked={selectedAnswers.includes(
                                            option
                                          )}
                                          value={option}
                                        />
                                      )}

                                      <DeleteOutlineIcon
                                        onClick={() => {
                                          setAddOptions(
                                            [...addOptions].filter(
                                              (x) => x !== option
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                              {addOptionsInput ? (
                                <div>
                                  <input
                                    onChange={(e) => {
                                      setaddOptionsInputTxt(e.target.value);
                                    }}
                                    className={styles["addOptionInput"]}
                                    type="text"
                                  />
                                  <div
                                    onClick={(e) => {
                                      setaddOptionsInput(!addOptionsInput);
                                      setAddOptions([
                                        ...addOptions,
                                        addOptionsInputTxt,
                                      ]);
                                    }}
                                    className={styles["saveTxt"]}
                                  >
                                    Save
                                  </div>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    setaddOptionsInput(!addOptionsInput);
                                  }}
                                  className={styles["addOptionsCard"]}
                                >
                                  <img alt="" src={addIconOptions} />
                                  <div className={styles["addOptionsTxt"]}>
                                    Add option
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        <div className={styles["feedback-divider"]}></div>
                        <div className={styles["feedback-card"]}>
                          <div className={styles["model-add-questions"]}>
                            Feedback
                          </div>
                          <EditorWrapper
                            showPreview={true}
                            readOnly={false}
                            content={content}
                            setContent={setContent}
                            isEditorLoading={isEditorLoading}
                            setEditorLoading={setEditorLoading}
                          />
                        </div>
                      </div>
                      <div className={styles["modal-grading-section"]}>
                        <div className={styles["model-questions-type"]}>
                          Grading Type
                        </div>
                        <div className={styles["grading-type"]}>
                          {gradingOptions.map((op, key) => {
                            return (
                              <FormControlLabel
                                classes={{
                                  root: styles[""],
                                }}
                                key={`${key}-${op}`}
                                control={
                                  <Radio
                                    classes={{
                                      root: styles["grading-options"],
                                    }}
                                    checked={
                                      selectedGradingOption === op.key
                                        ? true
                                        : false
                                    }
                                    value={op.key}
                                    onChange={(e) => {
                                      setSelectedGradingOption(e.target.value);
                                      e.target.value === "FIXED_GRADING"
                                        ? setGradingSection(true)
                                        : setGradingSection(false);
                                    }}
                                  />
                                }
                                label={op.value}
                              />
                            );
                          })}
                        </div>
                        <div className={styles["weightage-input-wrapper"]}>
                          <div className={styles["weightage-input"]}>
                            <label
                              htmlFor="quantity"
                              className={styles["weightage-label"]}
                            >
                              Enter {gradingSection ? "Fixed" : "Pass/Fail"}{" "}
                              Weightage
                            </label>
                            <input
                              type={"number"}
                              name={"quantity"}
                              min={1}
                              max={100}
                              onChange={handleCreditChange}
                              value={credits}
                            />
                          </div>
                          <div className={styles["weightage-input"]}>
                            <label
                              htmlFor="weightage-label"
                              className={styles["weightage-label"]}
                            >
                              Credit Left
                            </label>
                            <div
                              className={styles["credit-left"]}
                              id="credit-left"
                            >
                              {creditsLeft}
                            </div>
                          </div>
                        </div>
                        <div className={styles["separator"]} />
                        <div className={styles["resubmission-section"]}>
                          <div className={styles["label-switch-wrapper"]}>
                            <div className={styles["headers"]}>
                              Allow Resubmissions
                            </div>
                            <Switch
                              checked={allowResubmission}
                              onChange={(event) => {
                                setAllowResubmission(event.target.checked);
                                setResubmission(
                                  event.target.checked === false
                                    ? 1
                                    : resubmission
                                );
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                          {allowResubmission && (
                            <div
                              className={styles["resubmission-limit-checker"]}
                            >
                              {attemptsOption.map((op, key) => {
                                return (
                                  <FormControlLabel
                                    key={`${key}-${op}`}
                                    control={
                                      <Radio
                                        classes={{
                                          root: styles["attemps-options"],
                                        }}
                                        checked={
                                          selectedAttemptsOption === op.key
                                            ? true
                                            : false
                                        }
                                        value={op.key}
                                        onChange={(e) => {
                                          setSelectedAttemptsOption(
                                            e.target.value
                                          );
                                          if (e.target.value === "limited") {
                                            setResubmission(1);
                                            setLimitedAttempts(true);
                                          } else {
                                            setLimitedAttempts(false);
                                          }
                                        }}
                                      />
                                    }
                                    label={
                                      op.key === "limited"
                                        ? op.label + "(Max 10)"
                                        : op.label
                                    }
                                  />
                                );
                              })}
                            </div>
                          )}
                          {allowResubmission && limitedAttempts && (
                            <input
                              type={"number"}
                              name={"quantity"}
                              min={1}
                              max={10}
                              disabled={!allowResubmission}
                              value={resubmission}
                              onChange={(event) => {
                                if (parseInt(event.target.value) < 0) {
                                  setResubmission(1);
                                } else if (parseInt(event.target.value) > 10) {
                                  setResubmission(10);
                                } else {
                                  setResubmission(event.target.value);
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className={styles["button-section"]}>
                          <OutlinedButton
                            variant="contained"
                            className={styles["cancel-btn"]}
                            bgcolor={
                              props.themeInfo[0]?.primarycolor
                                ? props.themeInfo[0]?.primarycolor
                                : "#0e1555"
                            }
                            fontFamily={
                              props.themeInfo[0]?.fontfamily
                                ? props.themeInfo[0]?.fontfamily
                                : "Nunito"
                            }
                            onClick={() => {
                              clearPopUpData();
                            }}
                          >
                            Cancel
                          </OutlinedButton>
                          <FilledButton
                            variant="contained"
                            bgcolor={
                              props.themeInfo[0]?.primarycolor
                                ? props.themeInfo[0]?.primarycolor
                                : "#0e1555"
                            }
                            fontFamily={
                              props.themeInfo[0]?.fontfamily
                                ? props.themeInfo[0]?.fontfamily
                                : "Nunito"
                            }
                            className={styles["create-btn"]}
                            onClick={() => {
                              updateQuestionnaireData(section);
                              updateToggle(false);
                            }}
                            disabled={isSaveButtonDisabled()}
                          >
                            Update
                          </FilledButton>
                        </div>
                      </div>
                    </div>
                  </GenericModal>
                </div>
                <>
                  <div
                    className={`${styles["questions-container"]} ${styles["creation-mode"]}`}
                  >
                    {Object.values(currQues)?.map((question, qIdx) => {
                      return (
                        <div
                          className={styles["question"]}
                          key={`question-${qIdx}`}
                          draggable
                          onDragStart={(e) => (dragItem2.current = qIdx)}
                          onDragEnter={(e) => (dragOverItem2.current = qIdx)}
                          onDragEnd={(e) => handleDrag2()}
                        >
                          <div className={styles["header-container"]}>
                            <div className={styles["question-header"]}>
                              <div className={styles["actual-question"]}>
                                <div className={styles["question-index"]}>
                                  Q{qIdx + 1}.
                                </div>
                                <EditorWrapper
                                  root="question-editor-wrapper"
                                  childRoot="question-wrapper-admin"
                                  showPreview={false}
                                  readOnly={true}
                                  content={question.question}
                                  isEditorLoading={isEditorLoadingQuestion}
                                  setEditorLoading={setEditorLoadingQuestion}
                                />
                              </div>
                              <div className={styles["question-type-card"]}>
                                <div className={styles["question-type"]}>
                                  <div className={styles["question-type-txt"]}>
                                    {question.questionType}
                                  </div>
                                </div>
                                <div className={styles["question-action-icon"]}>
                                  <StyledQuestionModeEditIcon
                                    width={"1rem"}
                                    height={"1rem"}
                                    primarycolor={"#9FA1BB"}
                                    onClick={(e) => {
                                      onEditUpdateUIState(e, question);
                                      setCurrSelectedSection(question._id);
                                    }}
                                  />
                                  <StyledDeleteIcon
                                    width={"1rem"}
                                    height={"1rem"}
                                    primarycolor={"#9FA1BB"}
                                    className={styles["question-mode-icon"]}
                                    onClick={() => {
                                      let newQues = { ...currQues };
                                      delete newQues[question._id];
                                      setCurrQues(newQues);
                                      setDeletSingleQuestion(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              </div>
            </>
          ) : (
            <div>Select a section to view</div>
          )}
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmFor="questions"
        setDraggedQuestionaireSections={setDraggedQuestionaireSections}
      />
    </div>
  );
};

const mapStateToProps = ({ app, modules, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    questionnaires: modules.questionnaires.questionnaires,
    themeInfo: user.themeInfo,
    customization: user.customization,
    sectionCreditsLeft: modules.questionnaires.creditLeft,
    moduleOptions: modules.moduleOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleQuestionContent: (moduleId) =>
      dispatch(getModuleQuestionContent(moduleId)),
    addModuleQuestionSection: (moduleId, title) =>
      dispatch(addModuleQuestionSection(moduleId, title)),
    updateModuleQuestionSection: (
      _id,
      title,
      questions,
      weightage,
      questionnaireTotalWeightage
    ) =>
      dispatch(
        updateModuleQuestionSection(
          _id,
          title,
          questions,
          weightage,
          questionnaireTotalWeightage
        )
      ),
    deletequestionaire: (moduleId, questionaireId) =>
      dispatch(deletequestionaire(moduleId, questionaireId)),
    updateQuestionaireSections: (moduleId, questionnaireId, title) =>
      dispatch(updateQuestionaireSections(moduleId, questionnaireId, title)),
    updateModuleQuestionsOrder: (moduleId, moduleData) =>
      dispatch(updateModuleQuestionsOrder(moduleId, moduleData)),
    getModuleOptions: (moduleId) => dispatch(getModuleOptions(moduleId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModuleQuestionCreation);
