import { useState } from "react";
import { connect } from "react-redux";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

import {
  setUserProfessionalData,
  editUserProfileData,
} from "../../../../../Store/Actions/actions.user";

import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";

function ModalProfessionalDetails(props) {
  const {
    eleindex,
    mode,
    userId,
    editProfileData,
    professionalDetails,
    setProfessionalData,
    handleClose,
    userUid,
  } = props;
  const [position, setPosition] = useState(
    (mode === "Edit" && professionalDetails[eleindex]?.position) || ""
  );
  const [bio, setBio] = useState(
    (mode === "Edit" && professionalDetails[eleindex]?.bio) || ""
  );
  const [startDate, setStartDate] = useState(
    (mode === "Edit" && professionalDetails[eleindex]?.startDate) || ""
  );
  const [endDate, setEndDate] = useState(
    (mode === "Edit" && professionalDetails[eleindex]?.endDate) || ""
  );
  const [currentProfession, setCurrentProfession] = useState(
    (mode === "Edit" && professionalDetails[eleindex]?.currentProfession) ||
      false
  );
  const handleSubmit = () => {
    const data = {
      position,
      bio,
      startDate,
      endDate,
      currentProfession,
    };
    mode === "Add"
      ? setProfessionalData(data, userId, userUid)
      : editProfileData(data, userId, "professional", eleindex, userUid);
    handleClose();
  };
  function isValidDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return !isNaN(date.getTime()) && date <= today;
  }
  function isStartDateMoreThanEndDate(startDate, endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    return date1 > date2;
  }
  const isSubmitButtonDisabled = () => {
    if (currentProfession) {
      return position === "" || !isValidDate(startDate);
    } else {
      return (
        position === "" ||
        !isValidDate(endDate) ||
        isStartDateMoreThanEndDate(startDate, endDate) ||
        !isValidDate(startDate)
      );
    }
  };
  return (
    <Box className={styles["modal-professional-box"]}>
      <Box className={styles["modal-professional-heading"]}>
        <Box> {mode} Work Experience</Box>
        <div className={styles["modal-heading-close"]} onClick={handleClose}>
          <CloseIcon />
        </div>
      </Box>

      <Box>
        <TextField
          label="Position"
          placeholder="Position"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-professional-textbox"]}
        />
      </Box>

      <Box>
        <TextField
          label="Bio"
          placeholder="Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          className={`${styles["modal-professional-textbox"]} ${styles["modal-professional-bio"]}`}
        />
      </Box>

      <Box className={styles["modal-professional-fistLine"]}>
        <TextField
          label="Start Date"
          placeholder="DD/MM/YY"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={!isValidDate(startDate)}
          className={styles["modal-professional-content"]}
        />
        {!currentProfession && (
          <TextField
            label="End Date"
            placeholder="DD/MM/YY"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={
              !isValidDate(endDate) ||
              isStartDateMoreThanEndDate(startDate, endDate)
            }
            className={styles["modal-professional-content"]}
          />
        )}

        <Box className={styles["modal-professional-text"]}>
          <Checkbox
            className={styles["modal-professional-checkbox"]}
            value={currentProfession}
            checked={currentProfession}
            onChange={() => setCurrentProfession(!currentProfession)}
          />{" "}
          Currently At this Position
        </Box>
      </Box>

      <Box className={styles["modal-professional-add-box"]}>
        <Button
          className={styles["modal-professional-add"]}
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitButtonDisabled()}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  userId: user.userDetails.id,
  professionalDetails: user.userProfile?.professionalDetails,
  userUid: user.userUid,
});

const mapDispatchToProps = (dispatch) => ({
  setProfessionalData: (data, userId, userUid) =>
    dispatch(setUserProfessionalData(data, userId, userUid)),
  editProfileData: (data, userId, mode, eleindex, userUid) =>
    dispatch(editUserProfileData(data, userId, mode, eleindex, userUid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalProfessionalDetails);
