import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { format } from "date-fns";
import CohortBackNav from "../Components/CohortBackNav";
import EditorWrapper from "../Components/EditorWrapper";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

import { getLiveSessionsForStudents } from "../Store/Actions/actions.cohort";
import styles from "../Stylesheets/Modules/liveSession.module.scss";
import { logPageView } from "../Utils/analytics";

export const LiveSessionStudentView = (props) => {
  const {
    appLoadingStatus,
    liveSessions,
    userInfo,
    getLiveSessionsForStudents,
  } = props;
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const { cohortId, moduleId } = useParams();
  const [studentId] = useState(userInfo.id);

  useEffect(() => {
    fetchLiveSessionData(studentId, cohortId, moduleId);
  }, []);

  const fetchLiveSessionData = async (studentId, cohortId, moduleId) => {
    await getLiveSessionsForStudents(studentId, cohortId, moduleId);
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Live Sessions Overview Page");
  }, [location]);

  const dateSlotFilter = (dateSlot) => {
    return dateSlot.filter((item) => {
      return item.moduleId === moduleId;
    });
  };

  if (appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <div className={styles["livesession-content-main"]}>
      <CohortBackNav
        link={`/cohort/${cohortId}/curriculum/${moduleId}?cohort=${searchParams.get(
          "cohort"
        )}&module=${searchParams.get("module")}`}
        text={`Back to ${searchParams.get("module")} Overview`}
      />
      <div className={styles["live-sessions-list-wrapper"]}>
        {liveSessions?.map((item, key) => {
          return (
            <div
              className={styles["wrapper"]}
              key={key}
              onClick={() =>
                history(
                  `/cohort/${cohortId}/curriculum/${moduleId}/liveSessionDetails/${
                    item._id
                  }?cohort=${searchParams.get(
                    "cohort"
                  )}&module=${searchParams.get("module")}&liveSession=${
                    item.topic
                  }`
                )
              }
            >
              <div
                className={styles["card-number-indicator"]}
                style={{ background: props.themeInfo[0]?.primarycolor }}
              >
                {item.topic}
              </div>
              <div className={styles["cards-wrapper"]} key={key}>
                <div className={styles["image-spkname-wrapper"]}>
                  <img src={item.photoUrl} alt="usericon" />
                  <div className={styles["spkname-wrapper"]}>
                    <div className={styles["spkname"]}>{item.speakerName}</div>
                    <div className={styles["time-range"]}>
                      {dateSlotFilter(item.dateSlot)?.[0]?.start &&
                        format(
                          new Date(dateSlotFilter(item.dateSlot)[0].start),
                          "MMM do, yyyy"
                        )}{" "}
                      -{" "}
                      {dateSlotFilter(item.dateSlot)?.[0]?.end &&
                        format(
                          new Date(dateSlotFilter(item.dateSlot)[0].end),
                          "MMM do, yyyy"
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles["event-overview"]}>
                  <EditorWrapper
                    childRoot="event-details-content-wrapper"
                    readOnly={true}
                    content={item.eventOverview}
                  />
                </div>
                {/* <div className={styles["separator"]} />
                <div className={styles["event-link-wrapper"]}>
                  <InsertLinkIcon />
                  <div className={styles["label"]}>Event Link : </div>

                  <div
                    className={styles["value"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open("https://" + item.googleMeetLink, "_blank");
                    }}
                  >
                    {item.googleMeetLink}
                  </div>
                </div>
                <div className={styles["recurring-wrapper"]}></div>
                <div className={styles["slots-wrapper"]}></div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    userInfo: user.userInfo,
    liveSessions: cohort.liveSessionDetails,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLiveSessionsForStudents: (studentId, cohortId, moduleId) =>
      dispatch(getLiveSessionsForStudents(studentId, cohortId, moduleId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveSessionStudentView);
