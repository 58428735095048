import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MuiPhoneNumber from "material-ui-phone-number";
import { setUserPersonalData } from "../../../../../Store/Actions/actions.user";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import SelfLinkModal from "./SelfLinkModal";
function ModalPersonalDetails(props) {
  const { userInfo, setPersonalDetails, handleClose, profileDetails } = props;
  const fullName = userInfo.firstName.concat(" ", userInfo.lastName);

  const [name, setName] = useState(fullName || "");
  const [mobile, setMobile] = useState(userInfo.mobileNo);
  const [country, setCountry] = useState(profileDetails?.country || "");
  const [city, setCity] = useState(profileDetails?.city || "");
  const [bio, setBio] = useState(profileDetails?.bio || "");
  const [selfLinks, setSelfLinks] = useState(profileDetails?.socialLinks || []);
  const [checked, setChecked] = useState(profileDetails?.openForWork || false);
  const [selfLinkModal, setSelfLinkModal] = useState(false);
  const [typeOfLink, setTypeOfLink] = useState("");
  const [url, setUrl] = useState("");

  const handleSubmit = () => {
    const data = {
      name,
      mobileNo: mobile,
      country,
      city,
      bio,
      openForWork: checked,
      socialLinks: [...selfLinks],
    };
    setPersonalDetails(data, userInfo.id, props.userUid);
    handleClose();
  };
  useEffect(() => {
    if (selfLinks?.length === 0)
      setSelfLinks([
        {
          typeOfLink: "facebook",
          url: "",
        },
        {
          typeOfLink: "linkedin",
          url: "",
        },
        {
          typeOfLink: "twitter",
          url: "",
        },
        {
          typeOfLink: "github",
          url: "",
        },
      ]);
  }, []);
  const handleLinkChange = () => {
    let singleLink = {
      typeOfLink: typeOfLink,
      url: url,
    };
    setSelfLinks([...selfLinks, singleLink]);
    setSelfLinkModal(false);
  };
  const handleChange = (index, newValue) => {
    const updatedSelfLinks = [...selfLinks];
    updatedSelfLinks[index].url = newValue;
    setSelfLinks(updatedSelfLinks);
  };
  return (
    <Box className={styles["modal-personal-box"]}>
      <Box className={styles["modal-personal-heading"]}>
        <Box>Edit Profile</Box>
        <div className={styles["modal-heading-close"]} onClick={handleClose}>
          <CloseIcon />
        </div>
      </Box>

      <Box className={styles["modal-personal-fistLine"]}>
        <TextField
          label="Name"
          placeholder="Name"
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-personal-content"]}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <MuiPhoneNumber
          label="Mobile No"
          data-cy="user-phone"
          className={styles["modal-personal-content"]}
          defaultCountry={"us"}
          variant={"outlined"}
          value={mobile}
          fullWidth
          onChange={(val) => setMobile(val)}
        />
        <TextField
          label="Country"
          placeholder="Country"
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-personal-content"]}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <TextField
          label="City of Residence"
          placeholder="City of Residence"
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-personal-content"]}
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </Box>

      <Box>
        <TextField
          label="Bio"
          placeholder="Bio"
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-personal-bio"]}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </Box>

      <Box className={styles["links-wrapper"]}>
        <div
          className={styles["add-links"]}
          onClick={() => setSelfLinkModal(true)}
        >
          <AddCircleIcon className={styles["modal-links-add"]} />
          Add Self Link
        </div>
        {selfLinks?.map((link, index) => (
          <TextField
            label={link?.typeOfLink}
            placeholder={link?.typeOfLink}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {link?.typeOfLink === "github" ? (
                    <GitHubIcon />
                  ) : link?.typeOfLink === "twitter" ? (
                    <TwitterIcon />
                  ) : link?.typeOfLink === "facebook" ? (
                    <FacebookIcon />
                  ) : link?.typeOfLink === "linkedin" ? (
                    <LinkedInIcon />
                  ) : (
                    ""
                  )}
                </InputAdornment>
              ),
            }}
            className={styles["modal-personal-content"]}
            key={index}
            value={link?.url}
            onChange={(e) => handleChange(index, e.target.value)}
          />
        ))}
      </Box>

      <Box className={styles["modal-personal-text"]}>
        Are you open for work?
      </Box>
      <Box className={styles["modal-personal-checkbox-container"]}>
        <div>
          <Checkbox
            className={styles["modal-personal-checkbox"]}
            value={checked}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />{" "}
        </div>
        <div>
          <span>Yes I’m currently seeking employment</span>
        </div>
      </Box>

      <Box className={styles["modal-personal-save-box"]}>
        <Button
          className={styles["modal-personal-save"]}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Box>
      <SelfLinkModal
        open={selfLinkModal}
        setOpen={setSelfLinkModal}
        setTypeOfLink={setTypeOfLink}
        setUrl={setUrl}
        handleLinkChange={handleLinkChange}
      />
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  userInfo: user.userDetails,
  profileDetails: user.learnerProfileDetails.profileDetails,
  userUid: user.userUid,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalDetails: (data, userId, userUid) =>
      dispatch(setUserPersonalData(data, userId, userUid)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPersonalDetails);
