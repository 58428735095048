import { useState } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";

import StudentFormHeader from "./StudentFormHeader";
import Checkbox from "@mui/material/Checkbox";
import ProfileModal from "./modals/ProfileModal";

import { delUserProfileData } from "../../../../Store/Actions/actions.user";
import EditIcon from "../../../../Assets/Images/editIcon.jpg";
import DeleteIcon from "../../../../Assets/Images/delete.jpg";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import RemoveIcon from '@mui/icons-material/Remove';
function StudentProfessionalDetails({
  professionalDetails,
  delUserProfile,
  userId,
  themeInfo,
  preview,
  userUid
}) {
  const [open, setOpen] = useState(false);
  const [eleindex, setEleIndex] = useState(0);
  const [mode, setMode] = useState("Add");

  const handleClose = () => setOpen(false);
  const handleAddOnClick = (mode) => {
    setMode(mode);
    setOpen(true);
  };
  const handleEditOnClick = (index, mode) => {
    setEleIndex(index);
    setMode(mode);
    setOpen(true);
  };

  const handleDelete = (index) => {
    delUserProfile(index, userId, "professional", userUid);
  };

  return (
    <Box className={styles["personal-details-box"]}>
      <ProfileModal
        eleindex={eleindex}
        mode={mode}
        open={open}
        handleClose={handleClose}
        modalName="EditProfessionalDetails"
      />

      <StudentFormHeader
        headerName="Professional Details"
        buttonName="Add"
        handleOnClick={() => handleAddOnClick("Add")}
        themeInfo={themeInfo}
        preview={preview}
      />

      {professionalDetails?.length > 0 &&
        professionalDetails.map((ele, index) => (
          <Box className={styles["profession-box"]} key={index}>
            <Box className={styles["profession-position"]}>
              <Box>{ele.position}</Box>
              {!preview && <Box className={styles["profession-position-icons"]}>
                <Box
                  className={styles["position-icon"]}
                  onClick={() => handleEditOnClick(index, "Edit")}
                >
                  <img src={EditIcon} alt="edit" />{" "}
                </Box>
                <Box
                  className={styles["position-icon"]}
                  onClick={() => handleDelete(ele._id)}
                >
                  <img src={DeleteIcon} alt="delete" />{" "}
                </Box>
              </Box>}
            </Box>
            <Box
              className={styles["profession-date"]}
            >{`${ele.startDate}`}
              <RemoveIcon />
              {`${ele.endDate}`}</Box>
            <Box className={styles["profession-bio"]}> {ele.bio} </Box>
            {ele.currentProfession && (
              <Box className={styles["profession-current"]}>
                <Checkbox
                  className={styles["profile-checkbox"]}
                  checked={ele.currentProfession}
                />
                Currently At this Possition
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  userId: user.userDetails.id,
  professionalDetails: user?.learnerProfileDetails?.profileDetails?.professionalDetails,
  themeInfo: user.themeInfo,
  userUid: user.userUid
});

const mapDispatchToProps = (dispatch) => ({
  delUserProfile: (index, userId, sectionName, userUid) =>
    dispatch(delUserProfileData(index, userId, sectionName, userUid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentProfessionalDetails);
