import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Button from "@mui/material/Button";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import TitleDescriptionComp from "../../../Reusable/TitleDescriptionComp";
import AddIcon from "@mui/icons-material/Add";
import styles from "../../../Stylesheets/Modules/Admin/Modules/createModule.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import ConfirmDialog from "../../../Reusable/Modals/ConfirmDialog";
import Tooltip from "@mui/material/Tooltip";
import {
  createNewModule,
  getAllModules,
  updataModuleStatus,
  updateModuleDetails,
  deleteModule,
  updateModulesOrder,
  getModuleOptions,
  getModuleSkillsList,
} from "../../../Store/Actions/actions.modules";
import { Autocomplete, Switch, TextField } from "@mui/material";
import {
  OutlinedButton,
  FilledButton,
} from "../../../Components/StyledComponents/Button.style";
import {
  StyledAddIcon,
  StyledArrowBackIcon,
  StyledDeleteIcon,
  StyledDragHandleIcon,
  StyledEditIcon,
} from "../../../Components/StyledComponents/Icons.style";
import ModuleCreationFormComp from "../../../Reusable/ModuleCreationFormComp";
import { logPageView } from "../../../Utils/analytics";

const CreateModule = (props) => {
  const history = useNavigate();
  const { cohortId } = useParams();
  const [searchParams] = useSearchParams();
  const [toggle, updateToggle] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedModule, setModule] = useState();
  const [modules, setModules] = useState([]);
  const [options, setModuleOptions] = useState([]);
  const [dragged, setdragged] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [moduleSkills, setModuleSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [search, setSearch] = useState("");
  const {
    createNewModule,
    getAllModules,
    allModules,
    updateModuleDetails,
    deleteModule,
    updateModulesOrder,
    getModuleOptions,
    moduleOptions,
    customization,
    updataModuleStatus,
  } = props;
  const moduleName = props.customization.module_label;
  React.useEffect(() => {
    getAllModulesData(cohortId);
  }, []);

  const getAllModulesData = async (cohortId) => {
    await getAllModules(cohortId);
  };
  const getAllModuleOptions = async (moduleId) => {
    await getModuleOptions(moduleId);
  };
  //see if there are changes in allmodules and call the setmodules
  React.useEffect(() => {
    setModules(allModules);
  }, [allModules]);
  useEffect(() => {
    setModuleOptions(moduleOptions);
  }, [moduleOptions]);
  const handleModuleCreation = () => {
    if (editMode) {
      const moduleInfo = {
        name,
        description,
        cohortId,
      };
      updateModuleDetails(selectedModule._id, moduleInfo);
      setEditMode(false);
      updateToggle(false);

      setName("");
      setDescription("");
      return;
    }
    createNewModule({
      name,
      description,
      cohortId,
    });
    updateToggle(false);
  };

  useEffect(() => {
    setModuleSkills(props.moduleSkillsList);
  }, [props.moduleSkillsList]);

  useEffect(() => {
    if (search !== "") props.getModuleSkillsList(search);
  }, [search]);

  const isModuleBtnDisabled = () => {
    return name.length === 0 || description.length === 0;
  };
  const deleteHandler = (moduleId, cohortId) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteModule(moduleId, cohortId);
  };
  const scrollContainerRef = useRef(null);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const checkForUnsavedChanges = (callback) => {
    if (dragged) {
      setConfirmDialog({
        isOpen: true,
        title:
          "You have unsaved changes. Do you want to proceed without saving?",
        onConfirm: () => {
          setConfirmDialog({ ...confirmDialog, isOpen: false });
          callback();
        },
      });
    } else {
      callback();
    }
  };

  const handleScrollWhileDragging = useCallback((e) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const containerTop = containerRect.top;
    const containerBottom = containerRect.bottom;

    const threshold = 100; // pixels from the edge to start scrolling
    const scrollSpeed = 20; // speed of scrolling

    if (e.clientY - containerTop < threshold) {
      // Scroll up
      container.scrollTop -= scrollSpeed;
    } else if (containerBottom - e.clientY < threshold) {
      // Scroll down
      container.scrollTop += scrollSpeed;
    }
  }, []);

  const handleDragStart = (e, idx) => {
    dragItem.current = idx;
    document.addEventListener("mousemove", handleScrollWhileDragging);
  };

  const handleDragEnter = (idx) => {
    dragOverItem.current = idx; // Capture the index of the drag-over item
  };

  const handleDragEnd = (e) => {
    document.removeEventListener("mousemove", handleScrollWhileDragging);
    if (dragItem.current !== null && dragOverItem.current !== null) {
      handleDrag();
    }
    dragItem.current = null;
    dragOverItem.current = null;
  };

  const handleDrag = () => {
    const newModules = [...modules];
    const draggedItem = newModules.splice(dragItem.current, 1)[0];
    newModules.splice(dragOverItem.current, 0, draggedItem);

    // Function to compare the order of two arrays
    const isOrderChanged = (array1, array2) => {
      return array1.some((item, index) => item._id !== array2[index]._id);
    };
    setModules(newModules);

    // Set dragged state based on whether the order has changed
    setdragged(isOrderChanged(newModules, allModules));

    // Reset the refs
    dragItem.current = null;
    dragOverItem.current = null;
  };

  const handleDragSave = (_modules) => {
    let moduleReordered = _modules.map((module) => ({
      name: module.name,
      description: module.description,
      _id: module._id,
      published: module.published,
    }));
    updateModulesOrder(moduleReordered, cohortId);
  };
  const changeOrder = async () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    handleDragSave(_modules);
  };
  const getCreditsUsed = (moduleId) => {
    var totalUsed = 0;
    modules.forEach((item) => {
      if (item._id === moduleId) {
        item.moduleOptions.forEach((weight) => {
          totalUsed = totalUsed + weight.weightage;
        });
      }
    });
    return totalUsed;
  };
  const [page, setPage] = useState("page1");
  const isContentEnabled = props.config.content;

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Create Module Page");
  }, [location]);

  const getNavigationalSectionId = (key, item) => {
    if (key === "courseContent") {
      return item.courseContent?.sections[0]?._id;
    } else return item.questionnaires[0]?._id;
  };
  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div
          className={styles["create-module-header"]}
          style={{ color: props.themeInfo[0]?.secondarycolor }}
        >
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() =>
              checkForUnsavedChanges(() => {
                history(
                  `/admin/cohortMenu/${cohortId}?cohort=${searchParams.get(
                    "cohort"
                  )}`
                );
              })
            }
          />
          {`${searchParams.get("cohort") || "Cohort"} Overview`}
        </div>
        {isContentEnabled === false && (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="info">
              Adding Content is a premium feature, contact support@Gritly.us to
              Enable!
            </Alert>
          </Stack>
        )}
        <div
          className={styles["module-wrap-container"]}
          ref={scrollContainerRef}
        >
          {modules?.map((module, idx) => {
            return (
              <Card
                key={idx}
                classes={{ root: styles["modules-tiles-wrapper"] }}
                style={{ color: props.themeInfo[0]?.primarycolor }}
                draggable
                onDragStart={(e) => handleDragStart(e, idx)}
                onDragEnter={() => handleDragEnter(idx)}
                onDragEnd={handleDragEnd}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className={styles["module-options-container"]}>
                  <StyledDeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      checkForUnsavedChanges(() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure you want to delete",
                          onConfirm: () => {
                            deleteHandler(module._id, cohortId);
                          },
                        });
                      });
                    }}
                  />
                  <StyledEditIcon
                    className={styles["edit-icon"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      checkForUnsavedChanges(() => {
                        setName(module.name);
                        setModule(module);
                        setDescription(module.description);
                        updateToggle(true);
                        setEditMode(true);
                        getAllModuleOptions(module._id);
                        setSelectedSkills(module.skills);
                      });
                    }}
                  />
                  <Tooltip
                    title={
                      module.published
                        ? `Unpublish ${moduleName}`
                        : `Publish ${moduleName}`
                    }
                  >
                    <Switch
                      classes={{
                        checked: styles["pubnub-switch-root"],
                      }}
                      checked={module.published || false}
                      onChange={(e) => {
                        checkForUnsavedChanges(() => {
                          updataModuleStatus(
                            module._id,
                            e.target.checked,
                            cohortId
                          );
                        });
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Tooltip>
                  <span key={idx}>
                    <StyledDragHandleIcon className={styles["drag-icon"]} />
                  </span>
                </div>
                <CardContent
                  classes={{ root: styles["modules-tiles-content"] }}
                >
                  <div className={styles["modules-content-wrapper"]}>
                    <span
                      className={styles["modules-header"]}
                      style={{ color: props.themeInfo[0]?.secondarycolor }}
                    >
                      {customization.module_label || "MODULE"} {idx + 1}:
                    </span>
                    <div className={styles["modules-title"]}>{module.name}</div>
                    <div className={styles["modules-description"]}>
                      {module.description}
                    </div>
                    <div className={styles["grading-section"]}>
                      <div className={styles["module-options-wrapper"]}>
                        {module.moduleOptions?.map((item, key) => {
                          if (item.active) {
                            return (
                              <div
                                className={styles["individual-option"]}
                                key={key}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  checkForUnsavedChanges(() => {
                                    if (item.key === "courseContent") {
                                      history(
                                        `/admin/cohort/${
                                          module.cohortId
                                        }/module/${
                                          module._id
                                        }/content?cohort=${searchParams.get(
                                          "cohort"
                                        )}&module=${
                                          module.name
                                        }&sectionId=${getNavigationalSectionId(
                                          item.key,
                                          module
                                        )}`
                                      );
                                    }
                                    if (item.key === "moduleQuestions") {
                                      history(
                                        `/admin/cohort/${
                                          module.cohortId
                                        }/module/${
                                          module._id
                                        }/questions?cohort=${searchParams.get(
                                          "cohort"
                                        )}&module=${
                                          module.name
                                        }&sectionId=${getNavigationalSectionId(
                                          item.key,
                                          module
                                        )}`
                                      );
                                    }
                                    if (item.key === "liveSessions") {
                                      history(
                                        `/admin/cohort/${module.cohortId}/module/${module._id}/liveSessions`
                                      );
                                    }
                                  });
                                }}
                              >
                                <span
                                  ref={(node) => {
                                    // Get the DOM node of the span
                                    if (node) {
                                      // Check if the text is truncated
                                      const isTruncated =
                                        node.offsetWidth < node.scrollWidth;
                                      if (isTruncated) {
                                        // Add the title attribute if the text is truncated
                                        node.setAttribute(
                                          "data-tooltip",
                                          item.key === "moduleQuestions"
                                            ? `${moduleName} Questions`
                                            : item.label
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {item.key === "moduleQuestions"
                                    ? `${moduleName} Questions`
                                    : item.label}
                                </span>
                              </div>
                            );
                          } else {
                            return <div key={key}></div>;
                          }
                        })}
                      </div>
                      <div className={styles["credits-used"]}>
                        Credits Used : {getCreditsUsed(module._id)}
                      </div>
                      {module?.skills && module?.skills.length > 0 && (
                        <div className={styles["skills-set"]}>
                          <span>Skills: &nbsp;</span>{" "}
                          {module?.skills
                            .map((skill) => skill.skill)
                            .join(", ")}
                        </div>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
          })}
        </div>

        <div className={styles["create-module-action"]}>
          {dragged && (
            <OutlinedButton
              className={styles["save-changes-button"]}
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure you want to change order?",
                  onConfirm: () => {
                    changeOrder();
                  },
                });
                setdragged(false);
              }}
              bgcolor={
                props.themeInfo[0]?.primarycolor
                  ? props.themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
            >
              Save Changes
            </OutlinedButton>
          )}

          <OutlinedButton
            disabled={!isContentEnabled}
            variant="contained"
            startIcon={<StyledAddIcon />}
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            onClick={() => {
              checkForUnsavedChanges(() => {
                setName("");
                setDescription("");
                updateToggle(!toggle);
                setEditMode(false);
              });
            }}
          >
            Create {customization.module_label || "Module"}
          </OutlinedButton>
        </div>
        <GenericModal
          open={toggle}
          togglePopUp={updateToggle}
          page={setPage}
          maxWidth={"lg"}
          setSelectedSkills={setSelectedSkills}
        >
          <div className={styles["modal-container-wrapper"]}>
            {editMode ? (
              <div className={styles["modal-header"]}>
                Update {customization.module_label || "Module"}
              </div>
            ) : (
              <div className={styles["modal-header"]}>
                Create {customization.module_label || "Module"}
              </div>
            )}

            {page === "page1" && (
              <>
                <div className={styles["content-body"]}>
                  <TitleDescriptionComp
                    hasTitle={true}
                    title={name}
                    setTitle={setName}
                    titleHelper="Enter title"
                    contentHelper="Enter description"
                    hasRichText={false}
                    content={description}
                    setContent={setDescription}
                  />
                  <div className={styles["module-skills-section"]}>
                    <Autocomplete
                      multiple
                      id="skills-selection"
                      options={moduleSkills.filter(
                        (option) =>
                          !selectedSkills.some(
                            (skill) => skill.skill === option.skill
                          )
                      )}
                      getOptionLabel={(option) => option.skill}
                      value={selectedSkills}
                      onChange={(event, newValue) => {
                        setSelectedSkills(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles["skills-auto-box"]}
                          placeholder="Select a skill"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={styles["next-module-action"]}>
                  <Button
                    onClick={() => {
                      setPage("page2");
                    }}
                    disabled={isModuleBtnDisabled()}
                    classes={{
                      root: `${styles["next-btn"]} ${
                        isModuleBtnDisabled() ? styles["disabled"] : ""
                      }`,
                    }}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {page === "page2" && (
              <>
                <Button
                  onClick={(e) => {
                    setPage("page1");
                  }}
                >
                  <StyledArrowBackIcon style={{ color: "black" }} />
                </Button>
                <ModuleCreationFormComp
                  moduleId={selectedModule}
                  moduleName={name}
                  cohortId={cohortId}
                  description={description}
                  togglePopUp={updateToggle}
                  editMode={editMode}
                  page={setPage}
                  selectedSkills={selectedSkills}
                  setSelectedSkills={setSelectedSkills}
                />
              </>
            )}
          </div>
        </GenericModal>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmFor="module"
      />
    </>
  );
};

const mapStateToProps = ({ app, modules, user, config }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    allModules: modules.modules,
    themeInfo: user.themeInfo,
    moduleOptions: modules.moduleOptions,
    customization: user.customization,
    config: config.configData,
    moduleSkillsList: modules.moduleSkillsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewModule: (modules) => dispatch(createNewModule(modules)),
    updateModuleDetails: (moduleId, moduleData) =>
      dispatch(updateModuleDetails(moduleId, moduleData)),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId)),
    updataModuleStatus: (moduleId, status, cohortId) =>
      dispatch(updataModuleStatus(moduleId, status, cohortId)),
    deleteModule: (moduleId, cohortId) =>
      dispatch(deleteModule(moduleId, cohortId)),
    updateModulesOrder: (moduleData, cohortId) =>
      dispatch(updateModulesOrder(moduleData, cohortId)),
    getModuleOptions: (moduleId) => dispatch(getModuleOptions(moduleId)),
    getModuleSkillsList: (search) => dispatch(getModuleSkillsList(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateModule);
