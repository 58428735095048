import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { getModuleLiveSessions } from "../../../Store/Actions/actions.modules";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import Container from "@mui/material/Container";
import styles from "../../../Stylesheets/Modules/Admin/LiveSession/ModuleLiveSession.module.scss";
import {
  StyledAddIcon,
  StyledArrowBackIcon,
  StyledDeleteIcon,
  StyledEditIcon,
} from "../../../Components/StyledComponents/Icons.style";
import ConfirmDialog from "../../../Reusable/Modals/ConfirmDialog";
import { OutlinedButton } from "../../../Components/StyledComponents/Button.style";
import EditorWrapper from "../../../Components/EditorWrapper";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { format } from "date-fns";
import LiveSessionCreation from "./LiveSessionCreation";
import { logPageView } from "../../../Utils/analytics";
import { getAllModules } from "../../../Store/Actions/actions.modules";
import { deleteLiveSession } from "../../../Store/Actions/actions.cohort";
import CloseIcon from "@mui/icons-material/Close";

export const ModuleLiveSession = (props) => {
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const { cohortId, moduleId } = useParams();
  const [toggle, updateToggle] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [liveSession, setLiveSession] = useState([]);
  const { themeInfo, customization, liveSessions } = props;
  const [liveSessionsList, setLiveSessionsList] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });

  useEffect(() => {
    fetchLiveSessionData(cohortId, moduleId);
    props.getAllModules(cohortId)
  }, []);
  useEffect(() => {
    if (liveSessions) {
      const filtered =
        liveSessions.dateSlot &&
        liveSessions.dateSlot.filter((item) => {
          return item.moduleId === moduleId;
        });
      liveSessions.dateSlot = filtered !== undefined ? filtered : [];
      setLiveSessionsList(liveSessions);
    }
  }, [liveSessions, moduleId]);

  const fetchLiveSessionData = async (cohortId, moduleId) => {
    await props.getModuleLiveSessions(cohortId, moduleId);
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Module Live Session Page");
  }, [location]);

  const dateSlotFilter = (dateSlot) => {
    return dateSlot.filter((item) => {
      return item.moduleId === moduleId;
    });
  };

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  const deleteHandler = (livesessionId, cohortId) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    props.deleteLiveSession(livesessionId, cohortId, moduleId);
  };
  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div
          className={styles["create-session-header"]}
          style={{ color: themeInfo[0]?.secondarycolor }}
        >
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() => history(-1)}
          />
          Live Session
        </div>
        <div className={styles["create-live-session-action"]}>
          <OutlinedButton
            variant="contained"
            startIcon={<StyledAddIcon />}
            bgcolor={
              themeInfo[0]?.primarycolor
                ? themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              themeInfo[0]?.fontfamily ? themeInfo[0]?.fontfamily : "Nunito"
            }
            onClick={() => {
              updateToggle(!toggle);
              setEditMode(false)
            }}
          >
            Add Live Session
          </OutlinedButton>
        </div>
        <div className={styles["live-sessions-list-wrapper"]}>
          {liveSessionsList?.map((item, key) => {
            return (
              <div
                className={styles["wrapper"]}
                key={key}
                onClick={() =>
                  history(
                    `/admin/liveSession/${item._id}/cohort/${cohortId}?moduleId=${moduleId}`
                  )
                }
              >
                <div className={styles["header-wrapper"]}>
                  <div
                    className={styles["card-number-indicator"]}
                    style={{ background: props.themeInfo[0]?.primarycolor }}
                  >
                    {item.topic}
                  </div>
                  <div
                    className={styles["edit-delete-section"]}
                    style={{ color: props.themeInfo[0]?.primarycolor }}
                  >
                    <StyledEditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        updateToggle(!toggle);
                        setEditMode(true);
                        setLiveSession(item);
                      }}
                    />
                    <StyledDeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure you want to delete ?",
                          onConfirm: () => {
                            deleteHandler(item._id, item.cohortId);
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles["cards-wrapper"]} key={key}>
                  <div className={styles["image-spkname-wrapper"]}>
                    <img src={item.photoUrl} alt="usericon" />
                    <div className={styles["spkname-wrapper"]}>
                      <div className={styles["spkname"]}>
                        {item.speakerName}
                      </div>
                      <div className={styles["time-range"]}>
                        {dateSlotFilter(item.dateSlot)?.[0]?.start &&
                          format(
                            new Date(dateSlotFilter(item.dateSlot)[0].start),
                            "MMM do, yyyy"
                          )}{" "}
                        -{" "}
                        {dateSlotFilter(item.dateSlot)?.[0]?.end &&
                          format(
                            new Date(dateSlotFilter(item.dateSlot)[0].end),
                            "MMM do, yyyy"
                          )}
                      </div>
                    </div>
                  </div>
                  <div className={styles["event-overview"]}>
                    <EditorWrapper
                      childRoot="event-details-content-wrapper"
                      readOnly={true}
                      content={item.eventOverview}
                    />
                  </div>
                  <div className={styles["separator"]} />
                  <div className={styles["event-link-wrapper"]}>
                    <InsertLinkIcon />
                    <div className={styles["label"]}>Event Link : </div>

                    <div
                      className={styles["value"]}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.googleMeetLink, "_blank");
                      }}
                    >
                      {item.googleMeetLink}
                    </div>
                  </div>
                  <div className={styles["recurring-wrapper"]}></div>
                  <div className={styles["slots-wrapper"]}></div>
                </div>
              </div>
            );
          })}
        </div>

        <GenericModal open={toggle} togglePopUp={updateToggle} maxWidth={"lg"}>
          <div className={styles["modal-container-wrapper"]}>
            {editMode ? (
              <div className={styles["modal-header"]}>Update Live Session<div><CloseIcon onClick={() => updateToggle(false)} sx={{ cursor: "pointer" }} /></div></div>
            ) : (
              <div className={styles["modal-header"]}>Create Live Session<div><CloseIcon onClick={() => updateToggle(false)} sx={{ cursor: "pointer" }} /></div></div>
            )}
            <LiveSessionCreation togglePopUp={updateToggle} editMode={editMode} liveSession={liveSession} moduleId={moduleId} />
          </div>
        </GenericModal>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

const mapStateToProps = ({ app, user, cohort, modules }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    customization: user.customization,
    liveSessions: modules.liveSessionDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleLiveSessions: (cohortId, moduleId) =>
      dispatch(getModuleLiveSessions(cohortId, moduleId)),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId)),
    deleteLiveSession: (livesessionId, cohortId, moduleId) =>
      dispatch(deleteLiveSession(livesessionId, cohortId, moduleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLiveSession);
