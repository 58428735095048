import React, { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import TimeMe from "timeme.js";

import styles from "../Stylesheets/Modules/moduleContent.module.scss";

import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import CohortBackNav from "../Components/CohortBackNav";
import EditorWrapper from "../Components/EditorWrapper";

import {
  getModuleCourseContent,
  markCourseContentSectionAsComplete,
  setTimeSpentOnContentAction,
  setTimeSpentOnContent,
  getVideoCustomization,
  updateVideoStatus,
  getStudentModuleQuestionContent,
} from "../Store/Actions/actions.modules";
import { connect } from "react-redux";
import {
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
  StyledCloseIcon,
  StyledMenuIcon,
} from "../Components/StyledComponents/Icons.style";
import { logPageView } from "../Utils/analytics";

import Player from "@vimeo/player";
import { getQueryParams } from "../Helpers/utils";
import { triggerASAPoints } from "../Store/Actions/actions.app";
import { handleActionCompletionASA } from "../Helpers/app";
import { useConfirmDialog } from "../Context/ConfirmDialogContext";
import videojs from "video.js";
import { Tooltip } from "@mui/material";

const ModuleContent = (props) => {
  const history = useNavigate();
  const { cohortId, moduleId } = useParams();
  const [studentId] = useState(props.userInfo.id);
  const [currentNav, setCurrentNav] = useState(0);

  const [searchParams] = useSearchParams();
  const [module, setModule] = useState();
  const [section, setSection] = useState();
  const [hide, setHide] = useState(true);
  const [isEditorLoading, setEditorLoading] = useState(true);
  const vimeoPlayerRef = useRef(null);
  const videoJsPlayerRef = useRef(null);
  const [videoCustomization, setVideoCustomization] = useState({});
  const { user_id, unique_task_key, step_number } = getQueryParams();
  const { getVideoCustomization, updateVideoStatus } = props;
  const { checkConditionAndShowDialog } = useConfirmDialog();
  const [containsModuleQuestion, setContainsModuleQuestions] = useState(false);
  useEffect(() => {
    props.getStudentModuleQuestionContent(studentId, cohortId, moduleId);
  }, []);

  useEffect(() => {
    if (props.questionnaires?.length > 0) setContainsModuleQuestions(true);
  }, [props.questionnaires]);
  useEffect(() => {
    TimeMe.startTimer(`Total-module-section-Time${currentNav}`);

    const didUnmountCallback = async () => {
      TimeMe.stopTimer(`Total-module-section-Time${currentNav}`);
      let time = TimeMe.getTimeOnPageInSeconds(
        `Total-module-section-Time${currentNav}`
      );
      let sectionId = section?._id;
      // TODO need to make use of state to use effective API calls
      if (sectionId)
        await props.setTimeSpentOnContentAction({ sectionId, time });
      await setTimeSpentOnContent({
        cohortId,
        moduleId,
        time,
        sectionId,
        userId: studentId,
      });
    };

    return () => {
      didUnmountCallback();
      TimeMe.resetRecordedPageTime(`Total-module-section-Time${currentNav}`);
    };
  }, []);

  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setTimeout(() => {
      setEditorLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.sections?.length > 0) {
      const selectedSection = props.sections?.[0];
      if (searchParams.get("sectionId")) {
        const sectionId = searchParams.get("sectionId");
        const selection = props.sections.find((sec) => sec._id === sectionId);
        const idx = props.sections.findIndex((sec) => sec._id === sectionId);
        setSection(selection);
        setCurrentNav(idx);
      } else {
        let url = `/cohort/${cohortId}/curriculum/${moduleId}/content?cohort=${searchParams.get(
          "cohort"
        )}&module=${searchParams.get("module")}`;
        url = `${url}&sectionId=${selectedSection._id}`;
        history(url);
        setSection(selectedSection);
        setCurrentNav(0);
      }
    }
  }, [props.sections]);

  useEffect(() => {
    if (
      section &&
      section.isCompleted &&
      user_id &&
      unique_task_key &&
      step_number
    ) {
      props.triggerASAPoints();
      handleActionCompletionASA();
    }
  }, [section]);

  useEffect(() => {
    props.getModuleCourseContent(cohortId, studentId, moduleId).then(() => {
      getVideoCustomization(cohortId, studentId, moduleId).then((res) => {
        setVideoCustomization(res.data);
      });
    });
  }, []);

  useEffect(() => {
    if (vimeoPlayerRef.current) {
      const iframe = vimeoPlayerRef.current;
      const player = new Player(iframe);

      if (
        videoCustomization?.hasOwnProperty(section._id) &&
        videoCustomization[section._id].disableSeek &&
        !videoCustomization[section._id].isWatched
      ) {
        // Disable seek
        let timeWatched = 0;

        player.on("timeupdate", (data) => {
          if (data.seconds - 1 < timeWatched && data.seconds > timeWatched) {
            timeWatched = data.seconds;
          }
        });

        player.on("seeked", (data) => {
          if (timeWatched < data.seconds) {
            player.setCurrentTime(timeWatched);
          }
        });
      }

      // Listen for the 'ended' event
      player.on("ended", () => {
        // If the video is ended, then we need to update the isWatched status in db
        if (
          videoCustomization?.hasOwnProperty(section._id) &&
          videoCustomization[section._id].disableSeek &&
          !videoCustomization[section._id].isWatched
        ) {
          updateVideoStatus(
            cohortId,
            studentId,
            moduleId,
            section._id,
            section.videoURL
          ).then(() => {
            getVideoCustomization(cohortId, studentId, moduleId).then((res) => {
              setVideoCustomization(res.data);
            });
          });
        }
      });

      // Clean up function to remove the event listener
      return () => {
        player.off("ended");
      };
    } else if (videoJsPlayerRef.current) {
      const player = videojs(videoJsPlayerRef.current, {
        playbackRates: [0.5, 1, 1.5, 2],
      });
      player.ready(() => {
        player.src(section.videoURL);
        if (section.captionsURL) {
          player.addRemoteTextTrack(
            {
              kind: "captions",
              src: section.captionsURL,
              label: "English",
              language: "en",
              default: true,
            },
            false
          );
        }
      });

      if (
        videoCustomization?.hasOwnProperty(section._id) &&
        videoCustomization[section._id].disableSeek &&
        !videoCustomization[section._id].isWatched
      ) {
        // Disable seek
        let lastTime = 0;

        player.on("timeupdate", () => {
          if (!player.seeking()) {
            lastTime = player.currentTime();
          }
        });

        player.on("seeking", () => {
          const currentTime = player.currentTime();
          if (currentTime > lastTime) {
            player.currentTime(lastTime);
          }
        });
      }

      // Listen for the 'ended' event
      player.on("ended", () => {
        // If the video is ended, then we need to update the isWatched status in db
        if (
          videoCustomization?.hasOwnProperty(section._id) &&
          videoCustomization[section._id].disableSeek &&
          !videoCustomization[section._id].isWatched
        ) {
          updateVideoStatus(
            cohortId,
            studentId,
            moduleId,
            section._id,
            section.videoURL
          ).then(() => {
            getVideoCustomization(cohortId, studentId, moduleId).then((res) => {
              setVideoCustomization(res.data);
            });
          });
        }
      });
    }
  }, [
    section,
    vimeoPlayerRef.current,
    videoJsPlayerRef.current,
    videoCustomization,
  ]);

  const markSectionAsComplete = async (section) => {
    await props.markCourseContentSectionAsComplete(
      cohortId,
      studentId,
      moduleId,
      section._id,
      section.sectionWeightage ? section.sectionWeightage : 0
    );
    await props.getModuleCourseContent(cohortId, studentId, moduleId);
  };

  const location = useLocation();

  const showMarkComplete = (section) => {
    return (
      !section?.isCompleted &&
      (!videoCustomization[section._id] ||
        !videoCustomization[section._id].videoURL ||
        !videoCustomization[section._id].disableSeek ||
        (videoCustomization[section._id].videoURL &&
          videoCustomization[section._id].isWatched))
    );
  };

  useEffect(() => {
    logPageView("[Student] Module Content Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  const getSectionTitle = (sectionTitle) => {
    const titletoDisplay =
      sectionTitle.length > 20
        ? sectionTitle.substring(0, 20) + "..."
        : sectionTitle;
    return titletoDisplay;
  };

  const contentNav = () => {
    return (
      <div
        className={styles["content-nav"]}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={styles["menu-close-icon"]}
          onClick={() => {
            setHide(true);
          }}
        >
          <StyledCloseIcon />
        </div>
        <div className={styles["nav-module-name"]}>{module?.name}</div>
        {props.sections?.map((sec, idx) => {
          return (
            <div
              data-event-name="[Student] Module Content Section Navigation"
              key={`${sec?._id}-item`}
              className={`${styles["nav-item"]} ${
                sec?._id === section?._id ? styles["selected"] : ""
              }`}
              onClick={() => {
                const selectedSection = props.sections?.find(
                  (s) => s?._id === sec?._id
                );
                let url = `/cohort/${cohortId}/curriculum/${moduleId}/content?cohort=${searchParams.get(
                  "cohort"
                )}&module=${searchParams.get("module")}`;
                url = `${url}&sectionId=${selectedSection._id}`;
                checkConditionAndShowDialog(() => {
                  history(url);
                  setCurrentNav(idx);
                  setEditorLoading(true);
                  setSection(selectedSection);
                  setTimeout(() => {
                    setEditorLoading(false);
                  }, 1000);
                  setHide(true);
                });
              }}
            >
              <CheckIcon
                classes={{
                  root: `${styles["tick-icon"]} ${
                    sec.isCompleted ? styles["completed"] : ""
                  }`,
                }}
              />
              <Tooltip title={sec.title}>
                <div className={styles["nav-item-title"]}>
                  {getSectionTitle(sec.title)}
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={styles["module-content-main"]}
      onClick={() => !hide && setHide(true)}
    >
      <CohortBackNav
        data-event-name={`[Student] Clicked On Back to ${searchParams.get(
          "module"
        )} Overview`}
        link={`/cohort/${cohortId}/curriculum/${moduleId}?cohort=${searchParams.get(
          "cohort"
        )}&module=${searchParams.get("module")}`}
        text={`Back to ${searchParams.get("module")} Overview`}
      />
      <div
        className={`${styles["module-content-container"]} ${
          !hide ? styles["with-hidden-nav"] : ""
        }`}
      >
        {!hide && <div className={styles["floating-nav"]}>{contentNav()}</div>}
        {hide && (
          <div
            className={styles["content-menu-icon"]}
            onClick={() => {
              setHide(false);
            }}
          >
            <StyledMenuIcon />
          </div>
        )}
        {contentNav()}
        <div className={styles["content-body"]}>
          {(section?.description || section?.videoURL) && (
            <div
              key={`${section?._id}-body`}
              className={styles["content-item"]}
            >
              <div className={styles["title"]}>{section.title}</div>
              {section?.videoURL && (
                <div className={styles["module-section-video-player"]}>
                  {!section.videoURL.includes("vimeo") ? (
                    <video
                      ref={videoJsPlayerRef}
                      controls
                      onContextMenu={(e) => e.preventDefault()}
                      className={`${styles["card-video-player"]} video-js `}
                    />
                  ) : (
                    <iframe
                      ref={vimeoPlayerRef}
                      src={`${section.videoURL}&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=256295`}
                      className={styles["card-video-player"]}
                      frameBorder="0"
                      allowFullScreen
                      title={section.videoTitle}
                    />
                  )}
                </div>
              )}
              {section?.description?.length > 0 && (
                <EditorWrapper readOnly={true} content={section.description} />
              )}

              <div
                className={`${styles["module-section-nav-wrapper"]} ${
                  !showMarkComplete(section) ? styles["no-mark-complete"] : ""
                }`}
              >
                <div className={styles["nav-prev-container"]}>
                  {currentNav !== 0 && (
                    <div
                      data-event-name="[Student] Module Content Navigation - Prev"
                      className={styles["nav-prev"]}
                      onClick={() => {
                        const selectedSection =
                          props?.sections?.[currentNav - 1];
                        let url = `/cohort/${cohortId}/curriculum/${moduleId}/content?cohort=${searchParams.get(
                          "cohort"
                        )}&module=${searchParams.get("module")}`;
                        url = `${url}&sectionId=${selectedSection._id}`;
                        checkConditionAndShowDialog(() => {
                          history(url);
                          setCurrentNav(currentNav - 1);
                          setSection(selectedSection);
                        });
                      }}
                    >
                      <StyledChevronLeftIcon /> Prev
                    </div>
                  )}
                </div>
                <div className={styles["nav-next-container"]}>
                  {currentNav < props.sections.length - 1 ? (
                    <div
                      data-event-name="[Student] Module Content Navigation - Next"
                      className={styles["nav-next"]}
                      onClick={() => {
                        const selectedSection =
                          props?.sections?.[currentNav + 1];
                        let url = `/cohort/${cohortId}/curriculum/${moduleId}/content?cohort=${searchParams.get(
                          "cohort"
                        )}&module=${searchParams.get("module")}`;
                        url = `${url}&sectionId=${selectedSection._id}`;
                        checkConditionAndShowDialog(() => {
                          history(url);
                          setCurrentNav(currentNav + 1);
                          setSection(selectedSection);
                        });
                      }}
                    >
                      Next <StyledChevronRightIcon />
                    </div>
                  ) : (
                    containsModuleQuestion && (
                      <div
                        data-event-name="[Student] Module Content Navigation - Next Questions"
                        className={styles["nav-next"]}
                        onClick={() => {
                          let url = `/cohort/${cohortId}/curriculum/${moduleId}/questions?cohort=${searchParams.get(
                            "cohort"
                          )}&module=${searchParams.get("module")}`;
                          checkConditionAndShowDialog(() => history(url));
                        }}
                      >
                        {props?.customization?.module_label === ""
                          ? "Module"
                          : props?.customization?.module_label}
                        {" Questions"}
                        <StyledChevronRightIcon />
                      </div>
                    )
                  )}
                </div>
              </div>

              {showMarkComplete(section) && (
                <div className={styles["mark-complete-btn"]}>
                  <Button
                    classes={{
                      root: styles["button-root"],
                    }}
                    variant="contained"
                    onClick={() => {
                      markSectionAsComplete(section);
                    }}
                  >
                    Mark Complete
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, modules, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    sections: modules.sections.sections,
    userInfo: user.userDetails,
    customization: user.customization,
    questionnaires: modules.questionnaires,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleCourseContent: (cohortId, studentId, moduleId) =>
      dispatch(getModuleCourseContent(cohortId, studentId, moduleId)),
    markCourseContentSectionAsComplete: (
      cohortId,
      studentId,
      moduleId,
      sectionId,
      weightage
    ) =>
      dispatch(
        markCourseContentSectionAsComplete(
          cohortId,
          studentId,
          moduleId,
          sectionId,
          weightage
        )
      ),
    getVideoCustomization: (cohortId, studentId, moduleId) =>
      dispatch(getVideoCustomization(cohortId, studentId, moduleId)),
    updateVideoStatus: (cohortId, studentId, moduleId, sectionId, url) =>
      dispatch(
        updateVideoStatus(cohortId, studentId, moduleId, sectionId, url)
      ),
    getStudentModuleQuestionContent: (studentId, cohortId, moduleId) =>
      dispatch(getStudentModuleQuestionContent(studentId, cohortId, moduleId)),
    setTimeSpentOnContentAction: (data) =>
      dispatch(setTimeSpentOnContentAction(data)),
    triggerASAPoints: () => dispatch(triggerASAPoints()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleContent);
