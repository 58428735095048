import React, { useReducer, useEffect } from "react";
import styles from "../Stylesheets/Modules/moduleCreationFormComp.module.scss";
import { FormControl, FormLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  createNewModule,
  getAllModules,
  getModuleOptions,
  updateModuleOptions,
} from "../Store/Actions/actions.modules";
import { connect } from "react-redux";

const ModuleCreationFormComp = (props) => {
  const { createNewModule, getModuleOptions, moduleOptions, selectedSkills } = props;
  const [aweight, setAWeight] = useState(0);
  const [arweight, setARWeight] = useState(0);
  const [lsweight, setLSWeight] = useState(0);
  const [weightageRemaining, setWeightageRemaining] = useState(100);
  const [aweightSet, SetAWeightSet] = useState(false);
  const [arweightSet, SetARWeightSet] = useState(false);
  const [lsweightSet, SetLsWeightSet] = useState(false);
  const [editModeWeight, setEditModeWeight] = useState(100);
  const [resultList, setResultList] = useState(moduleOptions);
  const [resultListCopy, setResultListCopy] = useState([]);
  const moduleName = props.customization.module_label;
  const page = props.page;
  useEffect(() => {
    if (props.editMode) {
      setAWeight(resultList[0].weightage);
      setARWeight(resultList[1].weightage);
      setLSWeight(resultList[2].weightage);
    }
  }, []);
  const isButtonDisabled = () => {
    return !(weightageRemaining - lsweight - arweight - aweight >= 0) || (lsweight < 0) || (lsweight.toString().includes('e') || lsweight.toString().includes('-')) || (lsweight === "") ||
      (arweight < 0) || (arweight.toString().includes('e') || arweight.toString().includes('-')) || (arweight === "") || (aweight < 0) || (aweight.toString().includes('e') || aweight.toString().includes('-')) || (aweight === "");
  };
  const getEditModeWeight = () => {
    return 100 - aweight - arweight - lsweight;
  };
  const handleSubmit = () => {
    var moduleOptions = [];
    resultList.map((item, key) => {
      if (item.text === "Course Content") {
        moduleOptions.push({
          key: item.text,
          label: item.label,
          active: item.active,
          weightage: parseInt(aweight),
        });
      }
      if (item.text === "Module Questions") {
        moduleOptions.push({
          key: item.text,
          label: item.label,
          active: item.active,
          weightage: parseInt(arweight),
        });
      }
      if (item.text === "Live Sessions") {
        moduleOptions.push({
          key: item.text,
          label: item.label,
          active: item.active,
          weightage: parseInt(lsweight),
        });
      }
      return moduleOptions;
    }, []);

    const name = props.moduleName;
    const cohortId = props.cohortId;
    const description = props.description;
    const moduleId = props.moduleId;
    resultList[0].weightage = aweight;
    resultList[1].weightage = arweight;
    // if conflict recieved/develop code merging to master choose develop code
    resultList[2].weightage = lsweight;
    let moduleSkills = []
    props.selectedSkills.forEach((skill) => {
      moduleSkills.push(skill._id)
    })
    if (props.editMode) {
      props.updateModuleOptions(
        moduleId._id,
        name,
        description,
        cohortId,
        resultList,
        moduleSkills
      );
    } else {
      createNewModule({
        name,
        cohortId,
        description,
        moduleOptions: resultList,
        skills: moduleSkills
      });
    }
    page("page1");
    props.togglePopUp(false);
    props.setSelectedSkills([])
  };
  const handleCheckSelection = (key) => {
    if (!resultList[key]?.active) {
      resultList[key].active = !resultList[key].active;
      setResultListCopy([...resultListCopy, resultList[key]]);
      setResultList(resultList);
    } else {
      resultList[key].active = !resultList[key].active;
      setResultListCopy(resultListCopy.splice(0, key));
      setResultList(resultList);
      if (key === 0) setAWeight(0);
      else if (key === 1) setARWeight(0);
      else if (key === 2) setLSWeight(0);
    }
  };
  const handleCheckSelection2 = (key) => {
    if (!moduleOptions2[key]?.active) {
      moduleOptions2[key].active = !moduleOptions2[key].active;
      setResultListCopy([...resultListCopy, moduleOptions2[key]]);
      setResultList(moduleOptions2);
    } else {
      moduleOptions2[key].active = !moduleOptions2[key].active;
      setResultListCopy(resultListCopy.splice(0, key));
      setResultList(moduleOptions2);
    }
  };

  const setWeights = (e, type) => {
    switch (type) {
      case "Course Content":
        setAWeight(e.target.value);
        SetAWeightSet(true);

        break;
      case "Module Questions":
        setARWeight(e.target.value);
        SetARWeightSet(true);

        break;
      case "Live Sessions":
        setLSWeight(e.target.value);
        SetLsWeightSet(true);

        break;

      default:
        break;
    }
  };
  const moduleOptions2 = [
    {
      id: "1",
      key: "courseContent",
      label: "Course Content",
      active: true,
      weightage: 0,
    },
    {
      id: "2",
      key: "moduleQuestions",
      label: `${moduleName} Questions`,
      active: true,
      weightage: 0,
    },
    {
      id: "3",
      key: "liveSessions",
      label: "Live Sessions",
      active: true,
      weightage: 0,
    },
  ];

  return (
    <>
      <div className={styles["form-wrapper"]}>
        <form variant="outlined" className={styles["form-control"]}>
          <FormLabel className={styles["form-label"]}>
            Select options you want to have in the module-
          </FormLabel>
          {resultList.length === 0 ? (
            <div className={styles["options-wrapper-row"]}>
              {moduleOptions2.map((item, key) => {
                return (
                  <div
                    className={`${styles["div-checkbox"]} ${item.active ? "" : styles["disabled"]
                      }`}
                    key={key}
                    onClick={() => handleCheckSelection2(key)}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles["options-wrapper-row"]}>
              {resultList.map((item, key) => {
                return (
                  <div
                    className={`${styles["div-checkbox"]} ${item.active ? "" : styles["disabled"]
                      }`}
                    key={key}
                    onClick={() => handleCheckSelection(key)}
                  >
                    {item.key === "moduleQuestions"
                      ? `${moduleName} Questions`
                      : item.label}
                  </div>
                );
              })}
            </div>
          )}

          {/* In this code , I have commented the weightage section part, as we need to implmenent them in future */}
          <FormLabel className={styles["form-label"]}>
            Set Weightage for Sections-
          </FormLabel>
          <div className={styles["weightage-row"]}>
            {resultList.map((list, key) => {
              return (
                list.active &&
                list.weightage >= 0 && (
                  <div className={styles["assignment-weightage"]} key={key}>
                    <FormLabel className={styles["form-label"]}>
                      {list.label}
                    </FormLabel>
                    {list.key === "courseContent" && (
                      <TextField
                        type={"Number"}
                        className={styles["text-field"]}
                        variant="outlined"
                        value={aweight}
                        onChange={(e) => {
                          setWeights(e, list.label);
                        }}
                      />
                    )}
                    {list.key === "moduleQuestions" && (
                      <TextField
                        type={"Number"}
                        className={styles["text-field"]}
                        variant="outlined"
                        value={arweight}
                        onChange={(e) => {
                          setWeights(e, list.label);
                        }}
                      />
                    )}
                    {list.key === "liveSessions" && (
                      <TextField
                        type={"Number"}
                        className={styles["text-field"]}
                        variant="outlined"
                        value={lsweight}
                        onChange={(e) => {
                          setWeights(e, list.label);
                        }}
                      />
                    )}
                  </div>
                )
              );
            })}

            <div className={styles["total-credit"]}>
              <FormLabel className={styles["form-label"]}>
                Total Credit
              </FormLabel>

              {props.editMode ? (
                <div className={styles["weightageDisplay"]}>
                  {getEditModeWeight()}
                </div>
              ) : (
                <div className={styles["weightageDisplay"]}>
                  {lsweightSet || arweightSet || aweightSet
                    ? weightageRemaining - lsweight - arweight - aweight
                    : 100}
                </div>
              )}
              <div>(credits left)</div>
            </div>
          </div>
        </form>
        <br />
        <br />
        <div className={styles["submit-section"]}>
          {props.editMode ? (
            <Button
              classes={{
                root: `${styles["create-btn"]} ${isButtonDisabled() ? styles["disabled"] : ""
                  }`,
              }}
              onClick={handleSubmit}
              disabled={isButtonDisabled()}
            >
              Update
            </Button>
          ) : (
            <Button
              classes={{
                root: `${styles["create-btn"]} ${isButtonDisabled() ? styles["disabled"] : ""
                  }`,
              }}
              // classes={{
              //   root: `${styles["create-btn"]}`,
              // }}
              onClick={handleSubmit}
              disabled={isButtonDisabled()}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ app, modules, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    allModules: modules.modules,
    themeInfo: user.themeInfo,
    moduleOptions: modules.moduleOptions,
    customization: user.customization,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createNewModule: (modules) => dispatch(createNewModule(modules)),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId)),
    updateModuleOptions: (
      moduleId,
      name,
      description,
      cohortId,
      moduleOptions,
      skills
    ) =>
      dispatch(
        updateModuleOptions(moduleId, name, description, cohortId, moduleOptions, skills)
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModuleCreationFormComp);
