import React, { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import styles from "../../../Stylesheets/Modules/moduleContent.module.scss";
import ConfirmDialog from "../../../Reusable/Modals/ConfirmDialog";
import CohortBackNav from "../../../Components/CohortBackNav";
import Add from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditorWrapper from "../../../Components/EditorWrapper";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
  addModuleSection,
  deletesection,
  getModuleContent,
  updateModuleSection,
  updateSectionsOrder,
  getModuleOptions,
} from "../../../Store/Actions/actions.modules";
import {
  TransparentButton,
  OutlinedButtonSmall,
} from "../../../Components/StyledComponents/Button.style";
import {
  StyledCloseIcon,
  StyledDeleteIcon,
  StyledDragHandleIcon,
  StyledEditIcon,
  StyledMenuIcon,
} from "../../../Components/StyledComponents/Icons.style";
import {
  fetchVideoURL,
  uploadVideoCaptions,
  deleteCaption,
  setUploadProgress,
} from "../../../Store/Actions/actions.cohort";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import ModuleSectionCreationForm from "../../../Reusable/ModuleSectionCreationForm";
import { Download } from "@mui/icons-material";
import { logPageView } from "../../../Utils/analytics";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import toast from "react-hot-toast";

const ModuleContentCreation = (props) => {
  const history = useNavigate();
  const { cohortId, moduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [module, setModule] = useState();
  const [section, setSection] = useState();
  const [allsections, setAllSection] = useState([]);
  const [content, setContent] = useState();
  const [isEditMode, setEditMode] = useState(false);
  const [secTitle, setSecTitle] = useState("");
  const [newSectionCreated, setNewSectionCreated] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [hide, setHide] = useState(true);
  const [isEditorLoading, setEditorLoading] = useState(true);
  const [draggedSections, setDraggedSections] = useState(false);
  const [url, setURL] = useState("");
  const [videoName, setVideoName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState(false);
  const options = ["yes", "no"];
  const [captionFileName, setCaptionFileName] = useState("");
  const [captionFileLink, setCaptionFileLink] = useState("");
  const videoJsPlayerRef = useRef(null);

  const { sections, videoMeta, setUploadProgress } = props;

  const isVideoUploadingEnabled = props.config.videoUploading;

  useEffect(() => {
    setTimeout(() => {
      setEditorLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    props.getModuleOptions(moduleId);
  }, []);
  useEffect(() => {
    if (sections?.length > 0) {
      const selectedSection = sections?.[0];
      setSection(selectedSection);
      setURL(selectedSection.videoURL);
      setVideoName(selectedSection.videoTitle);
      setCaptionFileLink(selectedSection.captionsURL);
      setCaptionFileName(selectedSection.captionsTitle);
      setAllSection(sections);
      if (searchParams.get("sectionId")) {
        const sectionId = searchParams.get("sectionId");
        const selection = sections.find((sec) => sec._id === sectionId);
        setSection(selection);
        setURL(selection?.videoURL || "");
        setVideoName(selection?.videoTitle || "");
        setCaptionFileLink(selection?.captionsURL || "");
        setCaptionFileName(selection?.captionsTitle || "");
      } else {
        let url = `/admin/cohort/${cohortId}/module/${moduleId}/content?cohort=${searchParams.get(
          "cohort"
        )}&module=${searchParams.get("module")}`;
        url = `${url}&sectionId=${
          newSectionCreated
            ? sections[sections?.length - 1]._id
            : selectedSection._id
        }`;
        history(url);
      }
      setUploadProgress(0);
      if (newSectionCreated) {
        let url = `/admin/cohort/${cohortId}/module/${moduleId}/content?cohort=${searchParams.get(
          "cohort"
        )}&module=${searchParams.get("module")}`;
        url = `${url}&sectionId=${sections[sections?.length - 1]._id}`;
        history(url);
        setNewSectionCreated(false);
      }
    } else {
      setSection(undefined);
      setVideoName("");
      setAllSection("");
      setAllSection([]);
    }
  }, [sections, searchParams, newSectionCreated]);

  useEffect(() => {
    props.getModuleContent(moduleId);
  }, []);

  useEffect(() => {
    if (section?.videoMeta && Object.keys(section?.videoMeta).length) {
      setAdditionalDetails(true);
      if (section.videoMeta.disableSeek) setSelectedOption("yes");
      else setSelectedOption("no");
    }
  }, [section]);

  const deletesectionhandler = (moduleId, sectionid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    props.deletesection(moduleId, sectionid);
    props.getModuleContent(moduleId);
  };

  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);
  let _sections = [...allsections];
  const handleDrag = () => {
    let _sections = [...allsections];
    const draggedSections = _sections.splice(dragItem.current, 1)[0];

    _sections.splice(dragOverItem.current, 0, draggedSections);

    dragItem.current = null;
    dragOverItem.current = null;
    setAllSection(_sections);

    if (JSON.stringify(allsections) === JSON.stringify(_sections)) {
      setDraggedSections(false);
    } else {
      setDraggedSections(true);
    }
  };
  const handleDragSave = (_sections) => {
    props.updateSectionsOrder(_sections, moduleId, cohortId);
  };

  const handleURLChange = async (e) => {
    const file = e.target.files[0];
    // Fallback: Check file extension if file.type is not available
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (file.type !== "video/mp4" && fileExtension !== "video/mp4") {
      toast.error("Please upload a valid mp4 video file.");
      return;
    }
    await props.fetchVideoURL(file).then((res) => {
      const player_embed_url = res.data.videoUrl;
      const parts = player_embed_url.split("/");
      const name = parts[parts.length - 1];
      props.updateModuleSection(
        {
          sectionId: section?._id,
          title: section.title,
          description: content,
          videoURL: player_embed_url,
          videoTitle: name,
        },
        moduleId
      );
    });
  };

  const handleCaptionFileUpload = async (e) => {
    const file = e.target.files[0];
    // Fallback: Check file extension if file.type is not available
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (file.type !== "text/vtt" && fileExtension !== "vtt") {
      toast.error("Please upload a valid vtt captions file.");
      return;
    }
    const videoId = section.videoURL.match(/\/([^/]+)\/[^/]+\.mp4$/)[1];
    await props.uploadVideoCaptions(videoId, file).then((res) => {
      setCaptionFileName(res.data.name);
      setCaptionFileLink(res.data.url);
      props.updateModuleSection(
        {
          sectionId: section?._id,
          title: section.title,
          description: content,
          videoURL: section.videoURL,
          videoTitle: section.videoTitle,
          captionsURL: res.data.url,
          captionsTitle: res.data.name,
        },
        moduleId
      );
    });
  };

  useEffect(() => {
    setURL(videoMeta.url);
    setVideoName(videoMeta.title);
  }, [videoMeta.url, videoMeta.title]);

  useEffect(() => {
    if (section && url && videoJsPlayerRef && videoJsPlayerRef.current) {
      const player = videojs(videoJsPlayerRef.current);
      player.ready(() => {
        player.src(url);
        if (captionFileLink) {
          player.addRemoteTextTrack(
            {
              kind: "captions",
              src: captionFileLink,
              label: "English",
              language: "en",
              default: true,
            },
            false
          );
        }
      });
      return () => {
        if (player) {
          player.dispose(); // Dispose of the player when the component unmounts
        }
      };
    }
  }, [videoJsPlayerRef.current]);

  const changeOrder = async () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    handleDragSave(_sections);
  };

  const getSectionTitle = (sectionTitle) => {
    const titletoDisplay =
      sectionTitle.length > 20
        ? sectionTitle.substring(0, 20) + "..."
        : sectionTitle;
    return titletoDisplay;
  };
  const contentNav = () => {
    return (
      <div
        className={styles["content-nav"]}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={styles["menu-close-icon"]}
          onClick={() => {
            setHide(true);
          }}
        >
          <StyledCloseIcon />
        </div>
        <div
          className={styles["nav-module-name"]}
          style={{ color: props.themeInfo[0]?.secondarycolor }}
        >
          {module?.name}
        </div>
        {allsections?.map((sec, index) => {
          const isEditable = isEditMode && section?._id === sec._id;
          return (
            <div
              style={{ color: props.themeInfo[0]?.secondarycolor }}
              key={`${sec._id}-item`}
              className={`
                ${styles["nav-item"]} 
                ${sec._id === section?._id ? styles["selected"] : ""}
                ${isEditable ? styles["editable"] : ""}
                ${styles["admin-nav-item"]} 
              `}
              onClick={() => {
                if (isEditable) {
                  return;
                }
                const selectedSection = allsections?.find(
                  (s) => s._id === sec._id
                );

                // update the search params with the current selection
                let url = `/admin/cohort/${cohortId}/module/${moduleId}/content?cohort=${searchParams.get(
                  "cohort"
                )}&module=${searchParams.get("module")}`;
                url = `${url}&sectionId=${selectedSection._id}`;
                history(url);

                setEditorLoading(true);
                setSection(selectedSection);
                setURL(selectedSection?.videoURL || "");
                setVideoName(selectedSection?.videoName || "");
                setCaptionFileLink(selectedSection.captionsURL || "");
                setCaptionFileName(selectedSection.captionsTitle || "");
                setEditMode(false);
                setTimeout(() => {
                  setEditorLoading(false);
                }, 1000);
                setHide(true);
                setSelectedOption(false);
                setUploadProgress(0);
              }}
              draggable
              onDragStart={(e) => (dragItem.current = index)}
              onDragEnter={(e) => (dragOverItem.current = index)}
              onDragEnd={(e) => handleDrag()}
            >
              <Tooltip title={sec.title}>
                <div className={styles["nav-item-title"]}>
                  {getSectionTitle(sec.title)}
                </div>
              </Tooltip>
              <div className={styles["nav-item-weightage"]}>
                {sec.sectionWeightage}
              </div>
              <div className={styles["nav-item-actions"]}>
                <StyledEditIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    const selectedSection = allsections?.find(
                      (s) => s._id === sec._id
                    );
                    setSection(selectedSection);
                    setURL(selectedSection.videoURL);
                    setVideoName(selectedSection.videoTitle);
                    setCaptionFileLink(selectedSection.captionsURL);
                    setCaptionFileName(selectedSection.captionsTitle);
                    setUpdateMode(true);
                    setOpenModal(true);
                    setSecTitle(selectedSection.title);
                  }}
                />
                <StyledDeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    const tmp = allsections.filter((s) => s._id === sec._id);
                    if (section?._id === sec._id) {
                      setSection(tmp);
                    }
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure you want to delete",
                      onConfirm: () => {
                        deletesectionhandler(moduleId, sec._id);
                      },
                    });
                  }}
                />
                <span>
                  <StyledDragHandleIcon className={styles["drag-icon"]} />
                </span>
              </div>
            </div>
          );
        })}
        <div className={styles["create-content-container"]}>
          <TransparentButton
            startIcon={
              <Add
                classes={{
                  root: styles["arrow-icon"],
                }}
              />
            }
            onClick={() => {
              setSecTitle("");
              setOpenModal(true);
            }}
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
          >
            Add Section
          </TransparentButton>
          <GenericModal
            open={openModal}
            togglePopUp={setOpenModal}
            setUpdateMode={setUpdateMode}
          >
            <ModuleSectionCreationForm
              togglePopUp={setOpenModal}
              credits={props.creditLeft}
              updateMode={updateMode}
              setUpdateMode={setUpdateMode}
              sectionTitle={secTitle}
              sectionType={"Content"}
              section={section}
              content={props.sections}
              moduleOptions={props.moduleOptions}
              setNewSectionCreated={setNewSectionCreated}
            />
          </GenericModal>
          {draggedSections && (
            <TransparentButton
              onClick={(e) => {
                e.stopPropagation();
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure you want to change the order?",
                  onConfirm: () => {
                    changeOrder();
                    setDraggedSections(false);
                  },
                });
              }}
              bgcolor={
                props.themeInfo[0]?.primarycolor
                  ? props.themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
            >
              Save Changes ?
            </TransparentButton>
          )}
        </div>
      </div>
    );
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Module Content Creation Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <div
      className={styles["module-content-main"]}
      onClick={() => !hide && setHide(true)}
    >
      <CohortBackNav
        link={`/admin/createModule/${cohortId}?cohort=${searchParams.get(
          "cohort"
        )}`}
        text={`Back to ${searchParams.get("module")} Overview`}
      />
      <div
        className={`${styles["module-content-container"]} ${
          !hide ? styles["with-hidden-nav"] : ""
        }`}
      >
        {!hide && <div className={styles["floating-nav"]}>{contentNav()}</div>}
        {hide && (
          <div
            className={styles["content-menu-icon"]}
            onClick={() => {
              setHide(false);
            }}
          >
            <StyledMenuIcon />
          </div>
        )}
        {contentNav()}
        <div className={styles["content-body"]}>
          {section ? (
            <div
              key={`${section?._id}-body`}
              className={styles["content-body-inside-wrapper"]}
            >
              <div className={styles["title"]}>{section?.title}</div>
              <div className={styles["content-video-upload-wrapper"]}>
                <span>Video URL</span>
                {props.progress > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={props.progress}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${props.progress}%`}</Typography>
                    </Box>
                  </Box>
                )}
                <FormControl
                  sx={{ m: 1 }}
                  size="small"
                  classes={{
                    root: styles["form-control-root"],
                  }}
                  value={url}
                  onChange={handleURLChange}
                >
                  {url && (
                    <TextField
                      disabled={!isVideoUploadingEnabled}
                      value={url}
                      InputProps={{
                        endAdornment: url && (
                          <ClearIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              // Disabled as it causes a connected issue of deleting videos in cloned cohort. Ref: https://gritly.atlassian.net/browse/GP-864
                              // const videoId = url.match(
                              //   /\/([^/]+)\/[^/]+\.mp4$/
                              // )[1];
                              // props
                              //   .deleteVideo(videoId, "video")
                              //   .then(() => {});
                              setURL("");
                              setVideoName("");
                              setCaptionFileLink("");
                              setCaptionFileName("");
                              props.updateModuleSection(
                                {
                                  sectionId: section?._id,
                                  title: section.title,
                                  description: content,
                                  videoURL: "",
                                  videoTitle: "",
                                  captionsURL: "",
                                  captionsTitle: "",
                                },
                                moduleId
                              );
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                  {!url && (
                    <TextField
                      disabled={!isVideoUploadingEnabled}
                      type={"file"}
                      inputProps={{ accept: "video/mp4" }}
                    />
                  )}
                </FormControl>
                {isVideoUploadingEnabled === false && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="info">
                      Video Uploading is a premium feature, contact
                      support@Gritly.us to Enable!
                    </Alert>
                  </Stack>
                )}
              </div>
              {url && (
                <>
                  <div className={styles["video-preview-wrapper"]}>
                    <OutlinedButtonSmall
                      className={styles["button-styles"]}
                      startIcon={
                        <Add
                          classes={{
                            root: styles["arrow-icon"],
                          }}
                        />
                      }
                      bgcolor={
                        props.themeInfo[0]?.primarycolor
                          ? props.themeInfo[0]?.primarycolor
                          : "#0e1555"
                      }
                      fontFamily={
                        props.themeInfo[0]?.fontfamily
                          ? props.themeInfo[0]?.fontfamily
                          : "Nunito"
                      }
                      onClick={() => {
                        setAdditionalDetails(!additionalDetails);
                      }}
                    >
                      Add Video Customization
                    </OutlinedButtonSmall>
                    {additionalDetails && (
                      <div className={styles["video-add-on-features"]}>
                        Do you want to disable seeking of video if watched for
                        the first time?
                        {options.map((op, key) => {
                          return (
                            <label htmlFor={key}>
                              <input
                                name={key}
                                key={key}
                                className={styles["setOptionsRadio"]}
                                type="radio"
                                onChange={(e) => {
                                  setSelectedOption(e.target.value);
                                }}
                                checked={selectedOption === op ? true : false}
                                value={op}
                              />
                              {op}
                            </label>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className={styles["content-video-preview"]}>
                    {url.includes("vimeo") ? (
                      <iframe
                        src={`${url}&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=256295`}
                        width="600"
                        height="400"
                        frameBorder="0"
                        allowFullScreen
                        title={videoName}
                      />
                    ) : (
                      url && (
                        <video
                          ref={videoJsPlayerRef}
                          controls
                          onContextMenu={(e) => e.preventDefault()}
                          className="video-js"
                          style={{
                            width: "100%",
                            height: "500px",
                            objectFit: "contain",
                          }}
                        />
                      )
                    )}
                  </div>
                  <div className={styles["content-video-upload-wrapper"]}>
                    <span>Video Closed Captions</span>
                    <FormControl
                      sx={{ m: 1 }}
                      size="small"
                      classes={{
                        root: styles["form-control-root"],
                      }}
                      value={captionFileName}
                      onChange={handleCaptionFileUpload}
                    >
                      {captionFileName && (
                        <TextField
                          value={captionFileName}
                          InputProps={{
                            endAdornment: captionFileName && (
                              <>
                                <Download
                                  sx={{ cursor: "pointer" }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    window.open(captionFileLink, "_blank");
                                  }}
                                />
                                <ClearIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    // TODO: Implement backend support for caption only deletion
                                    // const videoId = url.match(
                                    //   /\/([^/]+)\/[^/]+\.mp4$/
                                    // )[1];
                                    // props
                                    //   .deleteVideo(videoId, "captions")
                                    //   .then(() => {
                                    //   });
                                    setCaptionFileName("");
                                    setCaptionFileLink("");
                                    props.updateModuleSection(
                                      {
                                        sectionId: section?._id,
                                        title: section.title,
                                        description: content,
                                        videoURL: section.videoURL,
                                        videoTitle: section.videoName,
                                        captionsURL: "",
                                        captionsTitle: "",
                                        videoMeta: {
                                          videoURL: section.videoURL,
                                          disableSeek:
                                            additionalDetails &&
                                            selectedOption === "yes"
                                              ? true
                                              : false,
                                        },
                                      },
                                      moduleId
                                    );
                                  }}
                                />
                              </>
                            ),
                          }}
                        />
                      )}
                      {!captionFileName && (
                        <TextField
                          type={"file"}
                          inputProps={{ accept: ".vtt" }}
                        />
                      )}
                    </FormControl>
                  </div>
                </>
              )}

              <Button
                variant="contained"
                classes={{
                  root: styles["save-button"],
                }}
                style={{
                  color: props.themeInfo[0]?.primarycolor,
                }}
                startIcon={
                  <SaveOutlinedIcon
                    classes={{
                      root: styles["save-icon"],
                    }}
                  />
                }
                onClick={() => {
                  props.updateModuleSection(
                    {
                      sectionId: section?._id,
                      title: section.title,
                      description: content,
                      videoURL: url,
                      videoTitle: videoName,
                      captionsURL: captionFileLink,
                      captionsTitle: captionFileName,
                      videoMeta: {
                        videoURL: url,
                        disableSeek:
                          additionalDetails && selectedOption === "yes"
                            ? true
                            : false,
                      },
                    },
                    moduleId
                  );
                }}
              >
                Save
              </Button>

              <EditorWrapper
                showPreview={true}
                readOnly={false}
                content={section?.description}
                setContent={setContent}
              />
            </div>
          ) : (
            <div>Select a section to view</div>
          )}
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmFor="content"
        setDraggedSections={setDraggedSections}
      />
    </div>
  );
};

const mapStateToProps = ({ app, modules, user, cohort, config }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    sections: modules.sections.sections,
    themeInfo: user.themeInfo,
    videoMeta: cohort.videoMeta,
    progress: cohort.progress,
    creditLeft: modules.sections.creditLeft,
    config: config.configData,
    moduleOptions: modules.moduleOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleContent: (modules) => dispatch(getModuleContent(modules)),
    addModuleSection: (section, moduleId) =>
      dispatch(addModuleSection(section, moduleId)),
    updateModuleSection: (section, moduleId) =>
      dispatch(updateModuleSection(section, moduleId)),
    deletesection: (moduleId, sectionid) =>
      dispatch(deletesection(moduleId, sectionid)),
    updateSectionsOrder: (sectionData, modulenumber, cohortId) =>
      dispatch(updateSectionsOrder(sectionData, modulenumber, cohortId)),
    fetchVideoURL: (file) => dispatch(fetchVideoURL(file)),
    uploadVideoCaptions: (videoId, captionFile) =>
      dispatch(uploadVideoCaptions(videoId, captionFile)),
    deleteCaption: (videoId, captionId) =>
      dispatch(deleteCaption(videoId, captionId)),
    getModuleOptions: (moduleId) => dispatch(getModuleOptions(moduleId)),
    setUploadProgress: (progress) => dispatch(setUploadProgress(progress)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModuleContentCreation);
