import React from 'react'
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss"
import { Box, Button } from "@mui/material";
import { connect } from 'react-redux'
import pdfIcon from "../../../../Assets/Images/pdf_179483.png";
import { useNavigate } from 'react-router-dom';
function StudentCertificates(props) {
    const { themeInfo, certificates, studentId } = props;
    const history = useNavigate();

    return (
        <Box className={styles["personal-details-box"]}>
            <Box
                className={`${styles["profile-header"]}`}
                style={{
                    background:
                        themeInfo[0]?.primarycolor !== "#0e1555"
                            ? themeInfo[0]?.primarycolor
                            : "",
                    fontFamily: themeInfo[0]?.fontfamily,
                }}
            >
                <Box className={styles['profile-header-name']}>Certificates</Box>
            </Box>
            <div className={styles['certificate-wrapper']}>
                {certificates?.length === 0 ? <div className={styles["no-skills-found"]}>
                    Click <div className={styles["redirection"]} onClick={() => history("/cohorts")}> here </div> to start earning !
                </div> : certificates?.map((item, idx) => {
                    return (
                        <div key={idx} className={styles['certificate']} onClick={() => {
                            window.open(`${process.env.REACT_APP_API_ENDPOINT}/public/download/certificate/${item.path}?studentId=${studentId}`, '_blank');
                        }}>
                            <img src={pdfIcon} alt="pdf" className={styles['pdf-image']} />
                            {item.name}
                        </div>
                    )
                })}
            </div>
        </Box>
    )
}
const mapStateToProps = ({ user }) => ({
    themeInfo: user.themeInfo,
    certificates: user.learnerProfileDetails.certificates,
    studentId: user.userDetails.id
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCertificates)