import Box from "@mui/material/Box";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Badge from "@mui/material/Badge";
import { setProfileImageUrl } from "../../../../Store/Actions/actions.user";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import { useLocation } from "react-router-dom";
import ImageUploader from "../../../../Reusable/ImageUploader";
import CropImageModal from "../../../../Reusable/Modals/CropImageModal";
import { useCallback, useEffect, useState } from "react";

const ProfileBanner = ({
  userInfo,
  setProfileImageUrlFunc,
  themeInfo,
  userProfile,
  preview,
}) => {
  const [profileImgUrl, setProfileImgUrl] = useState(
    userInfo.profileImgUrl || ""
  );
  const [profileImg, setProfileImg] = useState("");
  const [originalFileName, setOriginalFileName] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const location = useLocation().pathname;
  const studentHeaderTitle =
    (userProfile?.educationDetails &&
      userProfile?.educationDetails?.filter(
        (ele) => ele.currentInstitution
      )?.[0]) ||
    false;
  const handleCroppedFile = useCallback(
    (imageCropped) => {
      // Check if imageCropped is provided and not an empty string
      if (imageCropped && typeof imageCropped === "string") {
        setProfileImageUrlFunc(imageCropped, userInfo.id);
      } else if (
        userInfo.profileImgUrl &&
        typeof userInfo.profileImgUrl === "string"
      ) {
        // If imageCropped is empty or not provided, check if userInfo.profileImgUrl has data
        setProfileImgUrl(userInfo.profileImgUrl);
      } else {
        // If both imageCropped and userInfo.profileImgUrl are empty, set fileURL to an empty string
        setProfileImgUrl("");
      }
    },
    [userInfo.profileImgUrl]
  );
  useEffect(() => {
    if (uploaded) setCropDialogOpen(true);
  }, [uploaded]);
  return (
    <div
      className={styles["student-profile-banner"]}
      style={{
        background:
          themeInfo[0]?.primarycolor !== "#0e1555"
            ? themeInfo[0]?.primarycolor
            : "",
      }}
    >
      <Box className={styles["profile-banner-back"]}></Box>
      <Box className={styles["profile-banner-content"]}>
        {preview ? (
          <Badge
            overlap="circular"
            classes={{ badge: styles["badge-background"] }}
            className={styles["profile-camera-badge"]}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Avatar
              alt="Profile Photo"
              className={styles["profile-banner-photo"]}
              src={profileImgUrl}
              style={{
                backgroundColor: themeInfo[0]?.primarycolor,
              }}
            >
              {userInfo.firstName?.[0]}
            </Avatar>
          </Badge>
        ) : (
          <Badge
            overlap="circular"
            classes={{ badge: styles["badge-background"] }}
            className={styles["profile-camera-badge"]}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <ImageUploader
                acceptedFormats={["image/jpeg", "image/png"]}
                supportedExtensions={[".jpeg", ".png"]}
                customButton={
                  <AddAPhotoIcon className={styles["profile-camera-logo"]} />
                }
                onDrop={(event) => {
                  const reader = new FileReader();
                  reader.addEventListener("load", () => {
                    setProfileImg(reader.result);
                  });
                  reader.readAsDataURL(event[0]);
                  setOriginalFileName(event[0].name);
                  setUploaded(true);
                }}
              />
            }
          >
            {uploaded && (
              <CropImageModal
                imageToBeCropped={profileImg}
                cropDialogOpen={cropDialogOpen}
                originalFileName={originalFileName}
                setCropDialogOpen={setCropDialogOpen}
                setFileURL={handleCroppedFile}
                maxWidth={256}
                maxHeight={256}
                aspect={256 / 256}
                setUploaded={setUploaded}
                setFile={setProfileImgUrl}
              />
            )}
            <Avatar
              alt="Profile Photo"
              className={styles["profile-banner-photo"]}
              src={profileImgUrl}
              style={{
                backgroundColor: themeInfo[0]?.primarycolor,
              }}
            >
              {userInfo.firstName?.[0]}
            </Avatar>
          </Badge>
        )}
        <Box className={styles["profile-banner-info"]}>
          <Box className={styles["profile-banner-info-name"]}>
            {userInfo.firstName} {userInfo.lastName}{" "}
          </Box>
          {studentHeaderTitle && (
            <Box className={styles["profile-banner-info-designation"]}>
              {location === "/adminProfile"
                ? ""
                : `${studentHeaderTitle.degree} ( ${studentHeaderTitle.eduStartDate} - ${studentHeaderTitle.eduEndDate} )`}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    userInfo: user.userDetails,
    userProfile: user.userProfile,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileImageUrlFunc: (event, userId) =>
      dispatch(setProfileImageUrl(event, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBanner);
