import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import styles from "../Stylesheets/Modules/moduleSectionCreationForm.module.scss";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";
import {
  addModuleQuestionSection,
  getModuleQuestionContent,
  addModuleSection,
  updateModuleQuestionSection,
  getModuleContent,
  updateModuleSection,
} from "../Store/Actions/actions.modules";

export const ModuleSectionCreationForm = (props) => {
  const { moduleId, cohortId } = useParams();
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  const [label, setLabel] = useState(props.sectionTitle);
  const [sectionWeights, setSectionWeights] = useState(0);
  const [editing, setEditing] = useState(false);
  const [dueDate, setDueDate] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState("page1");
  const {
    sectionType,
    updateMode,
    setUpdateMode,
    questions,
    courseContent,
    content,
    setNewSectionCreated,
    setNewQuestionnaireSectionCreated,
  } = props;
  const { togglePopUp } = props;
  const [credits, setCreditsLeft] = useState("");
  const isModuleBtnDisabled = () => {
    return label.length === 0;
  };
  useEffect(() => {
    if (props.section?.dueDate && updateMode) {
      setDueDate(true);
      setEndDate(props.section.dueDate);
    }
  }, []);
  const createQuestionnaireSection = async () => {
    var dueDateFormatter =
      new Date(endDate).getMonth() +
      1 +
      "-" +
      new Date(endDate).getDate() +
      "-" +
      new Date(endDate).getFullYear();
    if (!updateMode) {
      await props.addModuleQuestionSection(
        moduleId,
        label,
        sectionWeights,
        dueDate ? dueDateFormatter : ""
      );
      setNewQuestionnaireSectionCreated(true);
    } else {
      setNewQuestionnaireSectionCreated(false);
      await props.updateModuleQuestionSection(
        props.section._id,
        label,
        props.section.questions,
        props.section.weightage,
        sectionWeights,
        dueDate ? dueDateFormatter : ""
      );
      let url = `/admin/cohort/${cohortId}/module/${moduleId}/questions?cohort=${searchParams.get(
        "cohort"
      )}&module=${searchParams.get("module")}`;
      url = `${url}&sectionId=${props.section._id}`;
      history(url);
    }

    await props.getModuleQuestionContent(moduleId);
    setSectionWeights(0);
    setUpdateMode(false);
  };
  const createContentSection = async () => {
    if (!updateMode) {
      await props.addModuleSection(
        [{ title: label, sectionWeightage: sectionWeights }],
        moduleId
      );
      setNewSectionCreated(true);
    } else {
      setNewSectionCreated(false);
      await props.updateModuleSection(
        {
          title: label,
          sectionWeightage: sectionWeights,
          sectionId: props.section?._id,
        },
        moduleId
      );
      let url = `/admin/cohort/${cohortId}/module/${moduleId}/content?cohort=${searchParams.get(
        "cohort"
      )}&module=${searchParams.get("module")}`;
      url = `${url}&sectionId=${props.section?._id}`;
      history(url);
    }
    await props.getModuleContent(moduleId);
    setSectionWeights(0);
    setUpdateMode(false);
  };
  useEffect(() => {
    setCreditsLeft(
      sectionType === "Content"
        ? courseContent.creditLeft
        : questions.creditLeft
    );
    if (updateMode) {
      setSectionWeights(
        sectionType === "Questionnaire"
          ? props.section?.questionnaireTotalWeightage || 0
          : props.section?.sectionWeightage || 0
      );
    }
  }, []);
  const isSaveButtonDisabled = () => {
    return (
      getCreditLeft() < 0 ||
      (dueDate &&
        (endDate === undefined || endDate === "" || !isValidDate(endDate))) ||
      sectionWeights < 0 ||
      sectionWeights.toString().includes("e") ||
      sectionWeights.toString().includes("-") ||
      sectionWeights === ""
    );
  };
  function isValidDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return !isNaN(date.getTime()) && date >= today;
  }
  const getCreditLeft = () => {
    var moduleWeightage = 0;
    var allsectionWeights = 0;
    if (sectionType === "Questionnaire") {
      moduleWeightage = 0;
      allsectionWeights = 0;
      moduleWeightage = props.moduleOptions[1].weightage;
      questions?.forEach((item) => {
        allsectionWeights += item.questionnaireTotalWeightage;
      });
      if (updateMode) {
        moduleWeightage = 0;
        allsectionWeights = 0;
        moduleWeightage = props.moduleOptions[1].weightage;
        questions?.forEach((item) => {
          if (item._id !== props.section._id)
            allsectionWeights += item.questionnaireTotalWeightage;
        });

        if (editing)
          return moduleWeightage - allsectionWeights - sectionWeights;
        return moduleWeightage - (allsectionWeights + sectionWeights);
      } else return moduleWeightage - allsectionWeights - sectionWeights;
    } else if (sectionType === "Content") {
      moduleWeightage = 0;
      allsectionWeights = 0;
      moduleWeightage = props.moduleOptions[0].weightage;
      content?.forEach((item) => {
        allsectionWeights += item.sectionWeightage;
      });
      if (updateMode) {
        moduleWeightage = 0;
        allsectionWeights = 0;

        moduleWeightage = props.moduleOptions[0].weightage;
        content?.forEach((item) => {
          if (item._id !== props.section._id)
            allsectionWeights += item.sectionWeightage;
        });

        if (editing)
          return moduleWeightage - allsectionWeights - sectionWeights;
        return moduleWeightage - (allsectionWeights + sectionWeights);
      } else return moduleWeightage - allsectionWeights - sectionWeights;
    }
  };
  const handleDueDateChecked = () => {
    setDueDate(!dueDate);
    setEndDate("");
  };
  const handleEndDate = (val) => {
    setEndDate(val);
  };
  return (
    <div className={styles["modal-container-wrapper"]}>
      {page === "page1" && (
        <>
          {updateMode ? (
            <div className={styles["modal-header"]}>
              Update {sectionType} Section{" "}
            </div>
          ) : (
            <div className={styles["modal-header"]}>
              Add {sectionType} Section{" "}
            </div>
          )}
          <div className={styles["content-body"]}>
            <TextField
              type={"Text"}
              className={styles["text-field"]}
              variant="outlined"
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
            />
          </div>
          <div className={styles["next-module-action"]}>
            <Button
              onClick={() => {
                togglePopUp(false);
                setUpdateMode(false);
              }}
              className={styles["cancel-btn"]}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setPage("page2");
              }}
              disabled={isModuleBtnDisabled()}
              classes={{
                root: `${styles["next-btn"]} ${
                  isModuleBtnDisabled() ? styles["disabled"] : ""
                }`,
              }}
            >
              Next
            </Button>
          </div>
        </>
      )}
      {page === "page2" && (
        <div className={styles["weightage"]}>
          <div className={styles["weightage-header"]}>
            Assign Credit to Section
          </div>
          <FormControl variant="outlined" className={styles["form-control"]}>
            <TextField
              type={"Number"}
              className={styles["text-field"]}
              variant="outlined"
              inputProps={{
                min: 0,
                style: { textAlign: "center" },
              }}
              value={sectionWeights}
              onChange={(e) => {
                setEditing(true);
                setSectionWeights(e.target.value);
              }}
            />
          </FormControl>
          <div className={styles["credits-left"]}>
            Credits Left:{getCreditLeft()}
          </div>
          {sectionType === "Questionnaire" && (
            <div className={styles["due-date-section"]}>
              <div className={styles["option-picker"]}>
                Set due date?
                <Checkbox
                  sx={{
                    color: "#0e1555",
                    "&.Mui-checked": {
                      color: " #0e1555",
                    },
                  }}
                  checked={dueDate}
                  onChange={handleDueDateChecked}
                />
              </div>
              {dueDate && (
                <div className={styles["date-picker"]}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={endDate}
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      onChange={handleEndDate}
                      renderInput={(params) => (
                        <>
                          <TextField
                            classes={{
                              root: styles["date-picker-comp"],
                              notchedOutline: styles["notched-override"],
                            }}
                            {...params}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {!isValidDate(endDate) ? "Invalid due date" : ""}
                          </FormHelperText>
                        </>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>
          )}
          <div className={styles["next-module-action"]}>
            <Button
              onClick={() => {
                setPage("page1");
              }}
              className={styles["cancel-btn"]}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                sectionType === "Content"
                  ? createContentSection()
                  : createQuestionnaireSection();
                togglePopUp(false);
              }}
              classes={{
                root: `${styles["next-btn"]} ${
                  isSaveButtonDisabled() ? styles["disabled"] : ""
                }`,
              }}
              disabled={isSaveButtonDisabled()}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ modules }) => ({
  questionnaires: modules.questionnaires,
  courseContent: modules.sections,
  moduleOptions: modules.moduleOptions,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleQuestionContent: (moduleId) =>
      dispatch(getModuleQuestionContent(moduleId)),
    addModuleQuestionSection: (moduleId, title, weightage, dueDate) =>
      dispatch(addModuleQuestionSection(moduleId, title, weightage, dueDate)),
    addModuleSection: (section, moduleId) =>
      dispatch(addModuleSection(section, moduleId)),
    updateModuleQuestionSection: (
      questionnaireId,
      title,
      questions,
      weightage,
      questionnaireTotalWeightage,
      dueDate
    ) =>
      dispatch(
        updateModuleQuestionSection(
          questionnaireId,
          title,
          questions,
          weightage,
          questionnaireTotalWeightage,
          dueDate
        )
      ),
    getModuleContent: (moduleId) => dispatch(getModuleContent(moduleId)),
    updateModuleSection: (section, moduleId) =>
      dispatch(updateModuleSection(section, moduleId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModuleSectionCreationForm);
