import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import ProfileBanner from "../StudentProfile/ProfileBanner";
import StudentPersonalDetails from "./StudentPersonalDetails";
import StudentSkills from "./StudentSkills";
import StudentProfessionalDetails from "./StudentProfessionalDetails";
import StudentEducationDetails from "./StudentEducationDetails";
import { logPageView } from "../../../../Utils/analytics";
import { connect } from "react-redux";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import { getLearnerProfileDetails } from "../../../../Store/Actions/actions.user";
import StudentPrompts from "./StudentPrompts";
import { Button } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-hot-toast";
import StudentCertificates from "./StudentCertificates";
import StudentBadges from "./StudentBadges";

function StudentProfile(props) {
  const location = useLocation();
  const hiddenProfileRef = useRef(null);
  const [preview, setPreview] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    logPageView("[Student] Profile Page");
  }, [location]);

  useEffect(() => {
    props.getLearnerProfileDetails(props.userUId);
  }, []);

  const exportToPDF = async () => {
    const input = hiddenProfileRef.current;
    await setPreview(true);
    await html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 10;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      const blob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, "_blank");
    });
    setPreview(false);
  };
  const copyTexttoClickBoard = async () => {
    await navigator.clipboard.writeText(
      `${window.location.href}/${props.userUId}`
    );
    toast.success("Copied link to clipboard");
  };
  return (
    <div ref={hiddenProfileRef}>
      <Box className={styles["profile-page"]}>
        <ProfileBanner preview={preview} />
        {!preview && (
          <div className={styles["export-section"]}>
            <Button
              data-event-name="[Student] Clicked On Export Profile"
              className={styles["export-button"]}
              onClick={handleClick}
            >
              <IosShareIcon /> Export Profile
            </Button>
          </div>
        )}
        <Box className={styles["student-profile-body"]}>
          <StudentPrompts preview={preview} />
          <StudentPersonalDetails preview={preview} />
          <StudentSkills preview={preview} />
          <StudentCertificates />
          <StudentBadges />
          <StudentProfessionalDetails preview={preview} />
          <StudentEducationDetails preview={preview} />
        </Box>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          data-event-name="[Student] Clicked On Generate Shareable Profile Link"
          onClick={() => {
            handleClose();
            copyTexttoClickBoard();
          }}
        >
          Create Shareable Link
        </MenuItem>
        <MenuItem
          data-event-name="[Student] Clicked On Export Profile As PDF"
          onClick={() => {
            handleClose();
            exportToPDF();
          }}
        >
          Export to PDF
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  userUId: user.userUid,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLearnerProfileDetails: (userUId) =>
      dispatch(getLearnerProfileDetails(userUId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfile);
