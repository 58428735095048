import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import { Box } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Button, OutlinedInput } from "@mui/material";
import {
  addPrompts,
  updatePrompt,
} from "../../../../../Store/Actions/actions.user";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
export const PromptModal = (props) => {
  const { promptFor, promptToBeEdited, editMode } = props;
  const [prompt, setPrompt] = useState("");
  const [answer, setAnswer] = useState("");
  const [promptsList, setAllPromptsList] = useState([]);
  useEffect(() => {
    setAllPromptsList(props.allPrompts);
  }, [props.allPrompts]);
  const isAnswerinValid = () => {
    return answer?.length >= 500 || answer?.length === 0;
  };
  useEffect(() => {
    if (promptToBeEdited && editMode) {
      setPrompt(promptToBeEdited?.prompt);
      setAnswer(promptToBeEdited?.promptReply);
    }
  }, []);
  const handleSubmit = async () => {
    if (editMode) {
      await props.updatePrompt(
        promptToBeEdited._id,
        prompt,
        promptFor,
        answer,
        true,
        props.userUID,
        props.userId
      );
    } else {
      await props.addPrompts(
        prompt,
        promptFor,
        answer,
        true,
        props.userUID,
        props.userId
      );
    }

    props.setOpen(false);
  };

  return (
    <Box className={styles["prompt-form"]}>
      <Box className={styles["heading-section"]}>Add prompt</Box>
      <Box className={styles["form-section"]}>
        <div className={styles["label"]}>Prompt</div>
        <Select
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          sx={{ width: "95%" }}
        >
          {promptsList
            .filter((item) => item.promptType === promptFor)
            .map((item, key) => {
              return (
                <MenuItem
                  value={item.prompt}
                  key={key}
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {item.prompt}
                </MenuItem>
              );
            })}
        </Select>
        <div className={styles["label"]}>Answer</div>
        <OutlinedInput
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ minHeight: 200 }}
          inputProps={{
            maxLength: 500,
          }}
          className={`${
            !isAnswerinValid()
              ? styles["prompt-answer"]
              : styles["error-prompt-answer"]
          }`}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          multiline
          endAdornment={
            <InputAdornment position="end" className={styles["char-length"]}>
              <Typography variant="caption">
                {answer?.length}/{500}
              </Typography>
            </InputAdornment>
          }
        />
        <div className={styles["button-wrapper"]}>
          <Button
            className={styles["cancel-button"]}
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            className={`${styles["submit-button"]} ${
              isAnswerinValid() || prompt === "" ? styles["disbaled"] : ""
            }`}
            disabled={isAnswerinValid() || prompt === ""}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ user }) => ({
  userUID: user.userUid,
  userId: user.userDetails.id,
  allPrompts: user.allPrompts,
});

const mapDispatchToProps = (dispatch) => ({
  addPrompts: (prompt, promptType, promptReply, isActive, userUID, userId) =>
    dispatch(
      addPrompts(prompt, promptType, promptReply, isActive, userUID, userId)
    ),
  updatePrompt: (
    promptId,
    prompt,
    promptType,
    promptReply,
    isActive,
    userUID,
    userId
  ) =>
    dispatch(
      updatePrompt(
        promptId,
        prompt,
        promptType,
        promptReply,
        isActive,
        userUID,
        userId
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptModal);
