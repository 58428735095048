import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { getSingleLiveSession } from "../../../Store/Actions/actions.cohort";
import { StyledArrowBackIcon } from "../../../Components/StyledComponents/Icons.style";
import styles from "../../../Stylesheets/Modules/Admin/LiveSession/LiveSessionDisplay.module.scss";
import Container from "@mui/material/Container";
import EditorWrapper from "../../../Components/EditorWrapper";
import { format } from "date-fns";
import { logPageView } from "../../../Utils/analytics";
import { getAllModules } from "../../../Store/Actions/actions.modules";
import Tooltip from "@mui/material/Tooltip";
export const LiveSessionDisplay = (props) => {
  let history = useNavigate();
  const [searchParams] = useSearchParams();
  const moduleId = searchParams.get("moduleId");
  const { themeInfo, singleLiveSessionDetail } = props;
  const { liveSessionId, cohortId } = useParams();
  const [liveSessionDetail, setLiveSessionDetail] = useState([]);
  const [options, setOptions] = useState([]);
  const [slots, setSlots] = useState([]);
  const [firstEffectComplete, setFirstEffectComplete] = useState(false);

  const getLiveSession = async (cohortId, liveSessionId) => {
    await props.getSingleLiveSession(cohortId, liveSessionId);
  };

  const getAllModulesData = async (cohortId) => {
    await props.getAllModules(cohortId);
  };

  useEffect(() => {
    getLiveSession(cohortId, liveSessionId);
    getAllModulesData(cohortId);
    setFirstEffectComplete(true);
  }, [cohortId, liveSessionId, firstEffectComplete]);

  useEffect(() => {
    setOptions(
      props.allModules.map((item) => {
        return { name: item.name, id: item._id };
      })
    );
  }, [props.allModules]);

  useEffect(() => {
    setLiveSessionDetail(singleLiveSessionDetail);
  }, [singleLiveSessionDetail]);

  useEffect(() => {
    if (
      moduleId &&
      singleLiveSessionDetail.dateSlot &&
      singleLiveSessionDetail.dateSlot.length > 0
    ) {
      const module = options.find((option) => option.id === moduleId)?.name;
      const filteredDateSlot = singleLiveSessionDetail.dateSlot.filter(
        (item) => {
          if (item.moduleId === moduleId) {
            return item;
          }
        }
      )[0];
      setSlots([
        {
          startDate: filteredDateSlot?.start
            ? format(new Date(new Date(filteredDateSlot.start)), "dd/MM/yyyy")
            : "",
          startTime: filteredDateSlot?.start
            ? format(new Date(new Date(filteredDateSlot.start)), "h:mm aa")
            : "",
          endDate: filteredDateSlot?.end
            ? format(new Date(new Date(filteredDateSlot.end)), "dd/MM/yyyy")
            : "",
          endTime: filteredDateSlot?.end
            ? format(new Date(new Date(filteredDateSlot.end)), "h:mm aa")
            : "",
          module: module,
        },
      ]);
    } else if (
      singleLiveSessionDetail.dateSlot &&
      singleLiveSessionDetail.dateSlot.length > 0
    ) {
      const slots = singleLiveSessionDetail.dateSlot.map((item) => {
        return {
          startDate: format(new Date(new Date(item.start)), "dd/MM/yyyy"),
          startTime: format(new Date(new Date(item.start)), "h:mm aa"),
          endDate: format(new Date(new Date(item.end)), "dd/MM/yyyy"),
          endTime: format(new Date(new Date(item.end)), "h:mm aa"),
          module: options.find((option) => option.id === item.moduleId)?.name,
        };
      });
      setSlots(slots);
    } else {
      setSlots([]);
    }
  }, [singleLiveSessionDetail, moduleId, options]);

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Live Session Display Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div
          className={styles["create-session-header"]}
          style={{ color: themeInfo[0]?.secondarycolor }}
        >
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() => {
              history(-1);
            }}
          />
          {liveSessionDetail.topic}
        </div>
        <div className={styles["information-wrapper"]}>
          <div className={styles["information-box"]}>
            <div className={styles["left-side-wrapper"]}>
              <div className={styles["speaker-wrapper"]}>
                <img src={liveSessionDetail.photoUrl} alt="usericon" />
                <div className={styles["speaker-name"]}>
                  {liveSessionDetail.speakerName}
                </div>
              </div>
              <div className={styles["event-detail-wrapper"]}>
                {/* <div className={styles["row-wrapper"]}>
                  <div className={styles["left-label"]}>Start Date</div>
                  <div className={styles["right-value"]}>
                    {liveSessionDetail.dateSlot &&
                      format(
                        new Date(singleLiveSessionDetail.dateSlot[0].start),
                        "MMM do, yyyy"
                      )}
                  </div>
                </div>
                <div className={styles["row-wrapper"]}>
                  <div className={styles["left-label"]}>End Date</div>
                  <div className={styles["right-value"]}>
                    {liveSessionDetail.dateSlot &&
                      format(
                        new Date(singleLiveSessionDetail.dateSlot[0].end),
                        "MMM do, yyyy"
                      )}
                  </div>
                </div> */}
                <div className={styles["row-wrapper"]}>
                  <div className={styles["left-label"]}>Created By</div>
                  <div className={styles["right-value"]}>
                    {liveSessionDetail.createdBy || liveSessionDetail.updatedBy}
                  </div>
                </div>
                <div className={styles["row-wrapper"]}>
                  <div className={styles["left-label"]}>Event Link</div>
                  <div
                    className={styles["link-value"]}
                    onClick={() =>
                      window.open(
                        singleLiveSessionDetail.googleMeetLink,
                        "_blank"
                      )
                    }
                  >
                    {singleLiveSessionDetail.googleMeetLink}
                  </div>
                </div>
                <div className={styles["row-wrapper"]}>
                  <div className={styles["live-session-slot-wrapper"]}>
                    <div className={styles["heading"]}>Session Time Slots</div>
                    <div className={styles["slot-wrapper"]}>
                      {slots && slots.length > 0 ? (
                        slots.map((item) => {
                          return (
                            <>
                              <div
                                className={styles["slot-box-header"]}
                                key={item.start}
                              >
                                <div className={styles["slot-box-header-left"]}>
                                  <Tooltip title={item.module}>
                                    <div
                                      className={
                                        styles["slot-box-header-left-module"]
                                      }
                                    >
                                      {item.module}
                                    </div>
                                  </Tooltip>
                                </div>
                                {item.startDate && item.endDate && (
                                  <div
                                    className={styles["slot-box-header-right"]}
                                  >
                                    <div
                                      className={
                                        styles["slot-box-header-right-wrapper"]
                                      }
                                    >
                                      <div
                                        className={
                                          styles[
                                          "slot-box-header-right-wrapper-start-time"
                                          ]
                                        }
                                      >
                                        <span>{item.startDate}</span>
                                        <span>{item.startTime}</span>
                                      </div>
                                      -
                                      <div
                                        className={
                                          styles[
                                          "slot-box-header-right-wrapper-end-time"
                                          ]
                                        }
                                      >
                                        <span>{item.endDate}</span>
                                        <span>{item.endTime}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div className={styles["no-slot-selected"]}>
                          {" "}
                          No Time Slots Selected{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["divider"]} />
            <div className={styles["right-side-info"]}>
              <div className={styles["speaker-wrapper"]}>
                <div className={styles["heading"]}>About Speaker</div>
                <div className={styles["content"]}>
                  {singleLiveSessionDetail.speaker && (
                    <EditorWrapper
                      childRoot="display-event-details-content-wrapper"
                      readOnly={true}
                      content={singleLiveSessionDetail.speaker}
                    />
                  )}
                </div>
              </div>
              <div className={styles["event-wrapper"]}>
                <div className={styles["heading"]}>Event Overview</div>
                <div className={styles["content"]}>
                  {singleLiveSessionDetail.eventOverview && (
                    <EditorWrapper
                      childRoot="display-event-details-content-wrapper"
                      readOnly={true}
                      content={singleLiveSessionDetail.eventOverview}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = ({ app, user, cohort, modules }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    customization: user.customization,
    liveSessions: cohort.liveSessionDetails,
    singleLiveSessionDetail: cohort.singleLiveSessionDetail,
    allModules: modules.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleLiveSession: (cohortId, liveSessionId) =>
      dispatch(getSingleLiveSession(cohortId, liveSessionId)),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveSessionDisplay);
