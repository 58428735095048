
// Function to get query parameters from the URL
export const getQueryParams = () => {
    const search = window.location.search.substring(1);
    const params = new URLSearchParams(search);
    const referrerObj = JSON.parse(localStorage.getItem('referrerObj'));

    const user_id = params.get('user_id') || referrerObj?.userId;
    const unique_task_key = params.get('unique_task_key') || referrerObj?.uniqueTaskKey;
    const step_number = params.get('step_number') || referrerObj?.stepNumber;

    return { user_id, unique_task_key, step_number };
};

export const findOptimalQuality = (blob, lower = 0.1, upper = 1.0, tolerance = 1000) => {
    const MAX_SIZE = 100 * 1024; // 100KB
    // let mid = (lower + upper) / 2;

    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            const testQuality = (quality) => {
                return new Promise((res) => {
                    canvas.toBlob((newBlob) => {
                        res(newBlob.size);
                    }, 'image/jpeg', quality);
                });
            };

            const binarySearch = async (low, high) => {
                if (high - low < tolerance) {
                    resolve(low);
                    return;
                }
                const mid = (low + high) / 2;
                const size = await testQuality(mid);
                if (size > MAX_SIZE) {
                    binarySearch(low, mid);
                } else {
                    binarySearch(mid, high);
                }
            };

            binarySearch(lower, upper);
        };
    });
};
