import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";

import styles from "../../../Stylesheets/Modules/Admin/Cohorts/cohortMenu.module.scss";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { StyledArrowBackIcon } from "../../../Components/StyledComponents/Icons.style";
import { logPageView } from "../../../Utils/analytics";
import { CohortStats } from "../../../Store/Actions/actions.cohort";

const CohortMenu = (props) => {
  const history = useNavigate();
  const { cohortId } = useParams();
  const [searchParams] = useSearchParams();
  const { CohortStats, count } = props;

  const sections = [
    {
      key: "modules",
      title: `View ${props.customization.module_label || "Modules"}`,
      sub: 4,
      background: "linear-gradient(135deg, #FED992 0%, #EB5556 100%)",
      color: "#EB5556",
      route: `/admin/createModule/${cohortId}?cohort=${searchParams.get(
        "cohort"
      )}`,
      active: true,
    },
    {
      key: "students",
      title: "View Students",
      sub: 4,
      background: "linear-gradient(135deg, #5BEFDF 0.55%, #19A784 97.3%)",
      color: "#19A784",
      route: `/admin/cohort/students/${cohortId}?cohort=${searchParams.get(
        "cohort"
      )}`,
      active: true,
    },
    {
      key: "onboarding",
      title: "Onboarding",
      background: "linear-gradient(135deg, #FB6C79 0%, #95222C 100%)",
      color: "#95222C",
      route: `/admin/cohort/${cohortId}/onboarding?cohort=${searchParams.get(
        "cohort"
      )}`,
      active: true,
    },
    {
      key: "application_process",
      title: "Applicants",
      background: "linear-gradient(134.72deg, #00EAF8 0%, #6D42EF 101.5%)",
      color: "#6D42EF",
      route: `/admin/cohort/${cohortId}/applications?cohort=${searchParams.get(
        "cohort"
      )}`,
      active: true,
    },
    {
      key: "live_sessions",
      title: "Live Sessions",
      background: "linear-gradient(135deg, #FB6C79 0%, #95222C 100%)",
      color: "#95222C",
      route: `/admin/cohort/${cohortId}/sessions?cohort=${searchParams.get(
        "cohort"
      )}`,
      active: true,
    },
    {
      key: "discussion_forum",
      title: "Discussion Forum",
      background: "linear-gradient(135deg, #525C79 0%, #1B2850 100%)",
      color: "#1B2850",
    },
  ];

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Cohort Menu Page");
  }, [location]);

  useEffect(() => {
    CohortStats(cohortId);
  }, []);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div className={styles["cohort-menu-header"]}>
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() => history("/admin/cohorts")}
          />{" "}
          {searchParams.get("cohort") || "Cohort"}
        </div>
        <div className={styles["cohort-section-container"]}>
          {sections?.map((sec) => {
            return (
              <div
                key={sec.key}
                className={`${styles["cohort-section"]} ${
                  sec.active ? styles["active"] : ""
                }`}
                style={{ background: sec.background }}
                onClick={() => sec.route && history(sec.route)}
              >
                <span className={styles["section-name"]}>
                  {sec.title}
                  {count?.[sec.key] > 0 && (
                    <Badge
                      className={styles["section-badge"]}
                      color="error"
                      badgeContent={count[sec.key]}
                    />
                  )}
                </span>
                <ArrowForward
                  classes={{
                    root: styles["arrow-icon"],
                  }}
                  style={{
                    color: sec.color,
                  }}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = ({ app, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    customization: user.customization,
    count: cohort.cohortStats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    CohortStats: (cohortId) => dispatch(CohortStats(cohortId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CohortMenu);
