import { useState } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import ProfileModal from "./modals/ProfileModal";

import EditIcon from "../../../../Assets/Images/editIcon.jpg";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";

const headerMapping = {
  City: "City of Residence",
};

function StudentPersonalDetails({ name, mobileNo, profileDetails, themeInfo, preview }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const openForWork = profileDetails?.openForWork || false;

  const dataObj = {
    City: profileDetails?.city,
    Bio: profileDetails?.bio,
  };

  return (
    <Box className={styles["personal-details-box"]}>
      <ProfileModal
        modalName="EditPersonalDetails"
        open={open}
        handleClose={handleClose}
      />

      <Box className={styles["personal-details-header"]}>
        <Box
          className={`${styles["profile-header"]} ${styles["profile-header-first"]}`}
          style={{
            background:
              themeInfo[0]?.primarycolor !== "#0e1555"
                ? themeInfo[0]?.primarycolor
                : "",
            fontFamily: themeInfo[0]?.fontfamily,
          }}
        >
          <Box className={styles["profile-header-name"]}>Personal Details</Box>
        </Box>
        {!preview && <Box className={styles["personal-edit"]} onClick={() => setOpen(true)}>
          {" "}
          <img src={EditIcon} alt="edit" />{" "}
        </Box>}
      </Box>

      <Box className={styles["details-box-wrapper"]}>
        <Box className={styles["details-box-name"]}>
          <Box className={styles["content-heading-name"]}>Name</Box>
          <Box className={styles["content-data-name"]}> {name} </Box>
        </Box>
        <Box className={styles["details-box-name"]}>
          <Box className={styles["content-heading-name"]}>Mobile No</Box>
          <Box className={styles["content-data-name"]}> {mobileNo} </Box>
        </Box>
        <Box className={styles["details-box-name"]}>
          <Box className={styles["content-heading-name"]}>Country</Box>
          <Box className={styles["content-data-name"]}>
            {profileDetails?.country}
          </Box>
        </Box>
      </Box>
      {Object.keys(dataObj).map((key, index) => (
        <Box className={styles["details-box"]} key={index}>
          <Box className={styles["content-heading"]}>
            {headerMapping[key] ? headerMapping[key] : key}
          </Box>
          <Box className={styles["content-data"]}>
            {dataObj[key]}
          </Box>
        </Box>
      ))}

      <Box className={styles["details-box"]}>
        <Box className={styles["content-heading"]}>Are you open for work? </Box>
        <Box className={styles["content-data"]}>
          <Checkbox
            className={styles["profile-checkbox"]}
            checked={openForWork}
            value={openForWork}
          />
          Yes I’m currently seeking employment
        </Box>
      </Box>
      {profileDetails?.socialLinks?.map((item, key) => {
        return (
          item?.url !== "" && <Box className={styles["details-box"]} key={key}>
            <Box className={styles["content-heading"]}>{item?.typeOfLink} </Box>
            <Box className={styles["content-data"]}>
              {item?.url}
            </Box>
          </Box>
        )
      })}
    </Box>
  );
}

const mapStateToProps = ({ user }) => {
  const fullName = user.userDetails.firstName.concat(
    " ",
    user.userDetails.lastName
  );
  const mobileNo = user.userDetails.mobileNo;
  return {
    name: fullName,
    mobileNo: mobileNo,
    profileDetails: user.learnerProfileDetails.profileDetails,
    themeInfo: user.themeInfo,
  };
};

export default connect(mapStateToProps)(StudentPersonalDetails);
