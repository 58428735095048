import toast from "react-hot-toast";
import API from "../../Helpers/axiosInit";
import { getQueryParams } from "../../Helpers/utils";
import { setAppLoading } from "./actions.app";
import { handleActionCompletionASA } from "../../Helpers/app";

export const setAllModules = (allModules) => {
  return {
    type: "SET_ALL_MODULES",
    payload: {
      allModules,
    },
  };
};
export const setModuleInfo = (moduleInfo) => {
  return {
    type: "SET_MODULES_INFO",
    payload: {
      moduleInfo,
    },
  };
};

export const setModuleSkillsList = (moduleSkills) => {
  return {
    type: "SET_MODULE_SKILLS_LIST",
    payload: moduleSkills
  }
}

export const getAllModules = (cohortId) => async (dispatch) => {
  dispatch(setAllModules([]));
  dispatch(setAppLoading(true, "getAllModules - true"));
  try {
    const res = await API.get(`/modules/${cohortId}`);
    const { data: resData } = res;
    dispatch(setAllModules(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getAllModules - false"));
  }
};

export const createNewModule = (moduleData) => async (dispatch) => {
  dispatch(setAppLoading(true, "createModule - true"));
  try {
    await API.post(`/modules`, {
      ...moduleData,
    });
    dispatch(getAllModules(moduleData.cohortId));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "createModule - false"));
  }
};

export const deleteModule = (moduleId, cohortId) => async (dispatch) => {
  dispatch(setAppLoading(true, "deleteModule - true"));
  try {
    const result = await API.delete(`/modules/${moduleId}`, {
      data: { cohortId },
    });
    if (result) {
      toast("Deleted Successfully");
      dispatch(getAllModules(cohortId));
    }
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "deleteModule - false"));
  }
};
export const updateModules =
  (moduleData, moduleId, cohortId) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateModules - true"));
    try {
      const result = await API.delete(`/modules/${moduleId}`, {
        data: { cohortId },
      });
      if (result) {
        dispatch(getAllModules(cohortId));
        dispatch(createNewModule(moduleData));
        dispatch(getAllModules(cohortId));
      }
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "updateModules - false"));
    }
  };

/**
 *
 * @param {*} moduleData - Complete modules data with new order
 * @param {*} cohortId - current selected cohort ID
 * @returns
 */
export const updateModulesOrder =
  (moduleData, cohortId) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateModulesOrder - true"));
    try {
      const res = await API.put(`/modules/${cohortId}/updateModule`, {
        moduleData: moduleData,
      });
      if (res.status === 200) toast.success("Updated order successfully");
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "updateModulesOrder - false"));
    }
  };

export const deletesection = (modulenumber, sectionid) => async (dispatch) => {
  dispatch(setAppLoading(true, "deletesection - true"));
  try {
    const result = await API.delete(
      `/modules/${modulenumber}/sections/${sectionid}`
    );
    if (result) {
      toast("Deleted Successfully");
      dispatch(getModuleContent(modulenumber));
    } else toast("Could not delete");
  } catch {
  } finally {
    dispatch(setAppLoading(false, "deletesection - false"));
  }
};

export const updateSectionsOrder =
  (sectionData, modulenumber, cohortId) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateSectionsOrder - true"));
    try {
      const res = await API.put(
        `/modules/${cohortId}/${modulenumber}/updateSectionsOrder`,
        {
          sectionData: sectionData,
        }
      );
      if (res.status === 200) toast.success("Changed ordered successfully");
    } catch {
    } finally {
      dispatch(setAppLoading(false, "updateSectionsOrder - false"));
    }
  };

export const deletequestionaire =
  (moduleId, questionnaireId) => async (dispatch) => {
    dispatch(setAppLoading(true, "deletequestionaire - true"));
    try {
      let result = await API.delete(
        `/modules/${moduleId}/questionnaires/${questionnaireId}`
      );
      if (result) {
        toast("Deleted Successfully");
        dispatch(getModuleQuestionContent(moduleId));
      } else toast("Could not delete");
    } catch {
    } finally {
      dispatch(setAppLoading(false, "deletequestionaire - false"));
    }
  };
export const updateQuestionaireSections =
  (moduleId, questionnaireId, title, questionnaireTotalWeightage) =>
    async (dispatch) => {
      dispatch(setAppLoading(true, "updateQuestionaireSections - true"));
      try {
        let result = await API.delete(
          `/modules/${moduleId}/questionnaires/${questionnaireId}`
        );
        if (result) {
          dispatch(getModuleQuestionContent(moduleId));
          dispatch(
            addModuleQuestionSection(moduleId, title, questionnaireTotalWeightage)
          );
          dispatch(getModuleQuestionContent(moduleId));
        }
      } catch {
      } finally {
        dispatch(setAppLoading(false, "updateQuestionaireSections - false"));
      }
    };
export const updateModuleDetails =
  (moduleId, moduleData) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateModuleDetails - true"));
    try {
      await API.put(`/modules/${moduleId}`, {
        ...moduleData,
      });
      dispatch(getAllModules(moduleData.cohortId));
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "updateModuleDetails - false"));
    }
  };

export const updataModuleStatus =
  (moduleId, status, cohortId) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateModuleStatus - true"));
    try {
      const res = await API.put(`/modules/${moduleId}/publish`, {
        status,
      });
      const { data: resData } = res;
      dispatch(updateModule(resData));
      dispatch(getAllModules(cohortId));
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "updateModuleStatus - false"));
    }
  };

export const updateModule = (module) => async (dispatch, getState) => {
  let modules = getState().modules.modules;
  modules = modules && new Map(modules.map((m) => [m._id, m]));
  modules.set(module._id, module);
  modules = [...modules.values()];
  dispatch(setAllModules(modules));
};

export const getModuleContent = (moduleId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getModuleContent - true"));
  try {
    const res = await API.get(`modules/${moduleId}/courseContent`);
    const { data: resData } = res;
    dispatch(setModuleContent(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getModuleContent - false"));
  }
};

export const getModuleCourseContent =
  (cohortId, studentId, moduleId) => async (dispatch) => {
    dispatch(setAppLoading(true, "getModuleCourseContent - true"));
    try {
      const res = await API.get(
        `students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/courseContent`
      );
      const { data: resData } = res;
      dispatch(setModuleContent(resData));
      return resData;
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
        window.location.href = "/";
      }
    } finally {
      dispatch(setAppLoading(false, "getModuleCourseContent - false"));
    }
  };

export const setModuleContent = (content) => {
  return {
    type: "SET_MODULE_CONTENT",
    payload: {
      sections: content,
    },
  };
};
export const setModuleOptions = (content) => {
  return {
    type: "SET_MODULE_OPTIONS",
    payload: {
      moduleOptions: content.moduleOptions,
    },
  };
};

export const addModuleSection = (section, moduleId) => async (dispatch) => {
  dispatch(setAppLoading(true, "addModuleSection - true"));
  try {
    const res = await API.put(`/modules/${moduleId}/sections`, section);
    const { data: resData } = res;
    if (resData?.courseContent?.sections) {
      dispatch(setModuleContent(resData?.courseContent));
    }
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "addModuleSection - false"));
  }
};

export const updateModuleSection = (section, moduleId) => async (dispatch) => {
  dispatch(setAppLoading(true, "addModuleSection - true"));
  try {
    const res = await API.put(
      `/modules/${moduleId}/sections/${section.sectionId}`,
      {
        section,
      }
    );
    const { data: resData } = res;
    toast.success(`Successfully updated ${section.title} data 🎉`);
    if (resData?.courseContent?.sections) {
      dispatch(setModuleContent(resData?.courseContent));
    }
    dispatch(getModuleContent(moduleId));
  } catch (err) {
    toast.error(`Failed to update ${section.title} data`);
  } finally {
    dispatch(setAppLoading(false, "addModuleSection - false"));
  }
};
// Module questionaire flow

export const setModuleQuestionContent = (content) => {
  return {
    type: "SET_MODULE_QUESTION_CONTENT",
    payload: {
      questionnaires: content,
    },
  };
};

export const getModuleQuestionContent = (moduleId) => async (dispatch) => {
  dispatch(setModuleQuestionContent([]));
  dispatch(setAppLoading(true, "getModuleQuestionContent - true"));
  try {
    const res = await API.get(`modules/${moduleId}/questionnaires`);
    const { data: resData } = res;
    dispatch(setModuleQuestionContent(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getModuleQuestionContent - false"));
  }
};

/**
 * @actionName getStudentModuleQuestionContent
 *
 * @param {studentId} studentId
 * @param {cohortId} cohortId
 * @param {moduleId} moduleId
 * @returns response
 */
export const getStudentModuleQuestionContent =
  (studentId, cohortId, moduleId) => async (dispatch) => {
    dispatch(setAppLoading(true, "getStudentModuleQuestionContent - true"));
    try {
      const res = await API.get(
        `students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/questionaires`
      );
      const { data: resData } = res;
      dispatch(setModuleQuestionContent(resData));
      return resData;
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
        window.location.href = "/";
      }
    } finally {
      dispatch(setAppLoading(false, "getStudentModuleQuestionContent - false"));
    }
  };

export const addModuleQuestionSection =
  (moduleId, title, weightage, dueDate) => async (dispatch) => {
    dispatch(setAppLoading(true, "addModuleQuestionSection - true"));
    try {
      const res = await API.post(`questionnaires/module`, {
        moduleId,
        title,
        questionnaireTotalWeightage: weightage,
        weightage: 100,
        dueDate,
      });
      const { data: resData } = res;
      dispatch(setModuleQuestionContent(resData));
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "addModuleQuestionSection - false"));
    }
  };

export const updateModuleQuestionSection =
  (
    questionnaireId,
    title,
    questions,
    weightage,
    questionnaireTotalWeightage,
    dueDate
  ) =>
    async (dispatch) => {
      dispatch(setAppLoading(true, "updateModuleQuestionSection - true"));
      try {
        const res = await API.put(`/questionnaires/${questionnaireId}`, {
          title,
          questions,
          weightage,
          questionnaireTotalWeightage,
          dueDate,
        });
        const { data: resData } = res;
        dispatch(setModuleQuestionContent(resData));
      } catch (err) {
      } finally {
        dispatch(setAppLoading(false, "updateModuleQuestionSection - false"));
      }
    };

export const updateModuleQuestionsOrder =
  (moduleId, questionsData) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateModuleQuestionsOrder - true"));
    try {
      const res = await API.put(
        `/modules/${moduleId}/updateQuestionsOrder`,
        questionsData
      );
      if (res.status === 200) toast.success("Changed order successfully");
      dispatch(getModuleQuestionContent(moduleId));
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "updateModuleQuestionsOrder - false"));
    }
  };

export const markCourseContentSectionAsComplete =
  (cohortId, studentId, moduleId, sectionId, weightage) => async (dispatch) => {
    dispatch(setAppLoading(true, "markCourseContentSectionAsComplete - true"));
    try {
      const { user_id, unique_task_key, step_number } = getQueryParams();
      const queryParams = `?user_id=${user_id}&unique_task_key=${unique_task_key}&step_number=${step_number}`;
      let url = `student/progress/section/complete`;
      if (user_id || unique_task_key || step_number) {
        url = `student/progress/section/complete${queryParams}`;
      }
      await API.put(url, {
        cohortId: cohortId,
        studentId: studentId,
        moduleId: moduleId,
        sectionId: sectionId,
        weightage: weightage,
      });
      handleActionCompletionASA();
    } catch (err) {
    } finally {
      dispatch(
        setAppLoading(false, "markCourseContentSectionAsComplete - false")
      );
    }
  };

export const submitQuestionnaire =
  (cohortId, studentId, moduleId, questionnaireId, payload, update = false) =>
    async (dispatch) => {
      dispatch(setAppLoading(true, "submitQuestionnaire - true"));
      const { user_id, unique_task_key, step_number } = getQueryParams();
      const queryParams = `?user_id=${user_id}&unique_task_key=${unique_task_key}&step_number=${step_number}`;
      try {
        if (!update) {
          let url = `/students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/questionaires/${questionnaireId}`
          if (user_id || unique_task_key || step_number) {
            url = `/students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/questionaires/${questionnaireId}${queryParams}`
          }
          await API.post(url, { questions: payload });
        } else {
          let url = `/students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/questionaires/${questionnaireId}`;
          if (user_id || unique_task_key || step_number) {
            url = `/students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/questionaires/${questionnaireId}${queryParams}`
          }
          await API.put(url, { questions: payload });
        }
        handleActionCompletionASA();
      } catch (err) {
      } finally {
        dispatch(setAppLoading(false, "submitQuestionnaire - false"));
      }
    };

/**
 *
 * @param {*} cohortId
 * @param {*} studentId
 * @param {*} moduleId
 * @param {*} questionnaireId
 * @param {*} payload => {questionId, msg}
 * @returns
 */
export const createFeedback =
  (studentId, cohortId, moduleId, questionnaireId, payload) =>
    async (dispatch) => {
      dispatch(setAppLoading(true, "createFeedback - true"));
      try {
        const res = await API.post(
          `/students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/questionaires/${questionnaireId}/feedback`,
          {
            questionId: payload.questionId,
            msg: payload.msg,
          }
        );
        if (res.status === 200) toast.success("Posted feedback");
      } catch (err) {
      } finally {
        dispatch(setAppLoading(false, "createFeedback - false"));
      }
    };

/**
 *
 * @param {*} cohortId
 * @param {*} studentId
 * @param {*} moduleId
 * @param {*} questionnaireId
 * @param {*} questionIds list of question ids in a section
 * @returns
 */
export const requestResubmission =
  (studentId, cohortId, moduleId, questionnaireId, payload) =>
    async (dispatch) => {
      dispatch(setAppLoading(true, "requestResubmission - true"));
      try {
        await API.put(
          `/students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/questionaires/${questionnaireId}/update/status`,
          {
            questionsStatus: payload,
          }
        );
        return;
      } catch (err) {
      } finally {
        dispatch(setAppLoading(false, "requestResubmission - false"));
      }
    };

export const getModuleOptions = (moduleId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getModuleOptions-true"));
  try {
    const res = await API.get(`/modules/${moduleId}/moduleOptions`);
    const { data: resData } = res;
    dispatch(setModuleOptions(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getModuleOptions-false"));
  }
};

export const updateModuleOptions =
  (moduleId, name, description, cohortId, moduleOptions, skills) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateModuleOptions - true"));
    try {
      await API.put(`/modules/${moduleId}`, {
        cohortId: cohortId,
        name: name,
        description: description,
        moduleOptions,
        skills: skills
      });
      dispatch(getAllModules(cohortId));
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "update ModuleOptions - false"));
    }
  };

export const setTimeSpentOnContentAction = (data) => {
  return {
    type: "SET_TIMESPENT_ON_CONTENT",
    payload: { idx: data.sectionId, time: data.time },
  };
};

export const setTimeSpentOnContent = async ({
  cohortId,
  moduleId,
  time,
  sectionId,
  userId,
}) => {
  try {
    await API.put("/reporting/setTotalTimeSpentOnModule", {
      cohortId,
      moduleId,
      time,
      sectionId,
      userId,
    });
  } catch (err) {
    console.log("error while uploading time spent on content to db", err);
  }
};
export const getModuleLiveSessions =
  (cohortId, moduleId) => async (dispatch) => {
    dispatch(setAppLoading(true, "getModuleliveSessions - true"));
    try {
      const res = await API.get(
        `/liveSession/${cohortId}?moduleId=${moduleId}`
      );
      const { data: resData } = res;
      dispatch({
        type: "SET_MODULE_LIVE_SESSIONS",
        payload: {
          liveSessionDetails: resData,
        },
      });
    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
      dispatch(setAppLoading(false, "getModuleLiveSessions - false"));
    }
  };

export const getVideoCustomization =
  (cohortId, studentId, moduleId) => async () => {
    try {
      return await API.get(
        `videoWatchProgress/students/${studentId}/cohorts/${cohortId}/modules/${moduleId}/videoCustomization`
      );
    } catch (err) {
      console.error(`Failed to fetch video customization data`, err);
    }
  };

export const updateVideoStatus =
  (cohortId, studentId, moduleId, sectionId, url) => async () => {
    try {
      await API.post("videoWatchProgress/complete", {
        cohortId,
        studentId,
        moduleId,
        sectionId,
        url,
      });
    } catch (error) {
      console.error("failed to update the video watch status", error);
    }
  };

export const getModuleSkillsList = (search) => async (dispatch) => {
  try {
    const res = await API.get(`/skills?search=${search}`);
    const { data: resData } = res;
    dispatch(setModuleSkillsList(resData))
  } catch (err) {
  } finally {
  }
}
