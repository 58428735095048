import React, { useEffect, useState } from 'react'
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import Box from "@mui/material/Box";
import { getLearnerProfileDetails } from '../../../../Store/Actions/actions.user';
import { connect } from "react-redux";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Dialog from '@mui/material/Dialog';
import pdfIcon from "../../../../Assets/Images/pdf_179483.png"
import RemoveIcon from '@mui/icons-material/Remove';

function ShareableProfile(props) {
    const { userId } = useParams();
    const { learnerProfileDetails } = props;
    const [prompts, setPrompts] = useState();
    const [showMore, setShowMore] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [moreItem, setMoreItem] = useState();
    const [studentSkills, setStudentSkills] = useState([])
    const headerMapping = {
        City: "City of Residence",
    };

    useEffect(() => {
        props.getLearnerProfileDetails(userId);
    }, [])
    useEffect(() => {
        setPrompts(learnerProfileDetails.prompts)
    }, [learnerProfileDetails]);

    useEffect(() => {
        setStudentSkills(learnerProfileDetails?.profileDetails?.skills)
    }, [learnerProfileDetails])
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const onClose = () => {
        setDialogOpen(false);
    }
    const dataObj = {
        City: learnerProfileDetails?.profileDetails?.city,
        Bio: learnerProfileDetails?.profileDetails?.bio,
        Links: learnerProfileDetails?.profileDetails?.links,
    };
    console.log(learnerProfileDetails?.certificates)
    return (
        <Box className={styles["profile-page"]} >
            <div
                className={styles["student-profile-banner"]}
                style={{
                    background: ""
                }}
            >
                <Box className={styles["profile-banner-back"]}></Box>
                <Box className={styles["profile-banner-content"]}>
                    <Badge overlap="circular"
                        classes={{ badge: styles["badge-background"] }}
                        className={styles["profile-camera-badge"]}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                        <Avatar
                            alt="Profile Photo"
                            className={styles["profile-banner-photo"]}
                            src={learnerProfileDetails.profileImgUrl}
                            style={{
                                backgroundColor: "#0e1555",
                            }}
                        >{learnerProfileDetails.firstName?.[0]}</Avatar>
                    </Badge>
                    <Box className={styles["profile-banner-info"]}>
                        <Box className={styles["profile-banner-info-name"]}>
                            {learnerProfileDetails.firstName} {learnerProfileDetails.lastName}{" "}
                        </Box>
                    </Box>
                </Box>
            </div>
            <Box className={styles["student-profile-body"]}>

                <Box className={styles["personal-details-box"]}>
                    <Box
                        className={`${styles["profile-header"]}`}
                        style={{
                            background: "",
                            fontFamily: "",
                        }}
                    >
                        <Box className={styles['profile-header-name']}></Box>Things I care about</Box>

                    <Box className={styles['prompts-section']}>
                        {prompts?.filter(item => item.promptType === "THINGS_I_CARE_ABOUT" && item.isActive)?.map((item, key) => {
                            return (
                                <Box key={key} className={styles['prompt']}>
                                    <div className={styles['question']}>{item.prompt}</div>
                                    <div className={styles['answer']}>{item.promptReply}</div>
                                    {item.promptReply.length > 120 && (
                                        <div className={styles['view-more']} onClick={() => {
                                            toggleShowMore();
                                            setMoreItem(item);
                                            setDialogOpen(true);
                                        }}>
                                            {!showMore && 'View More'}
                                        </div>
                                    )}
                                    {item.skills && <div className={styles['skills-section']}>
                                        <div className={styles['label']}>Skills:</div>
                                        <div className={styles['skill-display-wrapper']}>
                                            {item.skills.map((skill, key) => {
                                                return (
                                                    <Box className={styles['skill']} key={key}>{skill}</Box>
                                                )
                                            })}
                                        </div>
                                    </div>}
                                </Box>
                            )
                        })}
                    </Box>
                    <Dialog open={dialogOpen} onClose={() => {
                        onClose()
                        toggleShowMore()
                    }}
                        PaperProps={{ style: { boxShadow: 'none', backgroundColor: 'transparent' } }}
                    >
                        <Box className={styles['prompt']}>
                            <div className={styles['question']}>{moreItem?.prompt}</div>
                            <div className={styles['answer-long']}>{moreItem?.promptReply}</div>
                            {moreItem?.skills && <div className={styles['skills-section']}>
                                <div className={styles['label']}>Skills:</div>
                                <div className={styles['skill-display-wrapper']}>
                                    {moreItem?.skills.map((skill, key) => {
                                        return (
                                            <Box className={styles['skill']} key={key}>{skill}</Box>
                                        )
                                    })}
                                </div>
                            </div>}
                        </Box>
                    </Dialog>

                </Box >
                <Box className={styles["personal-details-box"]}>
                    <Box
                        className={`${styles["profile-header"]}`}
                        style={{
                            background: "",
                            fontFamily: "",
                        }}
                    >
                        <Box className={styles['profile-header-name']}></Box>Things I care about</Box>

                    <Box className={styles['prompts-section']}>
                        {prompts?.filter(item => item.promptType === "HOW_TO_WORK_WITH_ME" && item.isActive)?.map((item, key) => {
                            return (
                                <Box key={key} className={styles['prompt']}>
                                    <div className={styles['question']}>{item.prompt}</div>
                                    <div className={styles['answer']}>{item.promptReply}</div>
                                    {item.promptReply.length > 120 && (
                                        <div className={styles['view-more']} onClick={() => {
                                            toggleShowMore();
                                            setMoreItem(item);
                                            setDialogOpen(true);
                                        }}>
                                            {!showMore && 'View More'}
                                        </div>
                                    )}
                                    {item.skills && <div className={styles['skills-section']}>
                                        <div className={styles['label']}>Skills:</div>
                                        <div className={styles['skill-display-wrapper']}>
                                            {item.skills.map((skill, key) => {
                                                return (
                                                    <Box className={styles['skill']} key={key}>{skill}</Box>
                                                )
                                            })}
                                        </div>
                                    </div>}
                                </Box>
                            )
                        })}
                    </Box>
                    <Dialog open={dialogOpen} onClose={() => {
                        onClose()
                        toggleShowMore()
                    }}
                        PaperProps={{ style: { boxShadow: 'none', backgroundColor: 'transparent' } }}
                    >
                        <Box className={styles['prompt']}>
                            <div className={styles['question']}>{moreItem?.prompt}</div>
                            <div className={styles['answer-long']}>{moreItem?.promptReply}</div>
                            {moreItem?.skills && <div className={styles['skills-section']}>
                                <div className={styles['label']}>Skills:</div>
                                <div className={styles['skill-display-wrapper']}>
                                    {moreItem?.skills.map((skill, key) => {
                                        return (
                                            <Box className={styles['skill']} key={key}>{skill}</Box>
                                        )
                                    })}
                                </div>
                            </div>}
                        </Box>
                    </Dialog>

                </Box >
                <Box className={styles["personal-details-box"]}>


                    <Box className={styles["personal-details-header"]}>
                        <Box
                            className={`${styles["profile-header"]} ${styles["profile-header-first"]}`}
                            style={{
                                background: "",
                                fontFamily: "",
                            }}
                        >
                            <Box className={styles["profile-header-name"]}>Personal Details</Box>
                        </Box>


                    </Box>
                </Box>

                <Box className={styles["details-box-wrapper"]}>
                    <Box className={styles["details-box-name"]}>
                        <Box className={styles["content-heading-name"]}>Name</Box>
                        <Box className={styles["content-data-name"]}> {learnerProfileDetails.firstName} </Box>
                    </Box>
                    <Box className={styles["details-box-name"]}>
                        <Box className={styles["content-heading-name"]}>Mobile No</Box>
                        <Box className={styles["content-data-name"]}> {learnerProfileDetails.mobileNo} </Box>
                    </Box>
                    <Box className={styles["details-box-name"]}>
                        <Box className={styles["content-heading-name"]}>Country</Box>
                        <Box className={styles["content-data-name"]}>
                            {learnerProfileDetails.profileDetails?.country}
                        </Box>
                    </Box>
                </Box>
                {Object.keys(dataObj).map((key, index) => (
                    <Box className={styles["details-box"]} key={index}>
                        <Box className={styles["content-heading"]}>
                            {headerMapping[key] ? headerMapping[key] : key}
                        </Box>

                        <Box className={styles["content-data"]}>
                            {key === "Links"
                                ? dataObj[key]?.map((link, index) => (
                                    <Box key={index}>{link}</Box>
                                ))
                                : dataObj[key]}
                        </Box>
                    </Box>
                ))}

                <Box className={styles["details-box"]}>
                    <Box className={styles["content-heading"]}>Are you open for work? </Box>
                    <Box className={styles["content-data"]}>
                        <Checkbox
                            className={styles["profile-checkbox"]}
                            checked={learnerProfileDetails.openForWork}
                            value={learnerProfileDetails.openForWork}
                        />
                        Yes I’m currently seeking employment
                    </Box>
                </Box>
                <Box className={styles["personal-details-box"]}>
                    <Box className={styles["personal-details-header"]}>
                        <Box
                            className={styles["profile-header"]}
                            style={{
                                background: "",
                                fontFamily: "",
                            }}
                        >
                            <Box className={styles["profile-header-name"]}>Skills</Box>
                        </Box>
                    </Box>

                    <Box className={styles["skills-content-box"]}>
                        {studentSkills?.map((skill, index) => (
                            <p className={styles["skills-content"]} key={index}>
                                {skill?.skill}
                            </p>
                        ))}
                    </Box>
                </Box>
                <Box className={styles["personal-details-box"]}>
                    <Box
                        className={`${styles["profile-header"]}`}
                    >
                        <Box className={styles['profile-header-name']}>Certificates</Box>
                    </Box>
                    <div className={styles['certificate-wrapper']}>
                        {learnerProfileDetails?.certificates?.map((item, idx) => {
                            return (
                                <div key={idx} className={styles['certificate']} onClick={() => {
                                    window.open(`${process.env.REACT_APP_API_ENDPOINT}/public/download/certificate/${item.path}?studentId=${studentId}`, '_blank');
                                }}>
                                    <img src={pdfIcon} alt="pdf" className={styles['pdf-image']} />
                                    {item.name}
                                </div>
                            )
                        })}
                    </div>
                </Box>
                <Box className={styles["personal-details-box"]}>
                    <Box
                        className={`${styles["profile-header"]}`}
                    >
                        <Box className={styles['profile-header-name']}>Badges</Box>
                    </Box>
                    <div className={styles['certificate-wrapper']}>
                        {learnerProfileDetails?.badges?.map((item, idx) => {
                            return (
                                <div key={idx} className={styles['badge-canvas']} >
                                    <img src={item.path} alt="badge" className={styles['badge-image']} key={idx} />
                                </div>
                            )
                        })}
                    </div>
                </Box>
                <Box className={styles["personal-details-box"]}>

                    <Box className={styles["personal-details-header"]}>
                        <Box
                            className={styles["profile-header"]}
                            style={{
                                background: "",
                                fontFamily: "",
                            }}
                        >
                            <Box className={styles["profile-header-name"]}>Professional Details</Box>
                        </Box>

                    </Box>
                    {learnerProfileDetails?.profileDetails?.professionalDetails?.length > 0 &&
                        learnerProfileDetails?.profileDetails?.professionalDetails.map((ele, index) => (
                            <Box className={styles["profession-box"]} key={index}>
                                <Box className={styles["profession-position"]}>
                                    <Box>{ele.position}</Box>
                                    <Box className={styles["profession-position-icons"]}>

                                    </Box>
                                </Box>
                                <Box
                                    className={styles["profession-date"]}
                                >{`${ele.startDate}`}
                                    <RemoveIcon />
                                    {`${ele.endDate}`}</Box>
                                <Box className={styles["profession-bio"]}> {ele.bio} </Box>
                                {ele.currentProfession && (
                                    <Box className={styles["profession-current"]}>
                                        <Checkbox
                                            className={styles["profile-checkbox"]}
                                            checked={ele.currentProfession}
                                        />
                                        Currently At this Possition
                                    </Box>
                                )}
                            </Box>
                        ))}
                </Box>
                <Box className={styles["personal-details-box"]}>

                    <Box className={styles["personal-details-header"]}>
                        <Box
                            className={styles["profile-header"]}
                            style={{
                                background: "",
                                fontFamily: "",
                            }}
                        >
                            <Box className={styles["profile-header-name"]}>Educational Details</Box>
                        </Box>

                    </Box>

                    {learnerProfileDetails?.profileDetails?.educationDetails?.length > 0 &&
                        learnerProfileDetails?.profileDetails?.educationDetails.map((ele, index) => (
                            <Box className={styles["profession-box"]} key={index}>
                                <Box className={styles["profession-position"]}>
                                    <Box> {ele.school} </Box>
                                </Box>
                                <Box className={styles["profession-date"]}>
                                    {" "}
                                    {`${ele.eduStartDate} `}
                                    <RemoveIcon />
                                    {`${ele.eduEndDate}`}
                                </Box>
                                <Box className={styles["profession-bio"]}> {ele.degree} </Box>
                                {ele.currentInstitution && (
                                    <Box className={styles["profession-current"]}>
                                        <Checkbox
                                            className={styles["profile-checkbox"]}
                                            checked={ele.currentInstitution}
                                        />
                                        Currently At this Institution
                                    </Box>
                                )}
                            </Box>
                        ))}
                </Box>

            </Box>
        </Box>

    )
}

const mapStateToProps = ({ user }) => ({
    learnerProfileDetails: user.learnerProfileDetails
});

const mapDispatchToProps = (dispatch) => {
    return {
        getLearnerProfileDetails: (userUId) => dispatch(getLearnerProfileDetails(userUId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareableProfile);
